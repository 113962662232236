import ProfileCreation from "./ProfileCreation";
import AuthGuard from "../../guards/AuthGuard";

const profileCreationRoute = [
  {
    path: "/profile-create",
    element: <AuthGuard component={<ProfileCreation />} />,
  },
];

export default profileCreationRoute;
