import React, { useMemo } from "react";

import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const DropZone = ({ onDrop, isDisabled, imageUploading }) => {
  const { t } = useTranslation();

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#5F3994",
    borderStyle: "dashed",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    maxWidth: "414px",
    flexShrink: 0,
    minWidth: "230px",
    height: "189px",
    backgroundColor: "#5F39940A",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const disabledStyle = {
    borderColor: "#5F39940A",
    opacity: "0.5",
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, disabled: isDisabled || imageUploading });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isDisabled ? disabledStyle : {}),
      ...(imageUploading ? disabledStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, isDisabled, imageUploading]
  );

  return (
    <div className="p-2 flex-shrink-0" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <img src="/assets/icons/drop.png" alt="drop" />
      <p
        style={{
          fontSize: "14px",
          fontWeight: "600",
          color: "#5F3994",
          marginBottom: "5px",
        }}
      >
        {t("profile.personalInfo.7")}
      </p>
      <p>{t("profile.personalInfo.8")}</p>
    </div>
  );
};

export default DropZone;
