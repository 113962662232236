import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../../firebase";
import swal from "sweetalert2";

export const sendResetPasswordEmail = (email, setSubmitting, t) => {
  return async (dispatch) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSubmitting(false);
        swal
          .fire(t("resetPassword.9"), t("resetPassword.10"), "success")
          .then(() => {
            window.location.href = `/`;
          });
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setSubmitting(false);
          swal.fire(t("resetPassword.11"), t("resetPassword.12"), "error");
        } else {
          setSubmitting(false);
          swal.fire(t("resetPassword.11"), error.message, "error");
        }

        return false;
      });
  };
};
