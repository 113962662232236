import { useEffect, useState } from "react";
import "./packages.scss";

import ChoiceOfferStep from "./steps/ChoiceOfferStep";
import FinalizeOrderStep from "./steps/FinalizeOrderStep";
import { useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Wizard } from "react-use-wizard";
import { useTranslation } from "react-i18next";
import { getAllPlans } from "../../redux/slices/subscription/actions/getAllPlans";

const stripePromise = loadStripe(process.env.REACT_APP_API_STRIPE_PUBLIC_KEY);

const ChoiceOfferPage = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(50);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPlans());
  }, []);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("fr");
  }, []);

  const steps = [
    {
      title: "1",
      isCompleted: activeStep === 1,
    },
    {
      title: "2",
      isCompleted: activeStep === 2,
    },
  ];

  useEffect(() => {
    console.log("activeStep", activeStep);
    if (activeStep === 1) {
      setProgress(50);
    } else if (activeStep === 2) {
      setProgress(100);
    }
  }, [activeStep]);

  return (
    <main className="packages-page poppins_font">
      <section className="mb-5 mt-3">
        <div className="container">
          <div
            className="row justify-content-between"
            style={{ rowGap: "2rem" }}
          >
            <div className="col-12">
              <div className="mb-4">
                <h3 className="h3 mb-1 text-black font-weight-bold text-capitalize text-center">
                  {t("Sign_up.21")}
                </h3>
              </div>
              <div className="steps-bar">
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                {steps.map((step, index) => (
                  <div
                    key={step.title}
                    className={`step ${step.isCompleted ? "completed" : ""} ${
                      activeStep === index + 1 ? "active" : ""
                    }`}
                  >
                    {step.title}
                  </div>
                ))}
              </div>
              <fieldset className="mt-3">
                <Wizard>
                  <ChoiceOfferStep setActiveStep={setActiveStep} />
                  <Elements stripe={stripePromise}>
                    <FinalizeOrderStep />
                  </Elements>
                </Wizard>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChoiceOfferPage;
