import { useState, useEffect, useMemo, useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  verifyResetPasswordCode,
  changePassword,
} from "../../utils/updatePassword";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/spinner";

const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),

  passwordClone: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPasswordPage = (props) => {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const oobCode = useMemo(() => queryParams.get("oobCode"), [queryParams]);
  const origin = useMemo(() => window.location.origin, []);
  const continueUrl = useMemo(
    () => queryParams.get("continueUrl") || origin || "/",
    [queryParams, origin]
  );

  const { t } = useTranslation();

  const [validCode, setValidCode] = useState(false);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      changePassword(
        values?.code,
        values?.password,
        values?.continueUrl,
        setSubmitting,
        t
      );
    },
    [continueUrl]
  );

  useEffect(() => {
    console.log("oobCode", oobCode);
    if (oobCode) {
      verifyResetPasswordCode(oobCode).then((res) => {
        console.log(res);
        if (!res) {
          window.location.href = `/`;
        } else setValidCode(true);
      });
    } else {
      window.location.href = `/`;
    }
  }, [oobCode]);

  return (
    <>
      {validCode ? (
        <div
          className="auth-layout-wrap"
          style={{
            backgroundImage: "url(/assets/images/photo-wide-4.jpg)",
          }}
        >
          <div className="auth-content">
            <div className="card o-hidden">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="p-4">
                    <div className="auth-logo text-center mb-4">
                      <img src="/assets/images/logo.png" alt="" />
                    </div>
                    <h1 className="mb-3 text-18">{t("resetPassword.1")}</h1>
                    <Formik
                      initialValues={{
                        code: oobCode,
                        continueUrl: continueUrl,
                        password: "",
                        passwordClone: "",
                      }}
                      validationSchema={ResetPasswordSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          {isSubmitting && <Spinner />}
                          <div className="form-group">
                            <label htmlFor="email">
                              {t("resetPassword.5")}
                            </label>
                            <input
                              className="form-control form-control-rounded position-relative"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            {errors.password && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">
                              {t("resetPassword.5")}
                            </label>
                            <input
                              className="form-control form-control-rounded position-relative"
                              type="password"
                              name="passwordClone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.passwordClone}
                            />
                            {errors.passwordClone && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.passwordClone}
                              </div>
                            )}
                          </div>
                          <button
                            className="btn btn-rounded btn-primary btn-block mt-4"
                            type="submit"
                          >
                            {t("resetPassword.6")}
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ResetPasswordPage;
