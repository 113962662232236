import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../firebase";
import {
  setAuthStatus,
  setAuthEventOn,
  setLoadingUserData,
} from "../authenticationSlice";
import { getUserData } from "./getUserData";
import { getAccountData } from "./getAccountData";
import reactivateAccount from "guards/utils/reactivateAccount";

export const checkAuthStatus = (t) => {
  return async (dispatch, getState) => {
    try {
      const authEventOn = getState().authentication.authEventOn;
      if (authEventOn) {
        return;
      }

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        dispatch(setLoadingUserData(true));
        if (user) {
          dispatch(setAuthEventOn(true));
          dispatch(setAuthStatus(true));
          dispatch(getUserData(user.uid));
          dispatch(getAccountData(user.uid));
          reactivateAccount(user.uid, t);
        } else {
          dispatch(setAuthEventOn(true));
          dispatch(setAuthStatus(false));
        }
        dispatch(setLoadingUserData(false));
      });
      return unsubscribe;
    } catch (error) {
      console.error(error);
    }
  };
};
