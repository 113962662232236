import { setReport, setLoading } from "../currentReportSlice";
import { db } from "../../../../firebase";
import { doc, getDoc } from "firebase/firestore";
const getCurrentReport = (reportId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const currentReportRef = doc(db, "RapportsEvaluations", reportId);
    const currentReview = await getDoc(currentReportRef);

    if (currentReview.exists()) {
      const reportData = currentReview.data();
      dispatch(setReport({ id: currentReview.id, ...reportData }));
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.log("getCurrentReport_error", error);
    dispatch(setLoading(false));
  }
};

export default getCurrentReport;
