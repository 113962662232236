import { Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();

  const { authStatus, loadingUserData } = useSelector(
    (state) => state.authentication
  );

  const footerShow = useSelector((state) => state.layoutSetting.footer.show);

  return (
    <>
      {!loadingUserData && footerShow ? (
        <footer className="py-4 bg-primary footer navbar-fixed-bottom">
          <Container>
            <div className="col-xl-9 px-0 mx-auto">
              <div>
                <h2 className="text-white text-center">{t("footer.1")}</h2>
              </div>

              <div className=" mt-4" style={{ gap: "1rem" }}>
                <Nav style={{ gap: "0.5rem", justifyContent: "center" }}>
                  {authStatus ? (
                    <li className="nav-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-white text-underline"
                        onClick={() => {
                          window.open(`${window.location.origin}`, "_blank");
                        }}
                      >
                        {t("footer.3")}
                      </span>
                    </li>
                  ) : null}

                  <li className="nav-item">
                    <a
                      href="https://pro.ziaway.ca/"
                      target="_blank"
                      className="text-white text-underline"
                      rel="noreferrer"
                    >
                      {t("footer.9")}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href="https://pro.ziaway.ca/blogs/"
                      target="_blank"
                      className="text-white text-underline"
                      rel="noreferrer"
                    >
                      {t("footer.4")}
                    </a>
                  </li>

                  <>
                    <li className="nav-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-white text-underline"
                        onClick={() => {
                          authStatus
                            ? window.open(
                                `${window.location.origin}/dataPolicy?newTab=true`,
                                "_blank"
                              )
                            : window.open(
                                `${window.location.origin}/dataPolicy?newTab=true`,
                                "_blank"
                              );
                        }}
                      >
                        {t("footer.10")}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-white text-underline"
                        onClick={() => {
                          authStatus
                            ? window.open(
                                `${window.location.origin}/termofservice?newTab=true`,
                                "_blank"
                              )
                            : window.open(
                                `${window.location.origin}/termofservice?newTab=true`,
                                "_blank"
                              );
                        }}
                      >
                        {t("footer.12")}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-white text-underline"
                        onClick={() => {
                          authStatus
                            ? window.open(
                                `${window.location.origin}/generalPolicy?newTab=true`,
                                "_blank"
                              )
                            : window.open(
                                `${window.location.origin}/generalPolicy?newTab=true`,
                                "_blank"
                              );
                        }}
                      >
                        {t("footer.11")}
                      </span>
                    </li>
                  </>
                </Nav>
              </div>
            </div>

            <div className="text-white text-center mt-5">{t("footer.7")}</div>
          </Container>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
