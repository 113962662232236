import { useEffect, useState } from "react";
import deleteNote from "../../utils/deleteNote";

const MyNotes = ({
  notesList,
  notesId,
  setLoading,
  evalId,
  setNotesList,
  setEditShow,
  setEditedNote,
}) => {
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (notesList?.length > 0) {
      setNotes(notesList);
    } else {
      setNotes([]);
    }
  }, [notesList]);

  const convertDate = (d) => {
    const date = new Date(d);
    let curr_date = date.getDate();
    let curr_month = date.getMonth() + 1;
    const curr_year = date.getFullYear();
    if (curr_date < 10) {
      curr_date = "0" + curr_date;
    }
    if (curr_month < 10) {
      curr_month = "0" + curr_month;
    }

    return curr_month + "-" + curr_date + "-" + curr_year;
  };

  const deleteNoteFunc = (evalId, noteId) => {
    const response = deleteNote(notesId, noteId, setLoading);
    if (!response?.error) {
      const newNotes = notes.filter((item) => item.id !== noteId);
      setNotesList(newNotes);
    }
  };

  return (
    <div
      className="animated fadeInUp table-responsive"
      style={{ height: 250, overflowY: "auto" }}
    >
      {notes?.length > 0 &&
        notes.map((item) => (
          <div
            key={item.id}
            className="d-flex align-items-center justify-content-between"
            style={{ maxWidth: "95%" }}
          >
            <div className="newLeads_note">
              <span>{convertDate(item?.date)}</span>
              <p>{item?.text}</p>
            </div>
            <div className="mr-1 d-flex align-items-center">
              <img
                onClick={async () => {
                  await setEditedNote(item);
                  setEditShow(true);
                }}
                src="assets/icons/edit.svg"
                alt="edit"
                style={{
                  height: "20px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
              <img
                onClick={() => deleteNoteFunc(evalId, item.id)}
                src="assets/icons/supprimer.svg"
                alt="supprimer"
                style={{
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default MyNotes;
