export const convertDate = (date) => {
  let day = date.getDate();
  let month = date.getMonth() + 1; // getMonth() returns 0 for January, so we add 1
  let year = date.getFullYear();

  // Add leading zeros to day and month if they are less than 10
  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  let dateString = `${day}/${month}/${year}`;
  return dateString;
};

export const convertStringToDate = (dateString) => {
  const [day, month, year] = dateString.split("/");
  // Subtract 1 from month because getMonth() returns 0 for January
  if (
    day === undefined ||
    month === undefined ||
    year === undefined ||
    isNaN(day) ||
    isNaN(month) ||
    isNaN(year)
  ) {
    return "";
  }

  return new Date(year, month - 1, day);
};

export function placeToAddress(place) {
  var address = {};
  if (place.address_components) {
    address.value = place.formatted_address;
    address.latlng = place.geometry && {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    place.address_components.forEach(function (c) {
      switch (c.types[0]) {
        case "street_number":
          address.StreetNumber = c;
          break;
        case "route":
          address.StreetName = c;
          break;
        case "neighborhood":
        case "locality": // North Hollywood or Los Angeles?
          address.city = c.long_name;
          break;
        case "administrative_area_level_1": //  Note some countries don't have states
          address.state = c;
          break;
        case "postal_code":
          address.postcode = c.short_name;
          break;
        case "country":
          address.Country = c;
          break;
        default:
          break;
      }
    });
  }
  return address;
}

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

export const reformulateAmount = (num) => {
  return addCommas(removeNonNumeric(num));
};

export const assignValueToLabel = (value, arr) => {
  let label = "";
  arr.forEach((element) => {
    if (element.value === value) {
      label = element.label;
    }
  });

  return label;
};

export const assignFields = (setFieldValue, propertyInfo) => {
  propertyInfo?.Nbrpieces &&
    setFieldValue("numberOfRooms", propertyInfo?.Nbrpieces);
  propertyInfo?.NbrChambres &&
    setFieldValue("numberOfBedRooms", propertyInfo?.NbrChambres);
  propertyInfo?.anneeConstruction &&
    setFieldValue("yearOfConstruction", propertyInfo?.anneeConstruction);
  propertyInfo?.evalMunicipalCalc &&
    setFieldValue("municipalAssessment", propertyInfo?.evalMunicipalCalc);
  // setmunicipalAssessment(propertyInfo?.evalMunicipalCalc);
  // setFieldValue("municipalAssessment", propertyInfo?.evalMunicipalCalc);
  // propertyInfo?.genreProprietes &&
  //   setpropertyGenre(propertyInfo?.genreProprietes);
  // setFieldValue("propertyGenre", propertyInfo?.genreProprietes);
  propertyInfo?.salleBains &&
    setFieldValue("numberOfBathrooms", propertyInfo?.salleBains);
  propertyInfo?.stationnements &&
    setFieldValue("numberOfParkingSpaces", propertyInfo?.stationnements);
  propertyInfo?.superficieTerrain &&
    setFieldValue("buildingArea", propertyInfo?.superficieTerrain);
  propertyInfo?.taxeMunicipaleCalc &&
    setFieldValue("municipalTax", propertyInfo?.taxeMunicipaleCalc);
  // setmunicipalTax(propertyInfo?.taxeMunicipaleCalc);
  // setFieldValue("municipalTax", propertyInfo?.taxeMunicipaleCalc);
  // propertyInfo?.type && setpropertyCategory(propertyInfo?.type);
  // setFieldValue("propertyCategory", propertyInfo?.type);
  // propertyInfo?.typeBatiment && setpropertyType(propertyInfo?.typeBatiment);
  // setFieldValue("propertyType", propertyInfo?.typeBatiment);
  propertyInfo?.nbrGarage &&
    setFieldValue("numberOfGarages", propertyInfo?.nbrGarage);
};
