import { useState, useEffect } from "react";
import MySalesList from "./MySalesList";
import Modall from "./utils/Modal";
import "./mySales.scss";

const EditMySalesList = ({ t, selectedSale, setSelectedSale }) => {
  const [isOpen, setIsOpen] = useState("");

  const [activePage, setActivePage] = useState(1);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedSale) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [selectedSale]);

  return (
    <>
      <MySalesList
        activePage={activePage}
        setActivePage={setActivePage}
        setIsOpen={setIsOpen}
        t={t}
        selectedSale={selectedSale}
        setSelectedSale={setSelectedSale}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      {isOpen && <Modall setIsModal={setIsOpen} t={t} />}
    </>
  );
};

export default EditMySalesList;
