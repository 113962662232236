import { Row, Col } from "react-bootstrap";
import CurrentPlan from "../CurrentPlan";
import MyCards from "../MyCards";
import AllPlans from "../AllPlans";
import SubscriptionHistory from "../SubscriptionHistory";

const StepOne = ({
  currentAccount,
  currStep,
  currentPlan,
  products,
  setCurrStep,
  setSelectedPack,
  transactions,
}) => {
  return (
    <Row style={{ display: currStep === 1 ? "flex" : "none" }}>
      <Col lg={6} className="current-plan-container">
        <CurrentPlan
          currentAccount={currentAccount}
          currentPlan={currentPlan}
          products={products}
        />
      </Col>
      <Col lg={6} className="my-cards-container">
        <MyCards currentAccount={currentAccount} />
      </Col>
      <Col lg={12} className="all-plans">
        <AllPlans
          setCurrStep={setCurrStep}
          setSelectedPack={setSelectedPack}
          products={products}
          currentPlan={currentPlan}
        />
      </Col>
      {transactions?.length && (
        <Col lg={12} className="history">
          <SubscriptionHistory transactions={transactions} />
        </Col>
      )}
    </Row>
  );
};

export default StepOne;
