const formatData = (data) => {
  let formattedData = {
    anneeConstruction: data.anneeConstruction,
    superficie: data.superficieTerrain,
    chambres: data.NbrChambres,
    bains: data.salleBains,
    stationnement: data.stationnements,
    taxesMunicipale: data.taxeMunicipaleCalc,
    evaluationMunicipale: data.evalMunicipalCalc,
    pieces: data.Nbrpieces,
    type: data.type,
    genreProprietes: data.genreProprietes,
    typeBatiment: data.typeBatiment,
  };

  return formattedData;
};

export default formatData;
