import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setSelectedCardId, setCards } from "../subscriptionSlice";

export const listCards = (selectedCardId) => {
  return (dispatch) => {
    const getCards = httpsCallable(functions, "listCards");
    getCards({})
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          dispatch(setCards(res?.data?.data));
          dispatch(setSelectedCardId(selectedCardId));
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
};
