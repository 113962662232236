import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from "../redux/slices/authentication/actions/checkAuthStatus";
import { useDispatch, useSelector } from "react-redux";
import UnAuthLayout from "../components/layout/UnAuthLayout";

const UnAuthGuard = ({ component }) => {
  const { authStatus, authEventOn } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, []);

  useEffect(() => {
    if (authEventOn && authStatus) {
      navigate("/dashboard");
    }
  }, [authStatus, authEventOn]);

  return !authStatus ? <UnAuthLayout>{component}</UnAuthLayout> : <></>;
};

export default UnAuthGuard;
