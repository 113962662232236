import { useState, useEffect } from "react";
import "../../components/packages/packages.scss";
import RegulatoryInformationStep from "../../components/packages/steps/RegulatoryInformationStep";
import InformationDisplayStep from "../../components/packages/steps/InformationDisplayStep";
import YourMessagesStep from "../../components/packages/steps/YourMessagesStep";
import PreviewYourProfileStep from "../../components/packages/steps/PreviewYourProfileStep";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileCreation = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(20);
  const [regulatory, setRegulatory] = useState();
  const [information, setInformation] = useState();
  const [messages, setMessages] = useState();
  const [logo, setLogo] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);

  const { userData } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (userData?.creationProcessFinished !== false) {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const { t, i18n } = useTranslation();

  const handleStepPrev = () => {
    setActiveStep(activeStep - 1);
    setProgress(((activeStep - 1) / 4) * 100);
  };

  const handleStepNext = () => {
    setActiveStep(activeStep + 1);
    setProgress(((activeStep + 1) / 4) * 100);
  };

  const steps = [
    {
      title: "1",
      isCompleted: activeStep > 1,
    },
    {
      title: "2",
      isCompleted: activeStep > 2,
    },
    {
      title: "3",
      isCompleted: activeStep > 3,
    },
    {
      title: "4",
      isCompleted: activeStep > 4,
    },
  ];

  useEffect(() => {
    i18n.changeLanguage("fr");
  }, [i18n]);

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <main className="packages-font benoitThomas-page bg-gray-100 flex-fill">
      <section className=" my-5">
        <div className="container">
          <div className=" d-flex flex-wrap" style={{ gap: "1rem" }}>
            <div className="order-1">
              <img
                style={{ width: 80, height: 80, objectFit: "contain" }}
                className="d-block mx-auto"
                src="/assets/images/logo-2.png"
                alt=""
              />
            </div>
            <div className="flex-fill order-3 order-lg-2">
              <h4 className="mb-0 text-center">
                {t("Sign_up.75")}
                <br className="d-none d-lg-block" />
                {t("Sign_up.76")}
              </h4>
              <p className="text-center">{t("Sign_up.77")}</p>
            </div>
            <div className="order-2 order-lg-3 ml-auto">
              <Dropdown
                as={ButtonGroup}
                size="sm"
                className="d-flex align-items-center"
              >
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Language
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleLanguage("fr")}>
                    French
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleLanguage("en")}>
                    English
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="bg-white shadow">
            <div>
              <nav className="p-4 p-lg-5 border-bottom">
                <div className="mx-auto px-0 col-lg-8">
                  <div className="steps-bar">
                    <div className="progress-bar-container">
                      <div
                        className="progress-bar"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                    {steps.map((step, index) => (
                      <div
                        key={step.title}
                        className={`step ${
                          step.isCompleted ? "completed" : ""
                        } ${activeStep === index + 1 ? "active" : ""}`}
                      >
                        {step.title}
                      </div>
                    ))}
                  </div>
                </div>
              </nav>
              <fieldset className="p-4 p-lg-5">
                <div className="mx-auto px-0 col-lg-8">
                  {activeStep === 1 && (
                    <InformationDisplayStep
                      handleStepNext={handleStepNext}
                      handleStepPrev={handleStepPrev}
                      setInformation={setInformation}
                      information={information}
                      setUserPhoto={setUserPhoto}
                    />
                  )}
                  {activeStep === 2 && (
                    <RegulatoryInformationStep
                      handleStepNext={handleStepNext}
                      setRegulatory={setRegulatory}
                      regulatory={regulatory}
                      setLogo={setLogo}
                      logo={logo}
                      handleStepPrev={handleStepPrev}
                    />
                  )}
                  {activeStep === 3 && (
                    <YourMessagesStep
                      setMessages={setMessages}
                      messages={messages}
                      handleStepNext={handleStepNext}
                      handleStepPrev={handleStepPrev}
                    />
                  )}
                  {activeStep === 4 && (
                    <PreviewYourProfileStep
                      logo={logo}
                      userPhoto={userPhoto}
                      regulatory={regulatory}
                      information={information}
                      messages={messages}
                      handleStepPrev={handleStepPrev}
                    />
                  )}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProfileCreation;

{
  /* <div className="px-4 px-lg-5 pb-4 text-right">
                {activeStep === 1 ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
                  >
                    Plus tard
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
                    onClick={handleStepPrev}
                  >
                    Précédent
                  </button>
                )}
                {activeStep === 4 ? (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary rounded-xl px-4"
                    onClick={() => history.push("/subscription")}
                  >
                    Suivant
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-lg btn-primary rounded-xl px-4"
                    onClick={handleStepNext}
                  >
                    Suivant
                  </button>
                )}
              </div> */
}
