import React, { useState } from "react";

function GroupCheckButtons({ options, onClick, initials }) {
  let currentOption = -1;
  if (initials) {
    currentOption = options.findIndex((item) => item === initials.value);
  }
  const [selectedOption, setSelectedOption] = useState(
    currentOption > 0 ? options[currentOption] : options[0]
  );

  const handleClick = (option) => {
    setSelectedOption(option);

    onClick({ value: option, label: option });
  };

  return (
    <div className="d-flex flex-wrap group-button-2" style={{ gap: "0.7rem" }}>
      {options.map((option) => (
        <button
          key={option}
          type="button"
          className={`${selectedOption === option ? "active" : ""}`}
          onClick={() => handleClick(option)}
        >
          <span className="mr-1">{selectedOption === option ? "✔" : ""}</span>
          <span>{option}</span>
        </button>
      ))}
    </div>
  );
}

export default GroupCheckButtons;
