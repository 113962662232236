import { useState, useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { suspendAccount } from "../../../redux/slices/subscription/actions/suspendAccount";

const SuspendModal = ({
  setModal,
  modal,
  password,
  setPassword,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const distpatch = useDispatch();
  const today = useMemo(() => new Date(), []);
  const todayString = useMemo(() => {
    return `${today.getFullYear()}-${
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : today.getMonth() + 1
    }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;
  }, [today]);

  const [reactivationDate, setReactivationDate] = useState(null);
  const [suspendDate, setSuspendDate] = useState(null);

  const handleSuspend = useCallback(() => {
    distpatch(
      suspendAccount(
        password,
        reactivationDate,
        suspendDate,
        setLoading,
        setPassword,
        setModal,
        modal,
        t
      )
    );
  }, [
    distpatch,
    password,
    reactivationDate,
    suspendDate,
    setLoading,
    setPassword,
    setModal,
    modal,
    t,
  ]);

  return (
    <Modal
      className="suspended-modal"
      show={modal}
      onHide={() => {
        setModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="suspended-modal-body">
          <p> {t("profile.subscription.current_plan.5")}</p>
          <div className="sub-container">
            <div className="white-input-container">
              <label> {t("profile.subscription.current_plan.6")}</label>
              <input
                className="card-inputs mb-20"
                type="date"
                min={todayString}
                value={suspendDate}
                onChange={(e) => setSuspendDate(e.target.value)}
              />
            </div>
            <div className="white-input-container">
              <label> {t("profile.subscription.current_plan.7")}</label>
              <input
                className="card-inputs mb-20"
                type="date"
                min={suspendDate}
                value={reactivationDate}
                onChange={(e) => setReactivationDate(e.target.value)}
              />
            </div>
            <div className="white-input-container">
              <label> {t("profile.subscription.current_plan.8")}</label>
              <input
                className="card-inputs"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="suspended-modal-footer">
              <button
                className="non-filled-bg-btn"
                disabled={loading}
                onClick={() => {
                  setLoading(false);
                  setPassword("");
                  setModal(!modal);
                }}
                style={{
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {t("profile.subscription.current_plan.9")}
              </button>
              <button
                className="bg-filled-btn"
                onClick={handleSuspend}
                disabled={loading}
                style={{
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {t("profile.subscription.current_plan.10")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuspendModal;
