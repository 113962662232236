import { useEffect, useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MapEstimate from "../../components/map/MapEstimate";
import { useLocation } from "react-router-dom";
import getCurrentReport from "../../redux/slices/currentRapport/actions/getCurrentReport";
import updateReportReview from "../../redux/slices/currentRapport/actions/updateReportReview";
import getArticles from "./utils/getArticles";

import "./rapport.scss";

const Rapport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const articles = useMemo(() => getArticles(t), [t]);

  const location = useLocation();
  const [reportId, setReportId] = useState(null);
  const [confianceIndex, setConfianceIndex] = useState(0);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const id = pathParts[pathParts.length - 1];
    setReportId(id);
    dispatch(getCurrentReport(id));
  }, [dispatch, location.pathname]);

  const { report: rapport } = useSelector((state) => state.currentReport);

  const getConfianceIndex = useCallback((rapport) => {
    return Array.isArray(rapport?.comparables) &&
      rapport?.comparables?.length > 0
      ? parseInt(rapport?.comparables[0]?.score * 100)
      : 0;
  }, []);

  useEffect(() => {
    if (rapport?.comparables?.length > 0)
      setConfianceIndex(getConfianceIndex(rapport));
  }, [getConfianceIndex, rapport]);

  const handleAvis = useCallback(
    (avis, reportId, t) => {
      dispatch(updateReportReview(reportId, avis, t));
    },
    [dispatch]
  );

  if (!rapport)
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center bg-white  rounded-xl p-4"
        style={{
          height: "70vh",
        }}
      >
        <img
          height={200}
          width={200}
          src="/assets/images/ziawayLoader.gif"
          alt=""
        />
        <h4 className="mb-0">{t("Estimate-new.121")}</h4>
      </div>
    );

  return (
    <div>
      <h2 className="mb-4 font-poppins font-weight-600">
        {t("Report_res.45")}
      </h2>

      <div className="row">
        <div className="col-lg-9 col-xl-8">
          <div className="box-shadow-1 rounded-xl p-3 p-xl-5 p-sm-4">
            <div className="text-center">
              <h3 className="mb-4 font-poppins font-weight-600">
                {t("Report_res.46")}
              </h3>
              <div className="mx-auto col-12 col-lg-9 bg-primary rounded-lg p-3 p-sm-4">
                <div className="d-flex align-items-center justify-content-center gap-1">
                  <h6 className="mb-0 text-white font-poppins font-weight-normal">
                    {t("Report_res.47")}
                  </h6>
                  <span
                    className="mx-3 d-none d-sm-inline-block"
                    style={{ width: "4.625rem" }}
                  />
                  <h6 className="mb-0 text-white font-poppins font-weight-normal">
                    {t("Report_res.48")}
                  </h6>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-center gap-1">
                  <h3 className="mb-0 text-white font-roboto font-weight-bold">
                    {rapport &&
                      rapport.ziaEstimation.prediction.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </h3>
                  <hr
                    className="my-0 bg-white mx-3 d-none d-sm-inline-block"
                    style={{ height: "0.12rem", width: "4.625rem" }}
                  />
                  <h3 className="mb-0 text-white font-roboto font-weight-bold">
                    {rapport &&
                      rapport.ziaEstimation.predictionEnd.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                  </h3>
                </div>
              </div>
              <div>
                {confianceIndex && (
                  <p>
                    <span>{t("Report_res.6")} </span>
                    <span className={"font-weight-600 text-primary text-14"}>
                      {confianceIndex}%
                    </span>
                    <br />
                    <span>{t("Report_res.4")} </span>
                  </p>
                )}
              </div>
              <div className="mt-3">
                <div className="d-inline-block rounded-pill bg-violet-light py-2 px-4">
                  <span className="font-weight-500 text-primary text-14 font-poppins text-capitalize">
                    {rapport && rapport.location.value}
                  </span>
                </div>
              </div>
              <div className="mt-3 d-flex flex-wrap justify-content-center gap-2">
                <span className="badge-box-2">
                  {" "}
                  {rapport && rapport.anneeConstruction}
                </span>
                <span className="badge-box-2">
                  {" "}
                  {rapport && rapport.superficieTerrain}
                </span>
                <span className="badge-box-2">
                  {rapport && rapport.pieces} {t("Report_res.14")}
                </span>
                <span className="badge-box-2">
                  {rapport && rapport.chambres} {t("Report_res.15")}
                </span>
                <span className="badge-box-2">
                  {rapport && rapport.bains} {t("Report_res.49")}
                </span>
                <span className="badge-box-2">
                  {rapport && rapport.stationnement} {t("Report_res.50")}
                </span>
                <span className="badge-box-2">
                  {rapport && rapport.garages} {t("Report_res.19")}
                </span>
              </div>
              <div className="mt-3">
                <div className="map-box mx-auto">
                  {rapport && (
                    <MapEstimate
                      markers={[
                        {
                          location: rapport?.location,
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              {rapport && !rapport.avisClient && (
                <div className="mt-4">
                  <h6 className="mb-2 text-14 text-black-2 font-poppins font-weight-normal">
                    {t("Report_res.51")}
                  </h6>
                  <div className="d-flex flex-wrap justify-content-center gap-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary font-poppins text-capitalize py-2 px-3"
                      onClick={() => handleAvis("Trop bas", reportId, t)}
                    >
                      {t("Report_res.52")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary font-poppins text-capitalize py-2 px-3"
                      onClick={() => handleAvis("Bas", reportId, t)}
                    >
                      {t("Report_res.53")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary font-poppins text-capitalize py-2 px-3"
                      onClick={() => handleAvis("Juste", reportId, t)}
                    >
                      {t("Report_res.54")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary font-poppins text-capitalize py-2 px-3"
                      onClick={() => handleAvis("Haut", reportId, t)}
                    >
                      {t("Report_res.55")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary font-poppins text-capitalize py-2 px-3"
                      onClick={() => handleAvis("Trop haut", reportId, t)}
                    >
                      {t("Report_res.56")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {rapport && rapport?.comparables?.length !== 0 && (
            <div className="mt-5">
              <h3 className="mb-4 font-poppins font-weight-600 text-center">
                {t("Report_res.57")}
              </h3>
              <div
                className="row row-cols-1 row-cols-sm-2 row-cols-lg-3"
                style={{ rowGap: "1.5rem" }}
              >
                {rapport?.comparables?.map((property, index) => (
                  <div className="col" key={index}>
                    <div className="h-100 d-flex flex-column box-5 p-3 p-sm-4">
                      <h5 className="text-center mb-3 text-18 font-poppins font-weight-600">
                        {property?.genreProprietes}
                      </h5>
                      <div className="d-flex flex-wrap gap-2 justify-content-center">
                        <span className="font-weight-500 text-14 text-muted-light text-capitalize">
                          {property?.nbrChambres} {t("Report_res.40")}
                        </span>
                        <span className="font-weight-500 text-14 text-muted-light text-capitalize">
                          {parseInt(property?.superficieTerrain)}{" "}
                          {t("Report_res.41")}
                        </span>
                        <span className="font-weight-500 text-14 text-muted-light text-capitalize">
                          {property?.salleBains} {t("Report_res.42")}
                        </span>
                      </div>
                      <div className="mt-auto pt-3 d-flex flex-wrap gap-2 justify-content-center">
                        <span className="badge-box-3">
                          {t("Report_res.44")} {parseInt(property?.score * 100)}{" "}
                          %
                        </span>
                        <span className="badge-box-3">
                          {t("Report_res.43")}{" "}
                          {property?.prixVente.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-3 col-xl-4 px-xl-5">
          <div className="position-sticky mb-5" style={{ maxHeight: "100vh" }}>
            <div
              className={`box-shadow-1 rounded-xl p-3 p-xl-5 overflow-auto right-box-2`}
              style={{ maxHeight: "100vh" }}
            >
              <div className="text-center">
                <h4 className="mb-2 text-22 font-poppins font-weight-bold">
                  {t("Report_res.63")}
                </h4>
              </div>
              {articles &&
                articles?.map((item, index) => (
                  <div
                    className="mt-4 text-center box-shadow-1 rounded-sm p-1"
                    key={index}
                  >
                    <div className="mt-2 blog-item bg-gray-light rounded-2 p-4">
                      <div>
                        <img
                          src={item.image}
                          alt=""
                          className="img-fluid rounded-lg"
                        />
                      </div>
                      <div>
                        <a
                          href={item?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mb-3 d-block h5 text-primary font-weight-semibold mt-3"
                        >
                          {item.title}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rapport;
