import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const GeneralPolicies = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { id } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const newTab = queryParams.get("newTab");

  useEffect(() => {
    if (id === "true") {
      window.scrollTo({
        top: 5000,

        behavior: "smooth",
      });
    }
  }, [id]);

  if (i18n.language === "en")
    return (
      <>
        <main className="py-5">
          <div className="container">
            {newTab !== "true" && (
              <div>
                <button
                  className="btn  btn-primary btn-rounded mb-3 "
                  Style={{ height: "min-content" }}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>
            )}
            <h2 className="mb-4 text-primary">
              GENERAL TERMS AND CONDITIONS OF SERVICES
            </h2>
            <p>(Version updated on 05/01/2023)</p>

            <section>
              <h4 className="mb-3">ARTICLE 1. DEFINITIONS </h4>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`
         ▪	Advertisement: Real estate advertisement for sale or rent added on the Site by the brokers.
         ▪	Customer review: Appreciation of the broker's services by its customers, in accordance with these TOS, the TOS, and in particular the charter of the properties sold, 
         ▪	Personal Data: means any information relating, directly or indirectly, to an identified or identifiable natural person.
         ▪	Partner: Any broker with a free or paid subscription to Ziaway Technology
         ▪	Party: means both the User and the Provider.
         ▪	Services: refers to all services offered by Ziaway Technology to users through the Platform 
         ▪	Site: means the online platform accessible from the Website. For professionals, the platform is accessible from: https://proziaway.com. The Platform includes all the web pages and features offered to Users.
         ▪	User: means any natural person accessing and browsing the https://proziaway.com platform. P
         ▪	Password: means the password associated with the Username. With the Username and Password, the user can benefit from the Services offered on the Platform.
         
         .
          `}
              </p>
            </section>
            <section>
              <h4 className="mb-3">ARTICLE 2. PURPOSE</h4>
              <p>
                The purpose of these General Terms and Conditions of Service
                (GTCS), concluded between Ziaway Technologie and the client (the
                Brokers), is to set forth the contractual provisions relating to
                the respective rights and obligations of the Parties in the
                context of the services offered by Ziaway Technologie. The GSPCs
                apply in addition to the General Terms of Use of the platform
                and the Privacy Policy available on the Site.
              </p>

              <h5 className="font-weight-bold">
                2.1. APPLICABILITY OF THE GPS
              </h5>
              <p>
                These GST are fully effective as of the date of publication on
                the Site https://proziaway.com and thus apply to all services
                offered by Ziaway Technologie.
              </p>
              <br />
              <h5 className="font-weight-bold">
                2.2. ENFORCEABILITY OF THE GPS
              </h5>
              <p>
                Any order of services offered by Ziaway Technologie implies the
                final, full and unconditional acceptance of the customer to
                these GSPC.
              </p>
            </section>

            <section>
              <h4 className="mb-3">
                ARTICLE 3. ACCEPTANCE OF THE GENERAL CONDITIONS OF SERVICES
              </h4>

              <p>
                The customer undertakes to read carefully the present General
                Conditions of Services (GCS) and is invited to print them,
                download them and keep a copy. The use of the functionalities of
                the Site https://proziaway.com and the services imply the
                express acceptance of the present GCPS. In this regard, the
                client acknowledges having been fully informed of the nature of
                the services offered by Ziaway Technologie, and recognizes that
                the latter was at his disposal to inform him of any determining
                factor before the conclusion of a service.
              </p>

              <p>
                It is specified that these conditions are referenced at the
                bottom of each page of the site https://proziaway.com by means
                of a hyperlink and can thus be consulted at any time.
              </p>
            </section>

            <section>
              <h4 className="mb-3">
                ARTICLE 4. MODIFICATION OF THE GENERAL CONDITIONS OF SERVICES
              </h4>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                These conditions apply to all customers of Ziaway Technologie.
                They were last updated on January 05, 2023. They may be modified
                at any time, in particular to address legislative or regulatory
                developments. Clients will be notified of any substantial
                modifications. In any case, the applicable conditions are those
                in force at the time of navigation on the site or when they are
                presented to customers.
              </p>
            </section>

            <section>
              <h4 className="mb-3">
                ARTICLE 5. VALIDITY OF THE GENERAL CONDITIONS OF SERVICES
              </h4>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                If any of the provisions of these GSPCs are declared null and
                void by virtue of a legislative or regulatory provision in force
                and/or a court decision having the force of res judicata, it
                shall be deemed unwritten but shall in no way affect the
                validity of the other provisions which shall remain fully
                applicable.
              </p>
              <br />
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Such a modification or decision does not authorize customers and
                Users to disregard the present GSP.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 6. ZIAWAY TECHNOLOGY SERVICES</h4>
              <h5 className="font-weight-bold">6.1. ACCESS TO SERVICES</h5>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The subscription to the services offered by Ziaway Technologie
                is only done online via the Platform from the page dedicated to
                this purpose accessible from the address
                https://pro.ziaway.ca/forfait-et-tarifs.
              </p>
              <br />
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Before subscribing to a service, the broker declares that he/she
                has the legal capacity and thus has the necessary powers to
                accept these GSTP. Furthermore, the client declares that he/she
                holds a valid real estate brokerage license and that he/she is
                not subject to any measure that prevents the practice of real
                estate brokerage at the time of registration and/or use of the
                platform https://proziaway.com
              </p>
              <br />
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of legal or administrative changes, clients agree
                to notify Ziaway Technology and to update their profile,
                particularly in the event of professional incapacity or change
                in the status of their license to practice occurring during the
                execution of the Contract.
              </p>
              <br />
              <h5 className="font-weight-bold">6.2. DESCRIPTION OF SERVICES</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Our broker customers can choose one of our 3 subscription
                offers; the essential offer, the performance offer and the
                expert offer. Ziaway Technology offers a free 7-day trial period
                with no commitment.
              </p>
              <h6>6.2.1 ESSENTIAL OFFER </h6>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                This offer allows for digital exposure of the Broker on the
                various media offered by Ziaway Technology. The exposure will
                take place in the form of recommendations on the following media
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`•	The pop-up on the home page of the ziaway.ca website: The display on this pop-up is random. 
                Ziaway Technology will make its best efforts to ensure fair exposure to all client brokers. 
                However, the display of pop-ups is dependent on the amount of time spent on the home page by users. 
                Therefore, Ziaway Technology cannot guarantee that all broker clients will be exposed.
                The pop-up is only displayed on the website and not on the mobile version.

                •	The estimate report displayed on the ziaway.ca website: Based on the information provided by users during their estimates, 
                the estimate report proposes a list of recommended brokers. This recommendation is made by presenting the name, first name, photo, 
                banners, online communication media of the broker (website, Facebook page, Linkedin page, Tweet page, Instagram page) when the latter 
                has completed all this information on his proziaway.com account. Brokers are displayed and therefore recommended according to :

                o	Their dynamism: The comparison between real estate brokers is based on the sales they have made and published on Ziaway Technology over the last 12 months in a given geographical area.	

                o	Relevance: The comparison between Brokers is based on their performance in selling a similar property close to the address entered. The similarity of the property takes into account the location, the type of property, the number of rooms, the surface as well as the date of sale. The more similar properties a professional has sold and published near the address, the better they are ranked.

                o	Distance: The comparison between the real estate brokers is done according to the distance to the address entered. The ranking is done from the real estate broker who sold properties closest to the address entered to the professional who sold the furthest away. 
                `}
              </p>
              <br />
              <p>
                The rankings are not influenced by any remuneration from the
                Real Estate Brokers. These different rankings available on the
                Site are continuously updated. Ziaway Technology strives to
                offer a real-time ranking by integrating each data transmitted
                by Partners or their Clients into the system allowing the
                comparison.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`•	The evaluation email: An email is sent to each user after each evaluation. The email includes the content of the evaluation report except for the links to the broker's online communication media (website, Facebook page, Linkedin page, Tweet page, Instagram page)." The appraisal email thus provides an opportunity once again to promote the recommended brokers.

                `}
              </p>
              <p>
                Ziaway Technology's obligation is limited to displaying
                information as entered by brokers to Users making estimates on
                its website ziaway.ca. In any case, Ziaway Technology does not
                guarantee the number of postings or emails sent.
              </p>
              <br />
              <h6>6.2.2 THE PERFORMANCE OFFER</h6>
              <p>
                The Performance offer includes the content of the Essential
                offer as described above and also allows access to the
                Prospecting function.
              </p>
              <p>
                The Performance offer allows to locate, according to the
                criteria selected by the brokers, the Users who have made an
                estimate on the ziaway.ca website. The broker will have access
                to the description of the property estimated by the User,
                namely, the type of property, the number of rooms, the surface
                of the land or the living space, the type of project of the User
                and the time frame of the project
              </p>
              <p>
                The prospecting function distinguishes between two types of
                Users:
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`•	Users to "Prospect": These users do not wish to be contacted by a broker. For this type of User, Ziaway Technology will provide brokers with information about the property, the profile (seller or buyer), the desired time frame for the project and an approximate location of the estimated property. The broker will not have access to the User's full address and personal data. 
This type of contact can be added to the Broker's contact list without this contact being exclusive to him. 
                    •	Users to "Contact": These users wish to be contacted by a broker. For this type of User, Ziaway Technology will provide brokers with information about the property, the profile (seller or buyer), and the desired time frame for the project. The broker will have access to the full address and personal data of the users.
This type of contact once accepted by the broker, the contact is assigned only to the broker who accepted the contact.
                `}
              </p>
              <p>
                The broker also has the possibility of assigning statuses and
                comments for each User he has selected.
              </p>
              <p>
                The information made available to brokers is transmitted as it
                is completed by users and Ziaway Technologie shall in no case be
                held responsible for the truthfulness, accuracy and/or relevance
                of the information transmitted (see General Terms of Use).
              </p>
              <p>
                It is specified that the obligation of Ziaway Technologie is
                limited to the display of Users who have made estimates on its
                site and does not guarantee in any case a number of contacts for
                a given period.
              </p>
              <p>
                Furthermore, Ziaway Technologie's obligation is limited to
                communicating the data entered by Clients to the broker, without
                guaranteeing the accuracy of the latter's contact information or
                their intention to sell or buy, and without guaranteeing that
                they are not already bound by a mandate with another real estate
                agent or broker for the sale or purchase of a property, or that
                advertisements relating to the sale of this property have
                already been published.
              </p>
              <h6>6.2.3 THE EXPERT OFFER</h6>
              <p>
                The Expert offer integrates the content of the Essential and
                Performance offers as described above and also allows access to
                the Connect function.
              </p>
              <p>
                The Expert offer consists of Ziaway Technology sending emails to
                Users in order to offer them a connection with brokers who have
                subscribed to the offer. The email will display the photo and
                name of several recommended brokers.
              </p>

              <p>
                The User will then be able to select the broker with whom he/she
                wishes to be put in contact. The User will have to update
                his/her information and accept that it be transmitted to the
                selected broker. From that moment on, the User's information
                will only be transmitted to the broker selected on his/her
                proziaway.com account.
              </p>
              <p>
                Ziaway Technologie's obligation is limited to sending emails
                proposing that Clients contact one or more brokers located near
                the property they are considering selling or buying.
              </p>
              <p>
                Accordingly, Ziaway Technology's obligation is limited to
                introducing potential Clients to the Broker. Ziaway Technology
                does not guarantee a minimum number of Clients whose details
                will be transmitted to the Broker
              </p>
              <p>
                Furthermore, once the Client's acceptance has been obtained,
                Ziaway Technology's obligation is limited to communicating to
                the broker the data entered by the Clients, without guaranteeing
                the accuracy of the latter's contact information or their
                intention to sell or buy, and without guaranteeing that they are
                not already bound by a mandate with another real estate agent or
                broker for the sale or purchase of a property, or that
                advertisements relating to the sale of this property have
                already been published.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 7. BILLING AND PAYMENT </h4>

              <h6>7.1. RATES FOR SERVICES</h6>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The current rates for the services offered are freely set by
                Ziaway Technologie. They are communicated on the site on the
                page relating to the services offered
                https://pro.ziaway.ca/forfait-et-tarifs or by any other means
                whatsoever, and are automatically applicable since 01/01/2023.
                Prices may vary depending on the type and duration of the
                services offered. They may also be subject to change over time.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie reserves the right to revise its prices at
                any time. Upon renewal of a Service or the Contract, the rates
                and conditions applied will be those in effect on the date of
                renewal. Broker may terminate the Contract upon renewal without
                penalty.
              </p>

              <h6>7.2. BILLING AND PAYMENT FOR SERVICES </h6>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Broker agrees in advance that invoices may be issued
                electronically and not on paper and may take the place of the
                original invoice. In the context of invoicing, it is hereby
                specified that any period begun (weekly, monthly or annual) is
                due regardless of the due date of the Service. Any invoiced
                price will not be refunded. Payment is due upon registration.
                The credit cards accepted are those accepted by the online
                payment interface.
              </p>

              <h6>7.2.1 MEANS OF PAYMENT </h6>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Any payment made by the broker r by a means that is not offered
                at the time of subscription is only binding on the broker, who
                will be the only one to bear any losses linked to the use of an
                unauthorized means of payment.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Regardless of the payment method chosen, the broker must ensure
                that he/she is able to honor the payments. Ziaway Technologie
                will not be held responsible for any costs generated by a lack
                of funds or any other problem encountered during payment.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The broker must update his payment method via his professional
                space in case of expiration of his credit card to avoid any
                payment problem. All fees related to a default of payment
                related to an expired or invalid credit card will be charged
                back to the Broker .
              </p>
              <h6>7.2.2 LATE PAYMENT </h6>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                For any late payment, a reminder will automatically be sent to
                the brokers. After 7 days from the reminder, Ziaway
                Technologie's services will be interrupted and a formal notice
                for non-payment may be issued.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 8: DURATION OF SERVICES</h4>
              <h5>8.1. DURATION</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie offers 3 types of subscription terms; weekly,
                monthly and yearly. The Contract comes into effect from the date
                of the first invoice and is agreed to for the chosen duration.
                It will then be renewed by tacit agreement unless terminated
                before the expiry date. It is hereby specified that any period
                started is due regardless of the due date. Any period paid, even
                in advance, or invoiced is due, even in the event of payment or
                invoicing during the 7-day notice period
              </p>
              <h5>8.2. TERMINATION</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The client is informed that he/she may terminate his/her
                subscription directly from his/her profile, which is accessible
                from his/her professional account.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event that the Broker fails to meet its payment
                obligations under the Agreement, Ziaway Technologie may
                terminate this Agreement, modify, suspend, limit or cancel
                access to the Service, as of right, directly online and within 7
                calendar days of the reminder sent to the Broker's account
                Ziaway Technologie may also terminate, modify, suspend, limit or
                cancel access to the Service in the event of misuse of the
                portal, denigration or publication of criminally reprehensible
                content . Ziaway Technologie may also terminate this Agreement,
                without prior notice, in the event of repeated breaches by the
                Broker.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In such cases, the Broker may not claim any compensation. If
                necessary, Ziaway Technologie will also be entitled to claim
                compensation for the damage suffered. In the event of
                termination of any of the Services, all other articles of the
                Agreement that were not subject to such termination shall remain
                in effect.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of termination or suspension of the Contract,
                Ziaway Technology shall be entitled to demand immediate payment
                of any amount invoiced.
              </p>

              <h5>8.3. NOTICE PERIOD</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                During the notice period, if any, the relationship between
                Ziaway Technologie and the Broker shall continue in a loyal,
                sincere and normal manner; the Parties shall carefully and
                diligently execute the Agreement until the expiration of the
                7-day notice period. In the event of termination or cancellation
                of the Agreement for any reason, the Broker agrees to delete all
                references to collaboration with Ziaway Technology. The Broker
                also agrees to return all documents or posters in its possession
                that refer to Ziaway Technology.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of termination of the Agreement by the Broker, any
                scheduled fees will be due for the period in progress on the
                effective date of termination.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 9. CONFIDENTIALITY</h4>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                All information communicated by Ziaway Technologie to the
                broker, as well as all information communicated by the broker to
                Ziaway Technologie, is absolutely confidential between the
                parties, with the exception of information relating to real
                estate sold or presented for sale, which may be used by the
                parties without restriction both for their statistics and in
                their dealings with the Client. Each of the parties shall
                therefore refrain from disseminating to third parties any
                information, documents, and technical, commercial, financial and
                operational information relating to the subject matter of the
                Contract and shall take all necessary measures with its
                personnel to ensure this confidentiality. The above
                confidentiality obligations shall remain in effect for 2 years
                after the expiration of this Agreement
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 10. BROKER'S WARRANTIES</h4>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The broker guarantees Ziaway Technologie against any complaint,
                claim and/or demand from a third party regarding the information
                published on the various Ziaway Technologie sites, particularly
                regarding the properties sold. The broker is solely responsible
                for any actions he or she may take in the course of his or her
                business and, more specifically, in his or her relations with
                Clients or contacts made directly or indirectly through the
                Sites.
              </p>
              <br />
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Broker is solely responsible for the use of the content and
                data published on the various Ziaway Technologie websites and
                declares that all of the content and data provided and made
                available to Ziaway Technologie for integration into the site
                presenting his or her activity does not infringe any
                intellectual or industrial property right, nor violate any right
                to privacy (including the right to one's image) belonging to a
                third party. He assures that he has all the necessary
                authorizations of diffusion, national and international, of the
                texts and images. The User declares that he/she is aware that
                any image posted on the Internet may be copied by Users. Ziaway
                Technologie shall not be held liable for any data or content
                that is erroneous or contrary to current law. The broker-user
                guarantees against any claim or proceeding brought against
                Ziaway Technologie due to the non-conforming use of Ziaway
                Technologie's services, both in terms of the costs that Ziaway
                Technologie would have to bear in its defense and in terms of
                any conviction in this regard
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 11. PROHIBITED BEHAVIORS</h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Notwithstanding all the measures that Ziaway Technologie may
                apply in the event of publication of fraudulent Sales
                References, the Broker is reminded that publishing fraudulent
                Sales References, participating in the publication of fraudulent
                Sales References or soliciting fraudulent Sales References is
                likely to fall within the scope of the criminal offence of
                deceptive commercial practices.
              </p>
              <br />
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Consequently, the Broker declares that he/she is aware that any
                manipulation of Sales References, whether it be the seizure of
                fraudulent Sales References or the modification of all or part
                of the constituent elements of a Sales Reference, is likely to
                constitute a criminal offence, in particular an unfair or
                deceptive commercial practice.
              </p>
              <p>
                If necessary, Ziaway Technologie reserves the right to request
                copies of the broker's mandate register and transaction files
                from the broker or the broker's chamber in order to verify the
                accuracy of the data transmitted to it and, if the broker
                refuses, to delete all or part of the sales references entered,
                subject to a 7-day notice. Notwithstanding the measures that
                Ziaway Technologie may apply in the event of publication of
                fraudulent Customer Reviews, the Broker is reminded that
                publishing fraudulent Customer Reviews, participating in the
                publication of fraudulent Customer Reviews or soliciting
                fraudulent Customer Reviews is likely to fall within the scope
                of the criminal offence of deceptive commercial practices.
                Consequently, the broker declares to be informed that any
                manipulation of opinions, whether it is the writing of false
                positive opinions or the deletion of negative opinions, or the
                reproduction of the same opinion (duplication of opinions) is
                likely to constitute an unfair or deceptive commercial practice.
                The Broker agrees not to publish information or content on the
                Sites that is pornographic, violent, racist or, more generally,
                contrary to good morals and current Quebec law, as well as any
                information or content that may harm the interests, credit,
                reputation or reputation of Ziaway Technologie.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 12. LIMITATION OF LIABILITY </h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie makes its best efforts to provide a quality
                service. However, due to the specificities of the Internet,
                Ziaway Technologie does not guarantee access to the Sites, the
                continuity of the Services or the speed of access to the Sites
                and Services. In this regard, Ziaway Technologie is bound only
                by an obligation of means. Ziaway Technologie shall not be held
                liable for any damages resulting from the temporary inability to
                access any of the Services. Within the limits of applicable law,
                Ziaway Technologie disclaims all liability for indirect damages,
                loss of profits, loss of data, loss of opportunity or loss of
                customers or, more generally, any loss of intangible property,
                even if Ziaway Technologie has been informed of the potential
                for such damages.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Furthermore, no damages may be claimed by the broker and Ziaway
                Technologie shall not be held liable in the event of malfunction
                of the computer tools made available to the broker by Ziaway
                Technologie. The broker may not claim damages and Ziaway
                Technologie shall not be held liable in the event of :
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`•	Delayed updates, technical problems related to the tools, software or techniques made available to the broker;	
                •	Estimation error calculated by the estimation tool; the commitment to make these tools available hereunder relates only to the said provision and not to the reliability of the technique used or of the results, which are only estimation indices.
                `}
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie shall not be held liable for any indirect
                damage. Indirect damage includes any financial or commercial
                loss, loss of sales, profits, data, orders or customers. No
                damages may be claimed by the broker from Ziaway Technologie and
                its liability may not be sought in the event of refusal to sell
                by the recommended client, refusal to accept a mandate or an
                appointment by the recommended client.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 13. GOOD FAITH</h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Each of the parties agrees to perform the Agreement in good
                faith and without reservation and not to do or say anything that
                may be detrimental to the interests, credit, reputation or good
                name of either of them or of Ziaway Technology's partner brokers
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 14. FORCE MAJEURE</h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie shall not be held liable if the
                non-performance or delay in the performance of any of its
                obligations described in the GSTA is due to a case of force
                majeure as defined by the jurisprudence of courts and tribunals.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In case of occurrence of any of the above events, Ziaway
                Technologie will endeavor to inform the customer as soon as
                possible.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 15. INTELLECTUAL PROPERTY </h4>
              <h5 className="font-weight-bold">15.1 TECHNOLOGY</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Parties agree that the applications, software, databases and
                technology developed by Ziaway Technology, in particular the
                estimation tool and the Price Pages, remain its exclusive
                property.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Broker may not, in any way, claim any ownership rights
                whatsoever, including intellectual property rights, over the
                technology, databases, applications and software created by
                Ziaway Technology and made available to it during the term of
                the Contract.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The data collected by Ziaway Technologie from third parties in
                the performance of this Agreement, as well as the content
                provided by Ziaway Technologie, remain its property. Ziaway
                Technologie owns all intellectual property rights to the
                organization and structure of the database of real estate
                prices, clients and prospects. This database constitutes,
                through the selection and organization of the information it
                contains, an intellectual creation for which Ziaway Technologie
                is the sole owner of the copyright.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Access to this data under the conditions stipulated in the
                Contract does not entail any transfer of intellectual property
                rights or sui generis rights to the benefit of the Broker on
                these data, nor on the database. The Broker is expressly
                prohibited from using this data to create a new database or a
                service that competes, directly or indirectly, with the Ziaway
                Technologie database or the Site. In addition, the broker is
                prohibited from extracting the contents of the databases
                accessible on the Sites by permanent or temporary transfer.
              </p>
              <br />
              <h5 className="font-weight-bold">15.2 TRADEMARK</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Parties authorize each other to use the trademark (verbal,
                semi-figurative or figurative), the trade name, the corporate
                name or any other distinctive sign of the other Party for the
                sole purpose of performing the Contract and for its sole
                duration. This right of use is non-transferable, for any reason
                whatsoever, and is granted exclusively within the framework of
                these terms and conditions; it may only be exercised in
                accordance with the terms and conditions set forth herein.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The Parties expressly agree that they will not make any
                modification, alteration or addition to the reproduction of the
                distinctive signs of the other Party. Each Party guarantees that
                the use by the other Party of the various distinctive signs that
                it enjoys will not infringe the rights of any third party.
              </p>

              <br />
              <h5 className="font-weight-bold">15.3 DATA LICENSE </h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The broker grants Ziaway Technology a license to use the data
                relating to the properties sold. This license grants Ziaway
                Technologie the right to reproduce, record, exploit, extract,
                adapt and represent this data on any current or future medium,
                and in particular on any tangible or intangible recording,
                publishing or broadcasting medium, online or offline. This right
                includes the reproductions necessary for any storage,
                transmission or downloading operation. All the above rights
                concern any destination, in particular commercial, with any
                public, directly or indirectly. It is specified that the
                transferred right is a non-exclusive right.
              </p>

              <br />
              <h5 className="font-weight-bold"> 15.4 DATA LICENSE</h5>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                As the Contract is intuitu personae, it may not be assigned or
                transferred in any manner whatsoever, in whole or in part, for
                any reason whatsoever, by either Party without the prior written
                consent of the other Party.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                However, notwithstanding the foregoing, the Agreement shall be
                transferred by operation of law in the event of a change in the
                form of the company, a change of partner(s), a merger or
                absorption of a Party, or, unless Ziaway Technologie objects, a
                transfer of goodwill. In such cases, the Party affected by the
                change must inform the other Party.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 16. PERSONAL DATA </h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Ziaway Technologie complies with current regulations on the
                protection of personal data. The client and user of the website
                is informed that he/she has a number of rights regarding the
                processing of his/her personal data (right of access,
                opposition, r ectification, deletion, portability, etc.).
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                These rights can be exercised by e-mail to the address:
                support@ziaway.ca
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                The broker is invited to consult Ziaway Technology's Privacy
                Policy to obtain more information on the processing of his
                personal data.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 17. SETTLEMENT OF DISPUTES </h4>
              <h5 className="font-weight-bold"> ARTICLE 17.1 JURISDICTION </h5>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of a dispute between the Ziaway Technologie
                platform and the User regarding the interpretation, execution or
                termination of these terms and conditions, the Parties shall
                endeavor to settle the dispute amicably.
              </p>

              <h5 className="font-weight-bold"> ARTICLE 17.2 MEDIATOR </h5>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of a dispute between the Ziaway Technologie
                platform and the User regarding the interpretation, execution or
                termination of these terms and conditions, the Parties shall
                endeavor to settle the dispute amicably.
              </p>

              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                If necessary, the User may contact the Mediator designated by
                Ziaway Technology. The mediation entity selected is the Quebec
                City - Chaudière - Appalaches Community Justice Center.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event of a dispute, the User may file a complaint online
                at: https://www.justicedeproximite.qc.ca/centres/quebec/.
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                In the event that the Mediation fails, the User retains the
                option of referring the matter to the judicial authority, i.e.
                the court with jurisdiction over Ziaway Technologie' s
                headquarters.
              </p>
            </section>

            <section>
              <h4 className="mb-3">ARTICLE 18. QUESTIONS </h4>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                Any question or claim concerning the present GSTP can be
                formulated:
              </p>
              <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
                {`• By e-mail to: support@ziaway.ca `}
              </p>
            </section>
          </div>
        </main>
      </>
    );

  return (
    <>
      <main className="py-5">
        <div className="container">
          {newTab !== "true" && (
            <div>
              <button
                className="btn  btn-primary btn-rounded mb-3 "
                Style={{ height: "min-content" }}
                onClick={() => navigate(-1)}
              >
                Retourner
              </button>
            </div>
          )}
          <h2 className="mb-4 text-primary">
            CONDITIONS GÉNÉRALES DE PRESTATIONS DE SERVICES
          </h2>
          <p>(Version mise à jour le 05/01/2023)</p>

          <section>
            <h4 className="mb-3">ARTICLE 1. DÉFINITIONS </h4>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`
         •	Annonce :  Annonce immobilière de vente ou de location ajoutée sur le Site par les courtiers.
         •	Avis client : Appréciation des services du courtier par ses clients, conformément aux présentes CGU, aux CGPS, et en particulier à la charte des propriétés vendues, 
         •	Données personnelles : désigne toute information se rapportant, directement ou indirectement, à une personne physique identifiée ou identifiable.
         •	Partenaire : Tout courtier ayant un abonnement gratuit ou payant auprès de Ziaway Technologie
         •	Partie : désigne communément l’Utilisateur et le Prestataire.
         •	Services : désigne l’ensemble des services proposés par Ziaway Technologie aux utilisateurs par l’intermédiaire de la Plateforme 
         •	Site : désigne la plateforme en ligne accessible à partir du Site web. Pour les professionnels la plateforme est accessible depuis :  https://proziaway.com. La Plateforme regroupe l’ensemble des pages web et fonctionnalités proposées aux Utilisateurs.
         •	Utilisateur : désigne toute personne physique accédant et naviguant sur la plateforme https://proziaway.com 
          Mot de passe : désigne le mot de passe associé à l’Identifiant. A partir de l’Identifiant et du Mot de passe, l’utilisateur pourra bénéficier des Services proposés sur la Plateforme.
          
            `}
            </p>
          </section>
          <section>
            <h4 className="mb-3">ARTICLE 2. OBJET </h4>
            <p>
              Les présentes Conditions Générales de Prestations de Services
              (CGPS) conclues entre le prestataire Ziaway Technologie et le
              client (les courtiers), ont pour objet de fixer les dispositions
              contractuelles relatives aux droits et obligations respectifs des
              Parties dans le cadre des prestations proposées par Ziaway
              Technologie. Les CGPS s’appliquent en complément des Conditions
              Générales d’Utilisation de la plateforme et de la Politique de
              confidentialité accessible sur le Site.
            </p>

            <h5 className="font-weight-bold">2.1. APPLICABILITÉ DES CGPS</h5>
            <p>
              Les présentes CGPS sont entrées pleinement en vigueur à leur date
              de publication sur le Site https://proziaway.com et s’appliquent
              ainsi à toutes les services proposés par Ziaway Technologie.
            </p>
            <br />
            <h5 className="font-weight-bold">2.2. OPPOSABILITÉ DES CGPS</h5>
            <p>
              Toute commande de prestations proposées par Ziaway Technologie
              implique l’adhésion définitive, entière et sans réserve du client
              aux présentes CGPS.
            </p>
          </section>

          <section>
            <h4 className="mb-3">
              ARTICLE 3. ACCEPTATION DES CONDITIONS GÉNÉRALES DE PRESTATIONS DE
              SERVICES
            </h4>

            <p>
              Le client s’engage à lire attentivement les présentes Conditions
              Générales de Prestations de Services (CGPS) et est invité à les
              imprimer, les télécharger et à en conserver une copie.
              L’utilisation des fonctionnalités du Site https://proziaway.com et
              des services impliquent l’acceptation expresse des présentes CGPS.
              A ce titre, le client reconnaît avoir été parfaitement informé de
              la nature des prestations proposées par Ziaway Technologie, et
              reconnaît que cette dernière a été à son entière disposition pour
              l’informer de tout élément déterminant avant la conclusion d’une
              prestation.
            </p>

            <p>
              Il est précisé que les présentes conditions sont référencées en
              bas de chaque page du site https://proziaway.com au moyen d’un
              lien hypertexte et peuvent ainsi être consultées à tout moment.
            </p>
          </section>

          <section>
            <h4 className="mb-3">
              ARTICLE 4. MODIFICATION DES CONDITIONS GÉNÉRALES DE PRESTATIONS DE
              SERVICES
            </h4>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les présentes conditions s’appliquent à tous les clients de Ziaway
              Technologie. Elles ont été mises à jour dernièrement le 05 janvier
              2023. Elles pourront être modifiées à tout moment, notamment pour
              faire face aux évolutions législatives ou réglementaires. Une
              notification sera alors adressée aux clients pour les prévenir des
              modifications substantielles effectuées. En tout état de cause,
              les conditions applicables sont celles en vigueur au moment de la
              navigation sur le site ou lorsqu’elles sont présentées aux
              clients.
            </p>
          </section>

          <section>
            <h4 className="mb-3">
              ARTICLE 5. VALIDITÉ DES CONDITIONS GÉNÉRALES DE PRESTATIONS DE
              SERVICES
            </h4>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Si l’une quelconque des stipulations des présentes CGPS venait à
              être déclarée nulle au regard d’une disposition législative ou
              réglementaire en vigueur et/ou d’une décision de justice ayant
              autorité de la chose jugée, elle sera réputée non écrite mais
              n’affectera en rien la validité des autres dispositions qui
              demeureront pleinement applicables.
            </p>
            <br />
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Une telle modification ou décision n’autorise en aucun cas les
              clients et Utilisateurs à méconnaître les présentes CGPS.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 6. SERVICES DE ZIAWAY TECHNOLOGIE</h4>
            <h5 className="font-weight-bold">6.1. ACCÈS AUX SERVICES </h5>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              La souscription des services proposés par Ziaway Technologie se
              fait uniquement en ligne via la Plateforme depuis la page dédiée à
              cet effet accessible depuis l’adresse
              https://pro.ziaway.ca/forfait-et-tarifs.
            </p>
            <br />
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Avant toute souscription à un service, le courtier déclare avoir
              la capacité juridique et ainsi disposer des pouvoirs nécessaires
              pour l’acceptation des présentes CGPS. Par ailleurs le client
              déclare être titulaire d’un permis de courtage immobilier valide
              et ne faire l’objet d’aucune mesure qui en empêche la pratique
              lors de l’inscription et/ou de l’utilisation de la plateforme
              https://proziaway.com
            </p>
            <br />
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de changement juridique ou administratifs, les clients
              s'engagent à avertir Ziaway Technologie et à mettre à jour leur
              profil en particulier, dans le cas d’incapacité professionnelle ou
              de changement de statut de leur permis d’exercice survenant durant
              l'exécution du Contrat.
            </p>
            <br />
            <h5 className="font-weight-bold">6.2. DESCRIPTION DES SERVICES</h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Nos clients courtiers peuvent choisir l’une de nos 3 offres
              d’abonnements ; l’offre essentiel, l’offre performance et l’offre
              expert. Ziaway Technologie propose une période d’essai gratuite de
              7 jours et sans engagement.
            </p>
            <h6>6.2.1 L’OFFRE ESSENTIELLE </h6>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Cette offre permet une exposition digitale du courtier sur les
              différents supports proposés par Ziaway Technologie. L’exposition
              aura lieu sous forme de recommandation sur les supports suivants :
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`•	Le pop-up de la page d’accueil du site web ziaway.ca : L’affichage sur ce pop-up se fait de manière aléatoire. Ziaway Technologie fera ses meilleurs efforts pour garantir une exposition équitable à tous les courtiers clients. Toutefois, l’affichage des pop-ups est dépendant de la durée de temps passée sur la page d’accueil par les utilisateurs. Dans ce cadre Ziaway Technologie ne peut garantir que tous les courtiers clients seront exposés.
L’affichage du pop-up se fait uniquement sur le site web et non pas sur la version mobile

•	Le rapport d’estimation affiché sur le site ziaway.ca : En fonction des informations indiquées par les utilisateurs lors de leurs estimations, le rapport d’estimation propose une liste de courtiers recommandés. Cette recommandation se fait en présentant le nom, prénom, photo, bannières, les supports de communication en ligne du courtier (site internet, page Facebook, page Linkedin, page Tweet, page Instagram) dès lors que ce dernier a bien complété toutes ces informations sur son compte proziaway.com. Les courtiers sont affichés et par conséquent recommandés en fonction de :
o	Leur dynamisme : La comparaison entre les Courtiers immobilier s’effectue en fonction des ventes qu’ils ont réalisées et publiées sur Ziaway Technologie au cours des 12 derniers mois dans un secteur géographique déterminé.	

o	La pertinence : La comparaison entre les Courtiers immobilier s’effectue en fonction de leur performance pour vendre une propriété similaire et proche de l’adresse saisie. La similarité de la propriété prend en compte l’emplacement, le type de propriété, le nombre de pièces, la surface ainsi que la date de vente. Plus un professionnel a vendu et publié de propriétés similaires et proches de l’adresse, mieux il est classé.

o	La distance : La comparaison entre les Courtiers immobilier s’effectue en fonction de la distance avec l’adresse saisie. Le classement s’effectue du Courtier immobilier ayant vendu des propriétés le plus proche de l’adresse saisie au professionnel ayant vendu les plus éloignés. 

                  `}
            </p>
            <br />
            <p>
              Le classement n’est pas influencé par une quelconque rémunération
              de la part des Courtiers immobilier. Ces différents classements
              disponibles sur le Site sont continuellement mis à jour. Ziaway
              Technologie s’efforce d’offrir un classement en temps réel en
              intégrant chaque donnée transmise par les Partenaires ou leurs
              Clients dans le système permettant la comparaison.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`•	Le courriel d’estimation : Un courriel est envoyé à chaque utilisateur après chaque évaluation. Le courriel reprend le contenu du rapport d’évaluation à l’exception des liens vers les supports de communication en ligne du courtier (site internet, page Facebook, page Linkedin, page Tweet, page Instagram) ». Le courriel d’estimation offre ainsi l’opportunité à nouveau de promouvoir les courtiers recommandés.
                  `}
            </p>
            <p>
              Il précisé que l’obligation de Ziaway Technologie se limite à
              l’affichage des informations telles que saisie par les courtiers
              auprès des Utilisateurs réalisant des estimations sur son site
              ziaway.ca. En tout état de cause, Ziaway Technologie ne garantit
              pas un nombre d’affichage ou d’envoi de courriels.
            </p>
            <br />
            <h6>6.2.2 L’OFFRE PERFORMANCE </h6>
            <p>
              L’offre Performance intègre le contenu de l’offre Essentiel telle
              que décrite ci-dessus et permet également l’accès à la fonction
              Prospection.
            </p>
            <p>
              L’offre Performance permet de localiser en fonction des critères
              sélectionnés par les courtiers les Utilisateurs ayant réalisés une
              estimation sur le site ziaway.ca. Le courtier aura accès à la
              description de la propriété estimée par l’utilisateur, à savoir,
              le type de propriété, le nombre de pièces, de chambres, la surface
              du terrain ou de l’espace de vie, au type de projet de
              l’utilisateur et le délai de réalisation du projet
            </p>
            <p>La fonction prospection distingue deux types d’Utilisateurs :</p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`•	Les utilisateurs à « Prospecter » : Ces utilisateurs ne souhaitent pas être contactés par un courtier. Pour ce type d’Utilisateur, Ziaway Technologie mettra à disposition des courtiers les informations de la propriété, le profil (vendeur ou acheteur), le délai souhaité de réalisation du projet et une localisation approximative de la propriété estimée. Le courtier n’aura pas accès à l’adresse complète et aux données à caractères personnels des utilisateurs. 
Ce type de contact peut être ajouté dans la liste de contact du Courtier sans que ce contact lui soit exclusif. 

•	Les utilisateurs à « Contacter » : Ces utilisateurs souhaitent être contactés par un courtier. Pour ce type d’Utilisateur Ziaway Technologie mettra à disposition des courtiers les informations de la propriété, le profil (vendeur ou acheteur), le délai souhaité de réalisation du projet. Le courtier aura accès à l’adresse complète et aux données à caractères personnels des utilisateurs.
Ce type de contact dès lors qu’il est accepté par le courtier, le contact est affecté uniquement au courtier qui a accepté le contact.

                  `}
            </p>
            <p>
              Le courtier dispose également de la possibilité d’affecter des
              statuts et des commentaires pour chaque Utilisateur qu’il aura
              sélectionné.
            </p>
            <p>
              Les informations mises à la disposition des courtiers sont
              transmises telle qu’elles sont complétées par les utilisateurs et
              Ziaway Technologie ne pourra en aucun cas être tenu responsable de
              la véracité, exactitude et/ou pertinence des informations
              transmises (Cf Conditions générales d’utilisation).
            </p>
            <p>
              Il précisé que l’obligation de Ziaway Technologie se limite à
              l’affichage des Utilisateurs ayant fait des estimations sur son
              site et ne garantit en aucun cas un nombre de contacts pour une
              période donnée.
            </p>
            <p>
              Par ailleurs, l’obligation de Ziaway Technologie se limite à la
              communication au courtier des données saisies par les Clients,
              sans garantir l’exactitude des coordonnées de ces derniers ou leur
              intention de vendre ou d’acheter et sans garantir que ces derniers
              ne soient pas déjà liés par un mandat avec un autre agent
              immobilier ou un autre mandataire ou un autre courtier pour la
              vente ou l’achat d’une propriété l’évaluation, ou encore que des
              annonces relatives à la mise en vente de ce bien aient déjà été
              publiées.
            </p>
            <h6>6.2.3 L’OFFRE EXPERT</h6>
            <p>
              L’offre Expert intègre le contenu de l’offre Essentiel et
              Performance telle que décrites ci-dessus et permet également
              l’accès à la fonction Connecter.
            </p>
            <p>
              L’offre Expert consiste en l’envoi par Ziaway Technologie de
              courriels à des Utilisateurs afin de leur proposer une mise en
              relation avec les courtiers ayant souscrits à l’offre. Le courriel
              affichera la photo et le nom de plusieurs courtiers recommandés.
            </p>

            <p>
              L’Utilisateur pourra alors sélectionner le courtier avec qui il
              souhaite être mis en contact. L’Utilisateur devra mettre à jour
              ses informations et accepter qu’elles soient transmises au
              courtier sélectionné. A compter de ce moment, ses informations
              seront transmises uniquement au courtier retenu sur son compte
              proziaway.com.
            </p>
            <p>
              Il précisé que l’obligation de Ziaway Technologie se limite à
              l’envoi de courriels proposant aux Clients d’entrer en relation
              avec un ou des courtier(s) situés à proximité de la propriété
              qu’ils envisagent de vendre ou d’acheter.
            </p>
            <p>
              En conséquence, l’obligation de Ziaway Technologie se limite à la
              seule présentation de potentiels Clients au Courtier. Ziaway
              Technologie ne garantit pas un nombre minimal de Clients dont les
              coordonnées seront transmises au courtier de l’immobilier
            </p>
            <p>
              Par ailleurs, une fois l’acceptation du Client obtenue,
              l’obligation de Ziaway Technologie se limite à la communication au
              courtier des données saisies par les Clients, sans garantir
              l’exactitude des coordonnées de ces derniers ou leur intention de
              vendre ou d’acheter et sans garantir que ces derniers ne soient
              pas déjà liés par un mandat avec un autre agent immobilier ou un
              autre mandataire ou un autre courtier pour la vente ou l’achat
              d’une propriété l’évaluation, ou encore que des annonces relatives
              à la mise en vente de ce bien aient déjà été publiées.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 7. FACTURATION ET PAIEMENT </h4>

            <h6>7.1. TARIFS DES SERVICES</h6>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les tarifs en vigueur des prestations proposées sont librement
              fixés par Ziaway Technologie. Ils sont communiqués sur le site sur
              la page relative aux prestations proposées
              https://pro.ziaway.ca/forfait-et-tarifs ou par tout autre support
              que ce soit, et sont de plein droit applicable depuis le
              01/01/2023. Les prix peuvent varier selon le type et la durée des
              prestations proposées. Ils peuvent également faire l’objet
              d’évolutions dans le temps.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie se réserve le droit de réviser ses tarifs à
              tout moment. Lors de la reconduction ou du renouvellement d’un
              Service ou du Contrat, le tarif et les conditions appliqués seront
              le tarif et les conditions en vigueur au jour de la reconduction
              ou du renouvellement. Le courtier pourra résilier le contrat lors
              du renouvellement sans pénalités.
            </p>

            <h6>7.2. FACTURATION ET PAIEMENT DES SERVICES </h6>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier r accepte préalablement que les factures puissent être
              émises par voie électronique et non sur support papier et puissent
              tenir lieu de facture d'origine. Dans le cadre de la facturation,
              il est ici précisé que toute période commencée (semaine, mensuelle
              ou annuelle), est due indépendamment de la date d’échéance du
              Service. Tout prix facturé ne pourra faire l’objet d’aucun
              remboursement. Le paiement est dû à l’inscription. Les cartes
              bancaires acceptées sont celles acceptées par l’interface de
              paiement en ligne.
            </p>

            <h6>7.2.1 MOYENS DE PAIEMENTS</h6>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les moyens de paiement acceptés sont le paiement par carte
              bancaire Tout paiement réalisé par le courtier par un moyen qui
              n’est pas proposé lors de la souscription n’engage que le
              courtier, qui sera le seul à supporter les éventuelles pertes
              liées à l’utilisation d’un moyen de paiement non autorisé.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Indépendamment du mode de paiement choisi, le courtier doit
              s’assurer d’être en mesure d’honorer les paiements. En aucun cas
              Ziaway Technologie ne pourra être tenu responsable de frais
              générés par un manque de provisions ou tout autre problème
              rencontré lors du paiement.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier devra mettre à jour son moyen de paiement via son
              espace professionnel en cas d’expiration de sa carte bancaire afin
              d’éviter tout problème de paiement. Tous les frais liés à un
              défaut de paiement lié à une carte bancaire expirée ou invalide
              seront refacturés au courtier.
            </p>
            <h6>7.2.2 RETARD DE PAIEMENT </h6>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Pour tout retard de paiement, une relance sera automatiquement
              envoyée aux courtiers. Au-delà d’un délai de 7 jours à compter de
              la relance, les services de Ziaway Technologie seront interrompus
              et une mise en demeure pour défaut de paiement pourra être
              engagée.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 8 : DURÉE DES SERVICES </h4>
            <h5>8.1. DURÉE</h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie propose 3 types de durées pour les abonnements
              ; hebdomadaire, mensuelle et annuelle. Le Contrat entre en vigueur
              à compter de la date de la première facturation et est consenti
              pour la durée choisie. Il se renouvellera ensuite par tacite
              reconduction à défaut de dénonciation avant l’échéance. Il est ici
              précisé que toute période commencée est due indépendamment de la
              date d’échéance. Toute période payée, même de façon anticipée, ou
              facturée est due et ce, même en cas de paiement ou de facturation
              durant la période de préavis de 7 jours.
            </p>
            <h5>8.2. RÉSILIATION</h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le client est informé de sa possibilité de résilier son abonnement
              directement depuis son profil, accessible sur son compte
              professionnel.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de manquement du courtier à l’obligation de paiement lui
              incombant au titre du Contrat, Ziaway Technologie pourra résilier
              les présentes, modifier, suspendre, limiter ou supprimer l’accès
              au Service, de plein droit directement en ligne et dans un délai
              de 7 jour calendaire après la relance adressée sur le compte du
              courtier.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie pourra également résilier les présentes,
              modifier, suspendre, limiter ou supprimer l’accès au Service en
              cas de mauvaise utilisation du portail, de dénigrement ou de
              publication de contenus pénalement répréhensible.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Il est précisé que Ziaway Technologie pourra également résilier
              les présentes, sans mise en demeure préalable, en cas de
              manquements répétés de la part du courtier. Dans ces hypothèses,
              le courtier ne pourra réclamer aucune indemnité quelconque. Le cas
              échéant, Ziaway Technologie sera également en droit de réclamer
              des indemnités destinées à compenser le préjudice subi. En cas de
              résiliation de l’un des Services, tous les autres articles
              constituant le Contrat et n’ayant pas fait l’objet de cette
              résiliation restent en vigueur. En cas de résiliation ou de
              suspension du Contrat, Ziaway Technologie sera en droit d’exiger
              le paiement immédiat de toute somme facturée.
            </p>

            <h5>8.3. PERIODE DE PREAVIS</h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Pendant l’éventuelle période de préavis, les relations entre
              Ziaway Technologie et le courtier doivent se poursuivre de façon
              loyale, sincère et normale ; les Parties doivent exécuter avec
              soin et diligence le Contrat jusqu'à expiration du délai de
              préavis de 7 jours. En cas de dénonciation ou de résiliation du
              Contrat pour quelque cause que ce soit, le courtier s’engage à
              supprimer toute référence à une collaboration avec Ziaway
              Technologie. Il s’engage également à restituer tous les documents
              ou affichettes qu’il pourrait détenir faisant référence à Ziaway
              Technologie.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de résiliation du Contrat par le courtier, toute redevance
              prévue sera due pour la période en cours à la date d’effet de la
              résiliation.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 9. CONFIDENTIALITÉ</h4>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Tous les renseignements qui ont été communiqués par Ziaway
              Technologie au profit du courtier, de même que tous ceux qui ont
              été communiqués par le courtier au profit de Ziaway Technologie,
              sont absolument confidentiels entre les parties, à l’exception des
              informations relatives aux biens immobiliers vendus ou présentés à
              la vente qui pourront être utilisés par les Parties sans aucune
              restriction tant pour leurs statistiques que dans leurs rapports
              avec la Clientèle. Chacune des parties s’interdit donc de diffuser
              à des tiers toutes informations, documents, et renseignements
              d’ordre technique, commercial, financier et opérationnel relatifs
              à l’objet du Contrat et à prendre toutes les mesures nécessaires
              auprès de son personnel pour garantir ce caractère confidentiel.
              Les obligations de confidentialité visées ci-dessus demeureront en
              vigueur 2 ans après l’expiration des présentes.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 10. GARANTIES DU COURTIER</h4>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier garantit Ziaway Technologie contre toute plainte,
              réclamation et/ou revendication d’un tiers au sujet des
              informations publiées sur les différents sites de Ziaway
              technologie et notamment relatives aux propriétés vendues. Le
              courtier est seul responsable des actions qu'il pourrait
              entreprendre dans le cadre de son activité et plus
              particulièrement dans ses relations avec les Clients ou contacts
              apportés directement ou indirectement par l'intermédiaire des
              Sites.
            </p>
            <br />
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier est seul responsable de l’utilisation des contenus et
              données publiés sur les différents sites de Ziaway Technologie et
              déclare que l’ensemble des contenus et données qu’il fournit et
              met à la disposition de Ziaway Technologie pour intégration dans
              l’emplacement présentant son activité ne contrefait aucun droit de
              propriété intellectuelle ou industrielle, ne viole aucun droit à
              la vie privée (dont le droit à l’image) appartenant à un tiers. Il
              assure qu’il dispose de toutes les autorisations nécessaires de
              diffusion, nationales et internationales, des textes et images. Il
              déclare être informé que toute image diffusée sur Internet peut
              être copiable par les Utilisateurs. La responsabilité de Ziaway
              Technologie ne saurait être nullement engagée en cas de données ou
              contenus erronés ou contraires à la loi en vigueur. Le courtier
              utilisateur garantit contre toute réclamation ou procédure formée
              à l’encontre de Ziaway Technologie du fait de l’utilisation
              non-conforme par lui des services de Ziaway technologie tant à
              hauteur des coûts que Ziaway Technologie devrait supporter pour sa
              défense que de toute condamnation à ce titre.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 11. COMPORTEMENTS INTERDITS</h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Nonobstant l’ensemble des mesures que Ziaway Technologie pourrait
              appliquer en cas de publication de Références de vente
              frauduleuses, il est rappelé au courtier que le fait de publier
              des Références de vente frauduleuses, de participer à la
              publication de Références de vente frauduleuses ou de solliciter
              des Références de vente frauduleuses est susceptible de relever
              notamment du délit pénal de pratique commerciale trompeuse.
            </p>
            <br />
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En conséquence, le courtier déclare être informé que toute
              manipulation de Références de vente, qu’il s’agisse de la saisie
              de Références de vente frauduleuses ou de la modification de tout
              ou partie des éléments constitutifs d’une référence de vente, est
              susceptible de constituer une infraction pénale, notamment une
              pratique déloyale ou pratique commerciale trompeuse.
            </p>
            <p>
              Le cas échéant, Ziaway Technologie se réserve la possibilité de
              demander au courtier ou à la chambre des courtiers des copies, le
              registre des mandats du courtier, ainsi que ses dossiers de
              transactions, afin de vérifier l'exactitude des données qui lui
              ont été transmises et, en cas de refus du courtier, de supprimer
              tout ou partie des références de vente saisies, sous réserve du
              respect d’un préavis de 7 jours. Nonobstant l’ensemble des mesures
              que Ziaway Technologie pourrait appliquer en cas de publication
              d’Avis clients frauduleux, il est rappelé au courtier que le fait
              de publier des Avis clients frauduleux, de participer à la
              publication d’Avis clients frauduleux ou de solliciter des Avis
              clients frauduleux est susceptible de relever notamment du délit
              pénal de pratique commerciale trompeuse. En conséquence, le
              courtier déclare être informé que toute manipulation d’avis, qu’il
              s’agisse de la rédaction de faux avis positifs ou de la
              suppression d’avis négatifs, de reproduction d’un même avis
              (dédoublonnement d’avis) est susceptible de constituer tant une
              pratique déloyale ou pratique commerciale trompeuse. Le courtier
              s’interdit de publier les Sites des informations ou des contenus à
              caractère pornographique, violent, raciste et plus généralement
              contraires aux bonnes mœurs et au droit Québécois en vigueur ainsi
              que toute information ou contenu qui puisse nuire aux intérêts, au
              crédit, à la notoriété ou à la réputation de Ziaway Technologie.
            </p>
          </section>

          <section>
            <h4 className="mb-3">
              ARTICLE 12. CLAUSE LIMITATIVE DE RESPONSABILITÉ{" "}
            </h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie fait ses meilleurs efforts pour assurer un
              service de qualité. Néanmoins, compte tenu des spécificités du
              réseau Internet, Ziaway Technologie ne garantit pas l'accès aux
              Sites, la continuité des Services ou encore la vitesse d'accès aux
              Sites et aux Services. Ziaway Technologie n'est tenu à cet égard
              que d'une obligation de moyens. Sa responsabilité ne pourra pas
              être engagée en cas de dommages liés à l'impossibilité temporaire
              d'accéder à l'un des Services. Dans les limites de la législation
              en vigueur, Ziaway Technologie décline toute responsabilité du
              fait des dommages indirects et en cas de manque à gagner, perte de
              bénéfices, perte de données, perte de chance ou perte de clientèle
              ou plus généralement toute perte de biens incorporels et ce même
              si Ziaway Technologie a été informée de la potentialité de tels
              dommages.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Par ailleurs, aucun dommages-intérêts ne pourra être réclamé par
              le courtier et la responsabilité de Ziaway Technologie ne saurait
              être recherchée en cas de dysfonctionnement des outils
              informatiques mis à la disposition du courtier par Ziaway
              Technologie. Aucun dommages-intérêts ne pourra être réclamé par le
              courtier et la responsabilité de Ziaway Technologie ne saurait
              être recherchée en cas :
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`•	De retard de mise à jour, de problème technique lié aux outils, logiciels ou techniques mis à la disposition du  courtier ;	
•	D’erreur d’estimation calculée par l’outil d’estimation ; l’engagement de mise à disposition de ces outils au titre des présentes ne portant que sur ladite mise à disposition et non sur la fiabilité de la technique utilisée ou des résultats qui ne sont que des indices d’estimation.

                  `}
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie ne pourra être tenu pour responsable d'aucun
              préjudice indirect. Est considéré comme préjudice indirect, tout
              préjudice financier ou commercial, perte de chiffre d'affaires, de
              bénéfice, de données, de commande ou de clientèle. Aucun
              dommages-intérêts ne pourra être réclamé par le courtier à Ziaway
              Technologie et sa responsabilité ne saurait être recherchée en cas
              de refus de vendre de la part du Client recommandé, de refus
              d’accepter un mandat ou un rendez-vous de la part du Client
              recommandé.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 13. BONNE FOI</h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Chacune des parties s'engage à exécuter de bonne foi et sans
              réserve le Contrat et à ne rien dire ou faire qui puisse nuire aux
              intérêts, au crédit, à la notoriété ou à la réputation de chacune
              d’entre elles ou des courtiers partenaires de Ziaway Technologie
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 14. FORCE MAJEURE </h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              La responsabilité de Ziaway Technologie ne pourra pas être mise en
              œuvre si la non-exécution ou le retard dans l’exécution de l’une
              de ses obligations décrites dans les CGPS découle d’un cas de
              force majeure tel que défini par la jurisprudence des cours et
              tribunaux.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de survenance d’un des évènements susvisés, Ziaway
              Technologie s’efforcera d’informer le client dès que possible.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 15. PROPRIÉTÉ INTELLECTUELLE </h4>
            <h5 className="font-weight-bold">15.1 TECHNOLOGIE </h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les Parties conviennent que les applications, logiciels, bases de
              données et la technologie développée par Ziaway Technologie,
              notamment l’outil d’estimation, les Pages de prix, restent sa
              propriété exclusive.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier ne pourra, d’aucune manière, se prévaloir d’un droit
              de propriété quelconque, notamment de propriété intellectuelle,
              sur la technologie, les bases de données, applications et
              logiciels créés par Ziaway Technologie et mis à sa disposition
              pendant la durée du Contrat.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les données recueillies par Ziaway Technologie auprès de tiers,
              dans le cadre de l’exécution des présentes, ainsi que le contenu
              apporté par Ziaway Technologie restent sa propriété. Ziaway
              Technologie est titulaire de tous les droits de propriété
              intellectuelle sur l’organisation et la structure de la base de
              données de prix immobiliers, de clients et de prospects. Cette
              base de données constitue, par le choix et l’organisation des
              informations qu’elle contient, une création intellectuelle dont
              Ziaway Technologie est seule titulaire des droits d’auteur.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              L’accès à ces données dans les conditions stipulées au Contrat
              n’entraîne aucune cession de droit de propriété intellectuelle ou
              du droit sui generis au profit du courtier sur celles-ci, ni sur
              la base de données. Le courtier s’interdit formellement d'utiliser
              ces données dans le but de créer une nouvelle base de données ou
              un service concurrent, directement ou indirectement, de la base de
              données de Ziaway Technologie ou du Site. En outre, le courtier
              s’interdit de procéder à l’extraction par transfert permanent ou
              temporaire du contenu des bases de données accessibles sur les
              Sites
            </p>
            <br />
            <h5 className="font-weight-bold">15.2 MARQUE</h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les Parties s’autorisent mutuellement à utiliser la marque
              (verbale, semi-figurative ou figurative), le nom commercial, la
              dénomination sociale ou tout autre signe distinctif de l’autre
              Partie aux seules fins de l’exécution du Contrat et pour sa seule
              durée. Ce droit d'utilisation est incessible, à quelque titre que
              ce soit et est exclusivement consenti dans le cadre des présentes
              ; il ne pourra s'exercer que selon les modalités prévues par les
              présentes conditions.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les Parties conviennent expressément qu’ils n’apporteront aucune
              modification, altération ou adjonction dans la reproduction des
              signes distinctifs de l’autre Partie. Chaque Partie garantit que
              l’usage, par l’autre Partie, des différents signes distinctifs
              dont elle a la jouissance ne porte atteinte aux droits d’aucun
              tiers.
            </p>

            <br />
            <h5 className="font-weight-bold">
              15.3 LICENCE D’UTILISATION DES DONNÉES
            </h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier concède à Ziaway Technologie une licence
              d’exploitation des données relatives aux propriétés vendues. Cette
              licence confère à Ziaway Technologie des droits de reproduction,
              d’enregistrement, d’exploitation, d’extraction, d’adaptation et de
              représentation de ces données pour tout support actuel ou futur,
              et notamment sur tout support d’enregistrement, d’édition ou de
              diffusion matériel ou immatériel, en ligne ou hors ligne. Ce droit
              comprend les reproductions nécessaires à toute opération de
              stockage, transmission ou téléchargement. L’ensemble des droits
              ci-dessus concerne toute destination, notamment commerciale,
              auprès de tout public, directement ou indirectement. Il est
              précisé que le droit cédé est un droit non exclusif.
            </p>

            <br />
            <h5 className="font-weight-bold">
              15.4 LICENCE D’UTILISATION DES DONNÉES
            </h5>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le Contrat ayant un caractère intuitu personae, il ne pourra en
              aucun cas être cédé ou transféré de quelque manière que ce soit,
              en tout ou partie, pour quelque cause que ce soit, par l’une des
              Parties sans l’accord préalable et écrit de l’autre Partie.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Néanmoins, par dérogation à ce qui précède, le Contrat sera
              transféré de plein droit en cas de modification de la forme de la
              société, de changement d’associé(s), de société, de fusion ou
              d’absorption d’une Partie, ou, sauf opposition, de Ziaway
              Technologie, de cession de fonds de commerce. Le cas échéant, la
              Partie concernée par cette modification devra en informer l’autre.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 16. DONNÉES PERSONNELLES </h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ziaway Technologie respecte la réglementation en vigueur sur la
              protection des données à caractère personnel. Le client et
              l’utilisateur du site web est informés du fait qu’il dispose d’un
              certain nombre de droits sur le traitement de ses données
              personnelles (droit d’accès, d’opposition, de rectification,
              d’effacement, de portabilité, etc…).
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Ces droits peuvent être exercés, par courrier électronique à
              l’adresse : support@ziaway.ca
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Le courtier est invité à consulter la Politique de confidentialité
              de Ziaway Technologie afin d’obtenir de plus amples informations
              relatives au traitement de ses données personnelles.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 17. RÈGLEMENT DES LITIGES</h4>
            <h5 className="font-weight-bold"> ARTICLE 17.1 JURIDICTION </h5>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Les présentes conditions sont soumises au Droit québécois. Ainsi,
              tout différends, contestations, litiges relatifs aux présentes
              conditions seront soumis aux tribunaux du domicile du siège social
              de Ziaway Technologie à qui les Parties attribuent expressément
              juridiction, et ce même en cas d’appel en garantie et/ou de
              pluralités de défendeurs et ce nonobstant toutes clauses
              contraires.
            </p>

            <h5 className="font-weight-bold"> ARTICLE 17.2 MEDIATOR </h5>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de différend survenant entre la plateforme Ziaway
              Technologie et l’Utilisateur au sujet de l’interprétation, de
              l’exécution ou de la résiliation des présentes conditions, les
              Parties s’efforceront de le régler à l’amiable.
            </p>

            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Au besoin, l’Utilisateur pourra s’adresser au Médiateur désigné
              par Ziaway Technologie. L’entité de médiation retenue est le
              centre de justice de proximité Québec – Chaudière – Appalaches.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              En cas de différends, l’Utilisateur pourra déposer sa réclamation
              en ligne sur le site :
              https://www.justicedeproximite.qc.ca/centres/quebec/.
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Dans le cas où la Médiation se révélerait être un échec,
              l’Utilisateur conserve la possibilité de saisir l’autorité
              judiciaire, soit le tribunal géographiquement compétent dans le
              ressort du siège sociale Ziaway Technologie.
            </p>
          </section>

          <section>
            <h4 className="mb-3">ARTICLE 18. QUESTIONS </h4>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              Toute question ou réclamation concernant les présentes CGPS
              pourront être formulées :
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: 1.9 }}>
              {`• Par courrier électronique à l’adresse : support@ziaway.ca`}
            </p>
          </section>
        </div>
      </main>
    </>
  );
};
export default GeneralPolicies;
