import { useTranslation } from "react-i18next";

const LogoUpload = ({ setImage, url, text, type, defaultUrl }) => {
  const { t } = useTranslation();
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  let currentUrl = url ? url : defaultUrl;

  const handleDrop = (event) => {
    event.preventDefault();
    setImage(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <label
      htmlFor="your-logo"
      className="upload-image-4 p-5 text-center"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div>
        <div
          className={`mx-auto ${
            currentUrl && type === "photo" ? "placeholderurl" : "placeholder"
          } mb-1`}
        >
          {currentUrl ? (
            <img className="d-block h-100 w-100" src={currentUrl} alt="logo" />
          ) : (
            <img
              className="d-block h-100 w-100"
              src="/assets/packages/upload-image.svg"
              alt="..."
            />
          )}
        </div>
        <p className="mb-0 text-black-50 text-13">
          {text}{" "}
          <span className="font-weight-bold text-violet cursor-pointer">
            {t("Sign_up.80")}
          </span>
        </p>
      </div>
      <input
        type="file"
        className="d-none"
        name="your-logo"
        id="your-logo"
        onChange={(event) => {
          handleImageChange(event);
        }}
      />
    </label>
  );
};

export default LogoUpload;
