import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setLoadingPropertyInfo } from "../salesSlice";
import { toast } from "react-toastify";

const getProperyDetailInfo = (
  postalCode,
  address,
  AssignFields,
  setFieldValue
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingPropertyInfo(true));
      if (!postalCode || !address) return;

      const add = httpsCallable(functions, "GetProperyDetailInfoNew");
      add({ postalCode, address })
        .then((res) => {
          if (res.data[0]) {
            AssignFields(setFieldValue, res.data[0]);
          }
          dispatch(setLoadingPropertyInfo(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setLoadingPropertyInfo(false));
        });
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
};

export default getProperyDetailInfo;
