import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const LoadMore = ({ loadMore }) => {
  const {
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    loading: mainLoading,
  } = useSelector((state) => state.leads);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(
      actionLoading ||
        fetchMoreLoading ||
        firstFetchLoading ||
        loadingLeads ||
        mainLoading
    );
  }, [
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    mainLoading,
  ]);
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`load_more ${loading && "load_more_disabled"}`}
        onClick={loadMore}
        disabled={loading}
      >
        {t("Leads.112")}
      </button>
    </>
  );
};

export default LoadMore;
