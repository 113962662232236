import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import swal from "sweetalert2";
import { auth } from "../firebase";

export const verifyResetPasswordCode = async (code) => {
  try {
    const email = await verifyPasswordResetCode(auth, code);
    return email;
  } catch (error) {
    return false;
  }
};

export const changePassword = async (
  code,
  password,
  continueUrl,
  setSubmitting,
  t
) => {
  try {
    await confirmPasswordReset(auth, code, password);
    setSubmitting(false);
    swal
      .fire(t("resetPassword.13"), t("resetPassword.14"), "success")
      .then(() => {
        window.location.replace(continueUrl);
      });
    return true;
  } catch (error) {
    setSubmitting(false);
    swal.fire(t("resetPassword.11"), t("resetPassword.15"), "error");
  }
};
