import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setAll,
  setReports,
  setEvaluations,
  setNextProspectId,
  setLoadingLeads,
  setUserFilters,
} from "../leadsSlice";

export const getUserDataAction = () => {
  return async (dispatch, getState) => {
    const { userFilters, limit } = getState().leads;
    dispatch(setLoadingLeads(true));
    let filterSummary = userFilters?.filterSummary;
    let newUserFilters = userFilters;
    if (userFilters?.municipalite && !userFilters.filterSummary) {
      filterSummary = {
        Québec: userFilters?.municipalite
          .split(",")
          .map((item) => ({ label: item, value: item })),
      };
      newUserFilters = { ...userFilters, filterSummary };
      setUserFilters(newUserFilters);
    }
    const fetchNewLeads = httpsCallable(functions, "newleads");
    fetchNewLeads({ ...newUserFilters, limit })
      .then((res) => {
        const reports = res.data.data || [];
        const filtered = reports.map((v) => {
          if (v.ouiContacterParProfessionnel === "oui") v.type = "contact";
          else v.type = "prospect";
          return v;
        });
        dispatch(setAll(filtered));
        dispatch(setReports(filtered));
        dispatch(setEvaluations(filtered));
        dispatch(setNextProspectId(res.data.nextProspectId));
        dispatch(setLoadingLeads(false));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
