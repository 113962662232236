import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const WarningModal = ({ t, setShowWarning, showWarning, onHide }) => {
  return (
    <Modal
      show={showWarning}
      centered
      onHide={() => setShowWarning(false)}
      contentClassName="profile_sales_delete_modal"
    >
      <Modal.Body>
        <p style={{ marginTop: 0, marginBottom: 0, fontWeight: "600" }}>
          {t("profile.sales.86")}
        </p>
        <p style={{ marginTop: 0, marginBottom: 0 }}>{`(${t(
          "profile.sales.87"
        )})`}</p>
      </Modal.Body>

      <Modal.Footer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            setShowWarning(false);
          }}
        >
          {t("profile.sales.89")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onHide();
            setShowWarning(false);
          }}
        >
          {t("profile.sales.88")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;
