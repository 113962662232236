import { createSlice } from "@reduxjs/toolkit";

export const myEstimationsSlice = createSlice({
  name: "myEstimations",
  initialState: {
    estimations: [],
    count: 0,
    fixedLimit: 10,
    loading: false,
    lastFetchedEstimation: null,
  },
  reducers: {
    setEstimations: (state, action) => {
      state.estimations = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLastFetchedEstimation: (state, action) => {
      state.lastFetchedEstimation = action.payload;
    },
  },
});

export const {
  setEstimations,
  setCount,
  setLoading,
  setLastFetchedEstimation,
} = myEstimationsSlice.actions;

export default myEstimationsSlice.reducer;
