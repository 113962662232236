import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setTransactions } from "../subscriptionSlice";

export function listRecentTransactions() {
  return (dispatch) => {
    const getRecentTransactions = httpsCallable(
      functions,
      "listRecentTransactions"
    );
    getRecentTransactions({})
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          dispatch(setTransactions(res.data.data));
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
}
