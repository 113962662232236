const sortLeads = (myLeads = [], initialDate, finalDate) => {
  const startDate = new Date(initialDate);
  const endDate = new Date(finalDate);

  const resultProductData = myLeads.filter((a) => {
    const date = new Date(a.dateCreation);
    return date >= startDate && date <= endDate;
  });

  return resultProductData;
};

export default sortLeads;
