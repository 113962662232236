import PrivacyPage from "./PrivacyPolicyPage";
import Termofservice from "./TermOfService";
import GeneralPolicy from "./GeneralPolicies";
import NoGuard from "../../guards/NoGuard";

export const PublicFaqRoutes = [
  {
    path: "generalPolicy",
    element: <NoGuard component={<GeneralPolicy />} />,
  },
  {
    path: "dataPolicy",
    // Component: PrivacyPage,
    element: <NoGuard component={<PrivacyPage />} />,
  },
  {
    path: "termofservice",
    // Component: Termofservice,
    element: <NoGuard component={<Termofservice />} />,
  },
];
