import { useEffect, useRef } from "react";
import getPlaceDetail from "./utils/getPlaceDetail";

const Suggestions = ({
  placePredictions,
  placesService,
  setValue,
  placeToAddress,
  followUpFunc,
  setFieldValue,
  setShowPredictions,
  adressInputRef,
  customStyle,
  fieldName,
  onClose,
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        adressInputRef.current &&
        !adressInputRef.current.contains(event.target)
      ) {
        setShowPredictions(false);
        if (onClose) {
          onClose();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, adressInputRef, setShowPredictions, onClose]);

  return (
    <div
      className="autocomplete_predictions"
      ref={wrapperRef}
      style={customStyle ? customStyle : null}
    >
      {placePredictions.map((prediction) => (
        <div
          key={prediction.place_id}
          onClick={() => {
            getPlaceDetail(
              prediction,
              placesService,
              setValue,
              placeToAddress,
              followUpFunc,
              setFieldValue,
              setShowPredictions,
              fieldName
            );
          }}
          className="autocomplete_prediction"
        >
          {prediction.description}
        </div>
      ))}
    </div>
  );
};

export default Suggestions;
