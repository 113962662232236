import { Button } from "react-bootstrap";

const DesktopPreview = ({ profile, t }) => {
  return (
    <div className="col-lg-3 d-none d-lg-block flex-shrink-0">
      {/* {profile === null ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <div className="spinner-bubble spinner-bubble-primary m-5"></div>
        </div>
      ) : ( */}
      <div className="profile_preview_section">
        <div className="profile_desktopPreview_header">
          <div className="profile_desktopPreview_header">
            <img src="/assets/icons/apercu.png" alt="icon" className="mb-2" />
            <h3 className="profile_preview_header_title">
              {t("profile.preview.1")}
            </h3>
          </div>
        </div>
        <div className="scroll-box">
          <div className="row">
            <div className="col-12">
              <div
                className="p-3 position-sticky rounded-lg d-flex flex-column align-items-center justify-content-center"
                style={{ top: 0 }}
              >
                <img
                  className="profile_modal_img d-block"
                  src={profile?.image}
                  alt="profile_image"
                />

                <div className="text-center profile_modal_name">
                  {profile?.displayName}
                </div>

                <div className="text-center profile_modal_role">
                  {t("profile.preview.2")}
                </div>

                <Button className="profile_modal_btn my-4" variant="primary">
                  {t("profile.preview.3")}
                </Button>

                {profile?.logo && (
                  <div
                    className="mt-1 mx-auto"
                    style={{ height: "3rem", width: "3rem" }}
                  >
                    <img
                      className="p-0 w-100 h-100 d-block"
                      src={profile?.logo}
                      alt="logo"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default DesktopPreview;
