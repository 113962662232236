import { setAll, setEvaluations, setReports } from "../leadsSlice";

export const updateEvaluationAction = (evaluation) => {
  return async (dispatch, getState) => {
    const { All, reports, evaluations } = getState().leads;
    const newEvals = [...evaluations];
    const newAll = [...All];
    const newReports = [...reports];
    const allIdx = newAll.findIndex((item) => item.id === evaluation.id);
    if (allIdx >= 0) {
      newAll[allIdx] = evaluation;
      dispatch(setAll(newAll));
    }
    const idx = newEvals.findIndex((item) => item.id === evaluation.id);
    if (idx >= 0) {
      newEvals[idx] = evaluation;
      dispatch(setEvaluations(newEvals));
    }
    const reportIdx = newReports.findIndex((item) => item.id === evaluation.id);
    if (reportIdx >= 0) {
      newReports[reportIdx] = evaluation;
      dispatch(setReports(newReports));
    }
    // const myIdx = myEvaluations.findIndex((item) => item.id === evaluation.id);
    // if (myIdx >= 0) {
    //   myEvaluations[myIdx] = evaluation;
    //   dispatch(setMyEvaluations(myEvaluations));
    // }
  };
};
