import { useMemo, useCallback, useEffect, useState } from "react";
import { Badge, Row, Col } from "react-bootstrap";
import { FaBed, FaBath } from "react-icons/fa";
import Spinner from "../spinner";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import { toast } from "react-toastify";

const ProspectsMap = ({
  markers,
  selectedMarker,
  updateSelectedMarker,
  componentHeight,
  isFixed,
  isScreenWidthXl,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places", "geometry", "drawing"],
  });

  const [marginTop, setMarginTop] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Attach the event listener
    if (isFixed) window.addEventListener("scroll", handleScroll);

    // Detach the event listener on cleanup
    return () => {
      if (isFixed) window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  useEffect(() => {
    setMarginTop(scrollPosition);
  }, [scrollPosition, componentHeight]);

  useEffect(() => {
    if (loadError) {
      console.log("error loading map", loadError);
      toast.error(loadError.message);
    }
  }, [loadError]);

  const center = useMemo(() => {
    return { lat: 46.1908572, lng: -74.5489687 };
  }, []);

  const containerStyle = useMemo(() => {
    return {
      height: `500px`,
      width: `100%`,
      maxWidth: isFixed && isScreenWidthXl ? "425px" : "100%",
      position: isFixed && isScreenWidthXl ? "absolute" : "relative",
      top: isFixed && isScreenWidthXl ? `${marginTop}px` : "auto",
      left: isFixed && isScreenWidthXl ? "0" : "auto",
    };
  }, [isFixed, marginTop, isScreenWidthXl]);

  const urlSetter = useCallback((data) => {
    if (data?.ouiContacterParProfessionnel === "oui") {
      return { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" };
    } else {
      return {
        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
      };
    }
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={7}>
          {markers?.map((marker) => {
            return (
              <Marker
                key={marker?.id}
                onClick={() => {
                  updateSelectedMarker(marker);
                }}
                icon={urlSetter(marker)}
                position={{
                  lat: marker?.location?.latlng?.lat,
                  lng: marker?.location?.latlng?.lng,
                }}
              >
                {selectedMarker &&
                  selectedMarker?.id === marker.id &&
                  marker?.location?.latlng?.lat &&
                  marker?.location?.latlng?.lng && (
                    <InfoWindow
                      position={{
                        lat: marker?.location?.latlng?.lat,
                        lng: marker?.location?.latlng?.lng,
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        <h5 className="mt-1">
                          <Badge variant="primary" className="">
                            {marker?.genreProprietes}
                          </Badge>
                          <Badge variant="warning" className="ml-1">
                            {marker?.anneeConstruction}
                          </Badge>
                        </h5>
                        <p style={{ maxWidth: 300 }} className="card-text">
                          {marker?.location?.StreetName?.short_name},{" "}
                          {marker?.location?.city},{" "}
                          {marker?.location?.state?.short_name}
                          {marker?.location?.postcode},{" "}
                          {marker?.location?.Country?.long_name}
                        </p>
                        <Row className="mt-1">
                          <Col xs={1}>
                            <FaBed />
                          </Col>
                          <Col xs={2}>{marker?.chambres}</Col>
                          <Col xs={1}>
                            <FaBath />
                          </Col>
                          <Col xs={2}>{marker?.bains}</Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={5}>
                            <h6>Estimation : </h6>
                          </Col>
                          <Col xs={7}>
                            {marker?.ziaEstimation?.prediction.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }
                            )}
                          </Col>
                        </Row>
                      </div>
                    </InfoWindow>
                  )}
              </Marker>
            );
          })}
        </GoogleMap>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ProspectsMap;
