import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setSubError } from "../subscriptionSlice";
import { listCards } from "./listCards";

export const updateDefaultCard = (data, cb) => {
  return (dispatch) => {
    const setDefaultCard = httpsCallable(functions, "updateDefaultCard");
    setDefaultCard(data)
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          dispatch(listCards(res.data.selectedCardId));
          cb(res);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setSubError(err));
      });
  };
};
