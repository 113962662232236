import { auth } from "../../../../firebase";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import swal from "sweetalert2";

export function resetPassword(
  values,
  resetForm,
  sucessTitle,
  sucessMessage,
  errorMesssage,
  setShowDialog
) {
  return (dispatch, getState) => {
    const user = auth.currentUser;
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        values.currentPassword
      );
      reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, values.newPassword)
            .then(() => {
              // Update successful.
              swal.fire(sucessTitle, sucessMessage, "success");
              setShowDialog(false);
              resetForm({ values: "" });
            })
            .catch((error) => {
              swal.fire("Oups!", errorMesssage, "error");
              console.log(error);
            });
        })
        .catch((error) => {
          swal.fire("Oups!", errorMesssage, "error");
          console.log(error);
        });
    } catch (error) {
      console.log("error", error);
      swal.fire("Oups!", errorMesssage, "error");
    }
  };
}
