import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DropDownFilter = ({
  setMenu,
  menu,
  windowWidth,
  dateFilterType,
  onDaysSubtract,
  setDateFilterType,
  setInitialDate,
  initialDate,
  setFinalDate,
  finalDate,
  onDateChange,
  refreshFilter,
}) => {
  const { t } = useTranslation();

  const [isMonth, setIsMonth] = useState(dateFilterType === "31days");
  const [isWeek, setIsWeek] = useState(dateFilterType === "7days");
  const [isCustom, setIsCustom] = useState(dateFilterType === "custom");

  useEffect(() => {
    setIsMonth(dateFilterType === "31days");
    setIsWeek(dateFilterType === "7days");
    setIsCustom(dateFilterType === "custom");
  }, [dateFilterType]);

  return (
    <div className="d-flex align-items-center col-12 col-md-2 px-md-0">
      <li className="col-6">
        <div>
          <div>
            <button
              type="button"
              className="btn btn-outline-primary"
              style={{
                borderColor: "white",
              }}
              onClick={() => setMenu(!menu)}
            >
              <i className="i-Filter-2" style={{ color: "white" }}></i>
            </button>

            <div
              className={`dropdown-menu ${
                windowWidth >= 768
                  ? "dropdown-menu-right"
                  : "dropdown-menu-left"
              } ${menu && "show"}`}
              style={{ width: windowWidth >= 768 ? 400 : "90vw" }}
            >
              <div>
                <img
                  src="assets/icons/close.svg"
                  onClick={() => setMenu(false)}
                  alt="icon"
                  style={{
                    maxWidth: "20px",
                    cursor: "pointer",
                    position: "absolute",
                    right: "5px",
                    top: "2px",
                  }}
                />
              </div>
              <div className="px-3 border-bottom pb-2 mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="days"
                    id="31days"
                    checked={isMonth}
                    onChange={async (e) => {
                      if (!isMonth) {
                        await onDaysSubtract(31);
                        setMenu(false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="31days">
                    {t("Leads.5")}
                  </label>
                </div>
              </div>
              <div className="px-3 border-bottom pb-2 mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="days"
                    id="7days"
                    checked={isWeek}
                    onChange={async (e) => {
                      if (!isWeek) {
                        await onDaysSubtract(7);
                        setMenu(false);
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor="7days">
                    {t("Leads.6")}
                  </label>
                </div>
              </div>
              <div className="px-3 border-bottom pb-2 mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="days"
                    id="custom"
                    checked={isCustom}
                    onChange={(e) => {
                      if (!isCustom) setDateFilterType("custom");
                    }}
                  />
                  <div className="gy-2">
                    <b>{t("Leads.7")}</b>
                    <div className={"w-100 mb-2"}>
                      <input
                        className="form-control "
                        type="date"
                        onChange={(e) => {
                          setInitialDate(e.target.value);
                          setDateFilterType("custom");
                        }}
                        value={initialDate || ""}
                      />
                    </div>
                    <div className="w-100 mb-2 text-center">{t("Leads.8")}</div>
                    <div className={"w-100 mb-2"}>
                      <input
                        disabled={initialDate === null}
                        className="form-control "
                        type="date"
                        onChange={(e) => setFinalDate(e.target.value)}
                        value={finalDate || ""}
                      />
                    </div>
                    <div className={"px-2 d-flex justify-content-center"}>
                      <button
                        disabled={initialDate === null}
                        className="btn btn-primary mx-2"
                        type="date"
                        onClick={async () => {
                          await onDateChange();
                          setMenu(false);
                        }}
                        style={{ width: 150 }}
                      >
                        {t("Leads.9")}
                      </button>
                      <button
                        className="btn btn-primary mx-2"
                        type="date"
                        onClick={async (e) => {
                          await onDaysSubtract(31);
                          setMenu(false);
                        }}
                        style={{ width: 150 }}
                      >
                        {t("Leads.10")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="col-6">
        <div className="d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-outline-primary"
            style={{
              borderColor: "white",
            }}
            onClick={refreshFilter}
          >
            <i className="i-Reload" style={{ color: "white" }}></i>
          </button>
        </div>
      </li>
    </div>
  );
};

export default DropDownFilter;
