import { auth } from "../../../../firebase";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { setIsToDelete } from "../../authentication/authenticationSlice";

export const deleteAccount = (
  password,
  setLoading,
  setShowDeleteModal,
  showDeleteModal,
  setPassword,
  t
) => {
  return (dispatch) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    reauthenticateWithCredential(user, credential)
      .then(() => {
        setLoading(true);
        const removeUser = httpsCallable(functions, "deleteAccount");
        removeUser()
          .then((res) => {
            setShowDeleteModal(!showDeleteModal);
            if (res.data.success) {
              dispatch(setIsToDelete(true));
              setLoading(false);
              setPassword("");
              toast.success(
                `Your account will deleted by the end of the current billing cycle`
              );
            } else {
              setLoading(false);
              setPassword("");
              toast.error(res.data.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            setPassword("");
            setShowDeleteModal(!showDeleteModal);
            toast.error(e.message);
          });
      })
      .catch((err) => {
        setLoading(false);
        setPassword("");
        setShowDeleteModal(!showDeleteModal);
        if (err.code === "auth/wrong-password") {
          toast.error(t("profile.subscription.current_plan.16"));
        } else toast.error(err.message);
      });
  };
};
