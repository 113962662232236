import { useState, useEffect, useCallback } from "react";
import Plan from "./Plan";
import { useTranslation } from "react-i18next";

const AllPlans = (props) => {
  const { t } = useTranslation();
  const { products, currentPlan, setCurrStep, setSelectedPack } = props;
  const [active, setActive] = useState(0);
  const [monthly, setMonthly] = useState([]);
  const [yearly, setYearly] = useState([]);

  const sortPlans = useCallback((plans) => {
    const sortedPlans = plans.sort((a, b) => {
      if (a.unit_amount > b.unit_amount) return 1;
      if (a.unit_amount < b.unit_amount) return -1;
      return 0;
    });
    return sortedPlans;
  }, []);

  useEffect(() => {
    if (products?.length > 0) {
      let monthly = products.filter(
        (product) => product.recurring.interval === "month"
      );
      monthly = monthly.map((product) => {
        return {
          ...product,
          video: product.productMetadata.video,
          priceImage: product.productMetadata.image,
          discount: "15%",
        };
      });
      const sortedMonthly = sortPlans(monthly);
      setMonthly(sortedMonthly);
      let yearly = products.filter(
        (product) => product.recurring.interval === "year"
      );
      yearly = yearly.map((product) => {
        return {
          ...product,
          video: product.productMetadata.video,
          priceImage: product.productMetadata.image,
          discount: "15%",
        };
      });
      const sortedYearly = sortPlans(yearly);
      setYearly(sortedYearly);
    }
  }, [products, sortPlans]);

  return (
    <>
      <div className="card-container">
        <div className="row-spaceBtwn all-plans-tab-view">
          <p className="component-title">
            {t("profile.subscription.all_plans.1")}
          </p>
          <div className="btn-group">
            <button
              className={
                active === 0 ? "btn-group-active" : "btn-group-inactive"
              }
              onClick={() => setActive(0)}
            >
              {t("profile.subscription.all_plans.2")}
              <span>-15%</span>
            </button>
            <button
              className={
                active === 1 ? "btn-group-active" : "btn-group-inactive"
              }
              onClick={() => setActive(1)}
            >
              {t("profile.subscription.all_plans.3")}
            </button>
          </div>
          <div className="width-140" />
        </div>
        <div className="all-plans-container">
          {active === 0
            ? yearly.map((pack, key) => (
                <Plan
                  {...props}
                  key={pack.id}
                  pack={pack}
                  setCurrStep={setCurrStep}
                  setSelectedPack={setSelectedPack}
                  currentPlan={currentPlan}
                />
              ))
            : monthly.map((pack, key) => (
                <Plan
                  {...props}
                  key={pack.id}
                  pack={pack}
                  active={active}
                  setCurrStep={setCurrStep}
                  setSelectedPack={setSelectedPack}
                  currentPlan={currentPlan}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default AllPlans;
