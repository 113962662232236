const AdressField = ({
  getPlacePredictions,
  setShowPredictions,
  value,
  setValue,
  adressInputRef,
  cn,
  placeholder,
  setFieldTouched,
  setAddress,
  resetAddress,
  setFieldValue,
  customStyle,
  disabled,
}) => {
  const getPredictions = async (input) => {
    try {
      getPlacePredictions({
        input: input,
        componentRestrictions: { country: "ca" },
        types: ["address"],
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div
      style={{
        width: "100%",
      }}
      ref={adressInputRef}
    >
      <input
        className={cn ? cn : "form-control rounded-2 py-4 font-poppins"}
        style={customStyle}
        value={value}
        placeholder={
          placeholder
            ? placeholder
            : "Saisissez l’adresse de la propriété à évaluer..."
        }
        disabled={disabled}
        onChange={async (evt) => {
          if (setAddress) setAddress(null);
          if (resetAddress) resetAddress(null, setFieldValue);
          setValue(evt.target.value);
          getPredictions(evt.target.value);
        }}
        onFocus={() => setShowPredictions(true)}
        onBlur={async () => {
          if (setFieldTouched)
            await setFieldTouched("soldPropertyAddress", true);
        }}
      />
    </div>
  );
};

export default AdressField;
