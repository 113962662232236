import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import PaymentStripe from "../PaymentStripe";

const StepTwo = ({ setCurrStep, currStep, selectedPack, currentPlan }) => {
  const { t } = useTranslation();
  const { previousStep } = useWizard();
  return (
    <div className="payment-stripe-container">
      <button
        onClick={() => {
          previousStep();
          setCurrStep(currStep - 1);
        }}
        className="go-back"
      >
        <img src="./assets/icons/back.png" alt="icon" />
        {t("profile.subscription.retour")}
      </button>
      <PaymentStripe
        selectedPack={selectedPack}
        currentPlan={currentPlan}
        previousStep={previousStep}
        goBack={() => {
          previousStep();
          setCurrStep(currStep - 1);
        }}
      />
    </div>
  );
};

export default StepTwo;
