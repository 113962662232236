import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found-wrap text-center">
      <h1 className="text-60">404</h1>
      <p className="text-36 subheading mb-3">{t("error.1")}</p>
      <p className="mb-5  text-muted text-18">{t("error.2")}</p>
      <Link to="/dashboard" className="btn btn-lg btn-primary btn-rounded">
        {t("error.3")}
      </Link>
    </div>
  );
};

export default Error;
