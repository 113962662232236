import { useTranslation } from "react-i18next";
import { useState } from "react";

const Summary = ({ munciValue, filterSummary }) => {
  const { t } = useTranslation();

  const formatFilterSummary = (filterSummary) => {
    const lines = [];
    for (const [key, value] of Object.entries(filterSummary)) {
      lines.push(
        <div key={key} className="newleads_filter_summary_line">
          <span className="font-weight-bold mr-2">{key}:</span>
          {value.map((v) => (
            <span key={v.label} className="newleads_filter_summary_municip">
              {v.label}
            </span>
          ))}
        </div>
      );
    }
    const jsx = <div className="newleads_filter_summary_lines">{lines}</div>;
    return jsx;
  };

  const [showSummary, setShowSummary] = useState(false);

  return (
    <div className="px-2 newleads_filter_summary">
      <div className="newleads_filter_summary_head">
        <h4 className="row">{t("Leads.102")}:</h4>
        {showSummary ? (
          <img
            src="assets/icons/upArrow.svg"
            alt="hide"
            className="newleads_filter_summary_img"
            onClick={() => setShowSummary(false)}
          />
        ) : (
          <img
            src="assets/icons/downArrow.svg"
            alt="show"
            className="newleads_filter_summary_img"
            onClick={() => setShowSummary(true)}
          />
        )}
      </div>
      {showSummary && (
        <>
          {filterSummary ? (
            <div className="row">{formatFilterSummary(filterSummary)}</div>
          ) : (
            <div className="row">
              {munciValue?.map((v) => (
                <div key={v.label} className="newleads_filter_summary_municip">
                  {v.label}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Summary;
