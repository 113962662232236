import { useTranslation } from "react-i18next";

const Project = ({ prospect }) => {
  const { t } = useTranslation();

  return (
    <div className="newLeads_project">
      <div className="newLeads_project_first">
        <div>
          <span>{t("Leads.46")}</span>
          <span>{prospect.estProprietaireReponse}</span>
        </div>
        <div>
          <span>{t("Leads.47")}</span>
          <span>{prospect.ceBienEstReponse}</span>
        </div>
        <div>
          <span>{t("Leads.48")}</span>
          <span>{prospect.envisageVendreBienReponse}</span>
        </div>
      </div>
    </div>
  );
};

export default Project;
