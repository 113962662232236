import { useEffect } from "react";
import { checkAuthStatus } from "../redux/slices/authentication/actions/checkAuthStatus";
import { useDispatch } from "react-redux";
import Footer from "../components/layout/components/footer";

const NoGuard = ({ component }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, []);

  return (
    <div>
      {component}
      <Footer />
    </div>
  );
};

export default NoGuard;
