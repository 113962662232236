import { setCityValue, setMuncipalities } from "../leadsSlice";
import { municipalitiesFormatter } from "pages/prospect/utils/municipalitiesFormatter";

export const updateCityAction = (e, filters) => {
  return async (dispatch, getState) => {
    dispatch(setCityValue(e));
    for (const [key, val] of Object.entries(filters)) {
      if (key === e.value) {
        let municipalities = [...val.municipalities];
        let sortedMuncipalities = municipalities.sort((a, b) =>
          a > b ? 1 : -1
        );
        let formattedMunicipalities =
          municipalitiesFormatter(sortedMuncipalities);

        dispatch(setMuncipalities(formattedMunicipalities));
      }
    }
  };
};
