import { useCallback, useMemo } from "react";
import { useWizard } from "react-use-wizard";

const Plan = ({ pack, setCurrStep, setSelectedPack, active, currentPlan }) => {
  const { nextStep } = useWizard();

  const handleCLick = useCallback(() => {
    if (currentPlan === pack.id) return;
    setSelectedPack(pack);
    nextStep();
    setCurrStep(2);
  }, [currentPlan, pack, nextStep, setCurrStep, setSelectedPack]);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
        minimumFractionDigits: 0,
      }),
    []
  );

  const formatPrice = useCallback(
    (price) => {
      if (price % 100 === 0) {
        return formatter.format(price / 100);
      }
    },
    [formatter]
  );

  return (
    <div className="plan-container">
      <img src={pack.priceImage} alt="icon" />
      <p className="plan-name">{`Plan ${pack.name}`}</p>
      <p className="plan-amount">
        {formatPrice(pack.unit_amount_decimal)}
        {active !== 1 && <span>-{pack.discount}</span>}
      </p>
      <button
        className={
          currentPlan === pack.id ? "your-current-plan" : "choose-plan-btn"
        }
        onClick={handleCLick}
      >
        {currentPlan === pack.id ? "Plan actuel" : "Choisir ce plan"}
      </button>
      <p className="advantages mb-3">Advantages</p>
      <iframe
        width="100%"
        height="auto"
        src={pack.video}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Plan;
