import AuthGuard from "guards/AuthGuard";
import ProspectsPage from "./ProspectsPage";
import { authRoles } from "../../guards/authRoles";

const prospectsPageRoutes = [
  {
    path: "/leads",
    element: <AuthGuard noFooter={true} component={<ProspectsPage />} />,
    auth: authRoles.Performance,
  },
];

export default prospectsPageRoutes;
