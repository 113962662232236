const getPlaceDetail = async (
  prediction,
  placesService,
  setValue,
  placeToAddress,
  followUpFunc,
  setFieldValue,
  setShowPredictions,
  fieldName = "address"
) => {
  try {
    await placesService.getDetails(
      { placeId: prediction.place_id },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setValue(prediction.description);
          const formattedPlace = placeToAddress(place);
          setFieldValue(fieldName, formattedPlace);
          if (followUpFunc) followUpFunc(formattedPlace);
          setShowPredictions(false);
        }
      }
    );
  } catch (e) {
    console.log("error", e);
  }
};

export default getPlaceDetail;
