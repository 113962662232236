import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import {
  setUserInternetData,
  setLoadingUserData,
} from "../authenticationSlice";
import swal from "sweetalert2";
import { PushToZoho } from "../../../../utils/pushToZoho";

export function updateUserInternetData(
  values,
  successTitle,
  successMessage,
  errorTitle,
  errorMessage
) {
  return (dispatch, getState) => {
    try {
      const userData = getState().authentication.userData;
      const userId = userData.id;
      const json_data = {
        info: {
          data: [
            {
              Agency: userData?.regulatory?.agency || "",

              Authorized_field_of_practice:
                userData?.regulatory?.practice?.value || "",

              Business_adress:
                userData?.regulatory?.BusinessAddress.value || "",

              E_mail: values?.officialEmail || "",

              Email: userData?.email || "",

              Licence_status: userData?.regulatory?.status?.value || "",

              First_and_last_name: values?.officialName || "",

              Phone: values?.officialPhoneNumber || "",

              Licence_Number: userData?.licenseId || "",

              Last_Name: userData?.displayName || "",

              Mail_message: userData?.messages?.emailText || "",

              SMS_Message: userData?.messages?.smsText || "",

              Broker_Photo: userData?.image || "",

              Agency_Logo: userData?.logo || "",

              Cellulaire: userData?.phoneNumber || "",

              Facebook: values?.facebook || "",

              Linkedin: values?.linkedIn || "",

              Twitter_Address: values?.twitter || "",

              Website: values?.website || "",

              Instagram: values?.insta || "",
            },
          ],

          duplicate_check_fields: ["Licence_Number"],
        },

        vmodule: "Contacts/upsert",
      };
      dispatch(setLoadingUserData(true));
      const docRef = doc(db, "users", userId);
      updateDoc(docRef, { officialInformation: values });
      if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
        PushToZoho(json_data);
      }
      dispatch(setUserInternetData(values));
      dispatch(setLoadingUserData(false));
      swal.fire(successTitle, successMessage, "success");
    } catch (error) {
      console.log(error);
      swal.fire(errorTitle, errorMessage, "error");
      dispatch(setLoadingUserData(false));
    }
  };
}
