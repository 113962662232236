import { useEffect, useState, useRef, useCallback } from "react";
import GroupCheckButtons from "../components/GroupCheckButtons";
import LogoUpload from "../components/LogoUpload";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import AdressField from "../../forms/AddressField";
import Suggestions from "../../forms/AddressField/Suggestions";
import getPlaceDetail from "components/forms/AddressField/utils/getPlaceDetail";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import swal from "sweetalert2";
import Spinner from "../../../components/spinner";

const RegulatoryInformationStep = ({
  handleStepNext,
  setRegulatory,
  setLogo,
  logo,
  handleStepPrev,
  regulatory,
}) => {
  const { t } = useTranslation();
  const adressInputRef = useRef(null);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 1000,
  });

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [place, setPlace] = useState("");

  const { userData } = useSelector((state) => state.authentication);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [agencyStatus, setAgency] = useState(t("Sign_up.81"));

  const [imageUploading, setImageUploading] = useState(false);

  const agencys = [t("Sign_up.81"), t("Sign_up.82")];
  const authorizedFields = [t("Sign_up.83"), t("Sign_up.84"), t("Sign_up.85")];
  const status = [t("Sign_up.86"), t("Sign_up.87"), t("Sign_up.88")];

  const onStepPrev = (values) => {
    setRegulatory(values);
    if (url) setLogo(url);
    handleStepPrev();
  };

  const resetAddress = useCallback((adr = null, setFieldValue) => {
    setFieldValue("BusinessAddress", adr);
    setPlace(adr);
  }, []);

  const placeToAddress = useCallback((place) => {
    var address = {};
    if (place.address_components) {
      address.value = place.formatted_address;
      address.latlng = place.geometry && {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      place.address_components.forEach(function (c) {
        switch (c.types[0]) {
          case "street_number":
            address.StreetNumber = c;
            break;
          case "route":
            address.StreetName = c;
            break;
          case "neighborhood":
          case "locality": // North Hollywood or Los Angeles?
            address.city = c.long_name;
            break;
          case "administrative_area_level_1": //  Note some countries don't have states
            address.state = c;
            break;
          case "postal_code":
            address.postcode = c.short_name;
            break;
          case "country":
            address.Country = c;
            break;

          default:
            break;
        }
      });
    }
    return address;
  }, []);

  const addimage = useCallback(async () => {
    if (image) {
      if (
        image.type === "image/png" ||
        image.type === "image/jpeg" ||
        image.type === "image/jpg"
      ) {
        if (image.size > 5000000) {
          setImageUploading(false);
          swal.fire(t("profile.25"), t("profile.28"), "error");
        } else {
          setImageUploading(true);
          const fileRef = ref(storage, `images/${image.name}`);
          uploadBytes(fileRef, image).then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setUrl(url);
                setImageUploading(false);
              })
              .catch((err) => {
                console.log(err);
                setImageUploading(false);
              });
          });
        }
      } else {
        setImageUploading(false);
        swal.fire(t("profile.25"), t("profile.27"), "error");
      }
    }
  }, [image, t]);

  const onSubmit = useCallback(
    (values) => {
      setRegulatory(values);
      if (url) setLogo(url);
      handleStepNext();
    },
    [handleStepNext, setLogo, setRegulatory, url]
  );

  const extractFieldValue = useCallback((defaultValueOne, defaultValueTwo) => {
    if (defaultValueOne) {
      return defaultValueOne ? defaultValueOne : "";
    }
    if (defaultValueTwo) {
      return defaultValueTwo ? defaultValueTwo : "";
    }
    return "";
  }, []);

  useEffect(() => {
    if (image) {
      addimage();
    }
  }, [image, addimage]);

  return (
    <div>
      <Formik
        initialValues={{
          license: extractFieldValue(userData?.licenseId, regulatory?.license),
          BusinessAddress: extractFieldValue(
            userData?.regulatory?.BusinessAddress,
            regulatory?.BusinessAddress
          ),
          status: {
            value: regulatory?.status?.value
              ? regulatory?.status?.value
              : t("Sign_up.86"),
            label: regulatory?.status?.label
              ? regulatory?.status?.label
              : t("Sign_up.86"),
          },
          practice: {
            value: regulatory?.practice?.value
              ? regulatory?.practice?.value
              : t("Sign_up.83"),
            label: regulatory?.practice?.label
              ? regulatory?.practice?.label
              : t("Sign_up.83"),
          },
          agency: extractFieldValue(
            userData?.regulatory?.agency,
            regulatory?.agency
          ),
        }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          if (!values.license) {
            errors.license = t("Sign_up.126");
          }
          if (!values.BusinessAddress) {
            errors.BusinessAddress = t("Sign_up.125");
          }
          if (!values.status) {
            errors.status = t("Sign_up.126");
          }
          if (!values.practice) {
            errors.practice = t("Sign_up.126");
          }

          if (
            (agencyStatus === "Yes" || agencyStatus === "Oui") &&
            !values.agency
          ) {
            errors.agency = t("Sign_up.126");
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {imageUploading && <Spinner />}
            <h4 className="mb-4 text-violet text-18">{t("Sign_up.62")}</h4>

            <div className="mb-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.63")}
              </label>
              <LogoUpload
                defaultUrl={"/assets/packages/placeholder.png"}
                image={image}
                setImage={setImage}
                url={url}
                text={t("Sign_up.79")}
                type="photo"
              />
            </div>
            <div className="mb-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.64")}
              </label>
              <GroupCheckButtons
                options={agencys}
                onClick={(value) => {
                  setAgency(value.value);
                }}
              />
            </div>
            {agencyStatus === "Non" || agencyStatus === "No" ? null : (
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  {t("Sign_up.67")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="agency"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agency}
                />
                {errors.agency && touched.agency && (
                  <div className="text-danger mt-1 ml-2">{errors.agency}</div>
                )}
              </div>
            )}
            <div>
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.68")}
              </label>
              <div className="position-relative">
                {showSuggestions && placePredictions?.length > 0 && (
                  <Suggestions
                    placePredictions={placePredictions}
                    getPlaceDetail={getPlaceDetail}
                    placesService={placesService}
                    setValue={setPlace}
                    placeToAddress={placeToAddress}
                    customStyle={{
                      top: "35px",
                      left: "0",
                    }}
                    fieldName="BusinessAddress"
                    setFieldValue={setFieldValue}
                    setShowPredictions={setShowSuggestions}
                    adressInputRef={adressInputRef}
                  />
                )}
                {isPlacePredictionsLoading && <Spinner />}
                <AdressField
                  cn="form-control"
                  placeholder=" "
                  getPlacePredictions={getPlacePredictions}
                  setShowPredictions={setShowSuggestions}
                  value={place}
                  setValue={setPlace}
                  adressInputRef={adressInputRef}
                  setFieldValue={setFieldValue}
                  resetAddress={resetAddress}
                />
                {/* )} */}
              </div>
              {errors.BusinessAddress && touched.BusinessAddress && (
                <div className="text-danger mt-1 ml-2">
                  {errors.BusinessAddress}
                </div>
              )}
            </div>

            <div className="mb-4 mt-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.65")}
              </label>
              <GroupCheckButtons
                defaultOption={authorizedFields.findIndex(
                  (item) => item === values.status.value
                )}
                initials={values.practice}
                options={authorizedFields}
                onClick={(value) => setFieldValue("practice", value)}
              />
            </div>

            <div className="mb-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.66")}
              </label>
              <GroupCheckButtons
                initials={values.status}
                options={status}
                onClick={(value) => setFieldValue("status", value)}
              />
            </div>

            <div className=" pb-4 text-right mt-5 ">
              <button
                type="button"
                className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
                onClick={() => onStepPrev(values)}
              >
                {t("Sign_up.59")}
              </button>
              <button
                type="submit"
                className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
              >
                {t("Sign_up.60")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegulatoryInformationStep;
