export const statusFormatter = (data = []) => {
  let filters = [];
  for (let i = 0; i < data.length; i++) {
    if (filters.includes(data[i]) === false) {
      filters.push(data[i]);
    }
  }
  const idx = filters.findIndex((item) => item === "");
  if (idx >= 0) {
    filters[idx] = "RecherchInformations";
  }
  return filters;
};
