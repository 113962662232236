import { Button, Modal } from "react-bootstrap";

const Modall = ({ setIsModal, onClick, t, setSelectedSale }) => {
  return (
    <Modal
      show={true}
      centered
      onHide={() => setIsModal(false)}
      contentClassName="profile_sales_delete_modal"
    >
      <Modal.Body>
        <p>{t("profile.sales.45")}</p>
      </Modal.Body>

      <Modal.Footer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedSale(null);
            setIsModal(false);
          }}
        >
          {t("profile.sales.46")}
        </Button>
        <Button variant="danger" className="text-white" onClick={onClick}>
          {t("profile.sales.47")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Modall;
