import { setSortedBy, setSortingType } from "../leadsSlice";

export const sortEvaluationsByValeurVMZAction = (
  evaluations,
  sortingType,
  setState
) => {
  return async (dispatch, getState) => {
    dispatch(setSortedBy("valeurVMZ"));
    dispatch(setSortingType(sortingType));
    const sortedEvaluations = [...evaluations];
    sortedEvaluations.sort((a, b) => {
      if (sortingType === "asc") {
        return a.ziaEstimation?.prediction - b.ziaEstimation?.prediction;
      } else {
        return b.ziaEstimation?.prediction - a.ziaEstimation?.prediction;
      }
    });

    setState(sortedEvaluations);
  };
};
