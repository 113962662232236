import { useTranslation } from "react-i18next";
import updateContactProgress from "../../utils/updateContactProgess";
import { useSelector, useDispatch } from "react-redux";

const NewLeadStatus = ({
  progress,
  id,
  setLoading,
  updateEvaluation,
  setLiveProspect,
  leadEmail,
  brokerLicenceId,
  profile,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const list = [
    { name: "New Prospect" },
    { name: "Contacted" },
    { name: "Scheduled" },
    { name: "In Progress" },
    { name: "Offer Submitted" },
    { name: "Signed Mandate" },
    { name: "To be restarted" },
    { name: "Lost/Completion" },
  ];

  const onProgressChange = async (data) => {
    const progress = await updateContactProgress(
      id,
      data,
      setLoading,
      {
        email: leadEmail,
        licenseId: brokerLicenceId,
      },
      user,
      dispatch,
      profile
    );
    if (!progress?.error) {
      await updateEvaluation({
        ...progress?.evaluation,
        type: "contact",
      });
      await setLiveProspect({
        ...progress?.evaluation,
        type: "contact",
      });
    }
  };

  return (
    <div className="animated fadeInUp ">
      <ul className="nav flex-column text-12 gy-2">
        <li className="border-bottom border-light pb-1">
          <h6 className="mb-0 text-primary font-weight-bold">Select Status</h6>
        </li>
        {list.map((item, i) => (
          <li key={i} className="border-bottom border-light pb-1">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id={i}
                defaultChecked={item.name === progress}
                onClick={() => onProgressChange(item.name)}
              />
              <label className="form-check-label" htmlFor={i}>
                {t("Dashboard." + item.name.replaceAll(" ", "_"))}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewLeadStatus;
