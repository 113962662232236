import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    current: {},
    step: 1,
    subLoading: false,
    subError: null,
    plans: [],
    plan: {},
    cards: [],
    selectedCardId: null,
    taxes: [],
    licenceIdFound: true,
    subMessage: null,
    transactions: [],
    products: [],
    currentPlan: null,
  },
  reducers: {
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },

    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },

    setCards: (state, action) => {
      state.cards = action.payload;
    },

    setSelectedCardId: (state, action) => {
      state.selectedCardId = action.payload;
    },

    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },

    setProducts: (state, action) => {
      state.products = action.payload;
    },

    setSubLoading: (state, action) => {
      state.subLoading = action.payload;
    },

    setSubError: (state, action) => {
      state.subError = action.payload;
    },

    setCurrent: (state, action) => {
      state.current = action.payload;
    },

    setStep: (state, action) => {
      state.step = action.payload;
    },

    setPlans: (state, action) => {
      state.plans = action.payload;
    },

    setPlan: (state, action) => {
      state.plan = action.payload;
    },

    setTaxes: (state, action) => {
      state.taxes = action.payload;
    },

    setLicenceIdFound: (state, action) => {
      state.licenceIdFound = action.payload;
    },

    setSubMessage: (state, action) => {
      state.subMessage = action.payload;
    },
  },
});

export const {
  setSubscription,
  setSubLoading,
  setSubError,
  setCurrent,
  setStep,
  setPlans,
  setPlan,
  setTaxes,
  setLicenceIdFound,
  setSubMessage,
  setSelectedCardId,
  setCards,
  setTransactions,
  setProducts,
  setCurrentPlan,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
