import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setSubError, setCurrentPlan } from "../subscriptionSlice";
import {
  setAccountData,
  setUserData,
} from "../../authentication/authenticationSlice";
import { toast } from "react-toastify";

export function updateSubscription(data, cb) {
  return (dispatch, getState) => {
    try {
      const { userData } = getState().authentication;
      const setSubscription = httpsCallable(functions, "updateSubscription");
      setSubscription(data)
        .then((res) => {
          dispatch(setCurrentPlan(data.selectedPlanId));
          if (res?.data?.account) {
            dispatch(setAccountData(res.data.account));
            dispatch(
              setUserData({ ...userData, role: res.data.account.planName })
            );
          }
          cb(res);
        })
        .catch((err) => {
          dispatch(setSubError(err));
        });
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };
}
