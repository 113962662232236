import axios from "axios";

export async function createStripeToken({ number, cvc, exp_month, exp_year }) {
  const params = new URLSearchParams();
  params.append("card[number]", number);
  params.append("card[cvc]", cvc);
  params.append("card[exp_month]", exp_month);
  params.append("card[exp_year]", exp_year);
  params.append("key", process.env.REACT_APP_API_STRIPE_PUBLIC_KEY);

  const { data } = await axios.post(
    "https://api.stripe.com/v1/tokens",
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  return data;
}
