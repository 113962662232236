import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import addNote from "../../utils/addNote";

const NotesModal = ({
  show,
  setShow,
  evalId,
  setLoading,
  setNotes,
  notesList,
  setNotesId,
  notesId,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(null);
  const handleClose = () => {
    setShow(false);
  };

  const addNoteFunc = async () => {
    const note = {
      text: text,
      date: new Date(),
    };

    const response = await addNote(evalId, note, setLoading);
    if (!response?.error) {
      if (notesList?.length > 0) {
        const newNotes = [...notesList, { ...note, id: response?.noteId }];

        await setNotes(newNotes);
      } else {
        if (!notesId) await setNotesId(response?.notesId);
        const newNotes = [{ ...note, id: response?.noteId }];
        await setNotes(newNotes);
      }
      setShow(false);
    } else {
      setShow(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      style={{ maxWidth: "100vw" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Leads.67")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="w-100 "
          style={{ height: "100px" }}
          onChange={(e) => setText(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={addNoteFunc}>
          {t("Leads.117")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotesModal;
