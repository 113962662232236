import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { PushToZoho } from "../../../utils/pushToZoho";
import { getUserData } from "../../../redux/slices/authentication/actions/getUserData";

const updateContactProgress = (
  contactId,
  progress,
  setLoading,
  leadData,
  uid,
  dispatch,
  profile
) => {
  setLoading(true);

  const updateProgress = httpsCallable(functions, "updateContactProgress");

  const response = updateProgress({ contactId, progress })
    .then((res) => {
      dispatch(getUserData(uid));
      const dateAccepted = new Date();
      const [month, day, year] = [
        ("0" + (dateAccepted.getMonth() + 1)).slice(-2),
        ("0" + dateAccepted.getDate()).slice(-2),
        dateAccepted.getFullYear(),
      ];
      const [hour, minutes, seconds] = [
        ("0" + dateAccepted.getHours()).slice(-2),
        ("0" + dateAccepted.getMinutes()).slice(-2),
        ("0" + dateAccepted.getSeconds()).slice(-2),
      ];

      const json_data = {
        info: {
          data: [
            {
              Broker_Full_Name: profile?.username,
              Broker_Phone: profile?.phoneNumber,
              Email: profile?.email,
              Name: leadData?.email,
              Broker_License_Number: leadData?.licenseId,
              Date_Event: `${year}-${month}-${day}T${hour}:${minutes}:${seconds}-04:00`,
              Statut_Event: progress,
            },
          ],
        },
        vmodule: "Brokers_Leads",
      };

      if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
        PushToZoho(json_data)
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });

  return response;
};

export default updateContactProgress;
