import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setUserData } from "../../../redux/slices/authentication/authenticationSlice";

const removeBookmark = (
  e,
  bookmark,
  setLoading,
  dispatch,
  profile,
  atertRemoveLead
) => {
  e.stopPropagation();
  setLoading(true);
  const remove = httpsCallable(functions, "removeBookmark");
  remove({ bookmark })
    .then((res) => {
      let bookmarks = profile?.bookmarks;
      let newBookmarks = bookmarks.filter((item) => item.id !== bookmark.id);
      dispatch(setUserData({ ...profile, bookmarks: newBookmarks }));
      atertRemoveLead();
      setLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export default removeBookmark;
