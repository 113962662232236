import { setLoading } from "../currentReportSlice";
import { db } from "../../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import swal from "sweetalert2";

const updateReportReview = (reportId, review, t) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const currentReportRef = doc(db, "RapportsEvaluations", reportId);
      await updateDoc(currentReportRef, { avisClient: review });
      swal.fire(t("swal.16"), t("swal.17"), "Success");
      dispatch(setLoading(false));
    } catch (error) {
      console.log("updateReportReviews_error", error);
      dispatch(setLoading(false));
    }
  };
};

export default updateReportReview;
