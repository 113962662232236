import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { assignValueToLabel, reformulateAmount } from "../utils/UtilsFunction";

const SalePreview = ({
  t,
  selectedSale,
  setIsDeleting,
  setShowModal,
  setIsEditing,
}) => {
  const { data } = selectedSale;

  const salesData = useSelector((state) => state.sales);

  const {
    conditions,
    contractStatus,
    categories,
    genresOne,
    genresTwo,
    types,
  } = salesData;

  const title = useMemo(
    () =>
      t(
        `profile.sales.${assignValueToLabel(data?.propertyGenre, [
          ...genresOne,
          ...genresTwo,
        ])}`
      ),
    [data?.propertyGenre, genresOne, genresTwo, t]
  );

  return (
    <div className="profile_sales_preview">
      <Modal.Header closeButton style={{ border: "none" }}>
        <div className="profile_sales_preview_title">{`${title} ${t(
          "profile.sales.62"
        )} ${data?.soldPropertyAddress?.StreetName?.short_name}`}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="profile_sales_preview_section profile_sales_preview_field">
          <span>{t("profile.sales.63")}:</span>
          <span>{data?.soldPropertyAddress?.value}</span>
        </div>
        <div className="profile_sales_preview_section profile_sales_preview_field">
          <span>{t("profile.sales.64")}:</span>
          <span>{data?.noMLS}</span>
        </div>
        <div className="profile_sales_preview_section">
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.65")}:</span>
            <span>
              {t(
                `profile.sales.${assignValueToLabel(
                  data?.contractStatus,
                  contractStatus
                )}`
              )}
            </span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.66")}:</span>
            <span>{data?.dateExpirationContract}</span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.67")}:</span>
            <span>
              {t(
                `profile.sales.${assignValueToLabel(
                  data?.propertyCategory,
                  categories
                )}`
              )}
            </span>
          </div>
        </div>
        <div className="profile_sales_preview_section">
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.68")}:</span>
            <span>${reformulateAmount(data?.amountOffered)}</span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.69")}:</span>
            <span>${reformulateAmount(data?.amountSold)}</span>
          </div>
        </div>
        <div className="profile_sales_preview_section">
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.70")}:</span>
            <span>{data?.yearOfConstruction}</span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.71")}:</span>
            <span>
              {t(
                `profile.sales.${assignValueToLabel(data?.propertyType, types)}`
              )}
            </span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.72")}:</span>
            <span>{`${data?.buildingArea} ft²`}</span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.73")}:</span>
            <span>
              {t(
                `profile.sales.${assignValueToLabel(
                  data?.propertyCondition,
                  conditions
                )}`
              )}
            </span>
          </div>
        </div>
        <div className="profile_sales_preview_section">
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.74")}:</span>
            <span>{data?.numberOfRooms}</span>
          </div>

          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.75")}:</span>
            <span>{data?.numberOfBedRooms}</span>
          </div>

          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.76")}:</span>
            <span>{data?.numberOfBathrooms}</span>
          </div>

          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.77")}:</span>
            <span>{data?.numberOfParkingSpaces}</span>
          </div>

          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.83")}:</span>
            <span>{data?.numberOfGarages}</span>
          </div>
        </div>

        <div className="profile_sales_preview_section">
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.78")}:</span>
            <span>${reformulateAmount(data?.municipalTax)}</span>
          </div>
          <div className="profile_sales_preview_field">
            <span>{t("profile.sales.79")}:</span>
            <span>${reformulateAmount(data?.municipalAssessment)}</span>
          </div>
        </div>

        <div className="my-3 d-flex justify-content-end">
          <Button
            variant="primary"
            className="mr-2"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            {t("profile.sales.42")}
          </Button>{" "}
          <Button
            variant="danger"
            className="mr-4 text-white"
            onClick={() => {
              setIsDeleting(true);
              setShowModal(false);
            }}
          >
            {t("profile.sales.43")}
          </Button>
        </div>
      </Modal.Body>
    </div>
  );
};

export default SalePreview;
