import { useEffect, useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { useDispatch, useSelector } from "react-redux";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";

const GeneralConditionStep = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { nextStep, previousStep } = useWizard();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = useCallback(
    (data) => {
      dispatch(
        updateFormalizedData({ standing: data?.standing, formComplete: true })
      );
      nextStep();
    },
    [dispatch, nextStep]
  );

  const handleSelectClick = useCallback(
    (item) => {
      setValue("standing", item);
      setSelectedItem(item);
    },
    [setValue]
  );

  const generalCondition = useMemo(
    () => [
      { label: t("Estimate-new.56"), value: "refaitNeuf" },
      { label: t("Estimate-new.57"), value: "bon" },
      { label: t("Estimate-new.58"), value: "rafraichissement" },
      { label: t("Estimate-new.59"), value: "travauxEtatProp" },
    ],
    [t]
  );

  useEffect(() => {
    setValue(
      "standing",
      formalizedData?.formalizedData
        ? formalizedData?.formalizedData
        : "refaitNeuf"
    );
    setSelectedItem(
      formalizedData?.formalizedData
        ? formalizedData?.formalizedData
        : "refaitNeuf"
    );
  }, [setValue, setSelectedItem, formalizedData?.formalizedData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
          {t("Estimate-new.55")}
        </h2>
        <div className="row row-cols-2 row-cols-lg-4" style={{ rowGap: 20 }}>
          {generalCondition.map((data) => (
            <div className="col" key={data.value}>
              <div
                className={`step-box-1 p-4 text-center flex-fill ${
                  selectedItem === data.value ? "active" : ""
                }`}
                onClick={() => handleSelectClick(data.value)}
              >
                <h6 className="mb-0 text-14 font-weight-700 font-poppins">
                  {data.label}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={previousStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            disabled={selectedItem === null ? true : false}
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default GeneralConditionStep;
