const getUserStatusClass = (status) => {
  switch (status) {
    case "Signed Mandate":
      return "badge-success";
    case "Offer Submitted":
      return "badge-info";
    case "In Progress":
      return "badge-info";
    case "Lost/Completion":
      return "badge-danger";
    case "To be restarted":
      return "badge-danger";
    case "Contacted":
      return "badge-secondary";
    case "New Prospect":
      return "badge-primary";
    case "Scheduled":
      return "badge-info";
    default:
      break;
  }
};

export default getUserStatusClass;
