import DashboardGraphs from "./DasboardGraphs";
import { useSelector } from "react-redux";
import Spinner from "../../components/spinner";

const FunctionalDashboard = () => {
  const { loadingUserData } = useSelector((state) => state.authentication);

  return <>{!loadingUserData ? <DashboardGraphs /> : <Spinner />}</>;
};

export default FunctionalDashboard;
