const getCurrentMonthWeeks = () => {
  const currentDate = new Date();
  const lastFourWeeks = [currentDate];
  let i = lastFourWeeks.length;
  while (i < 4) {
    const previousWeek = lastFourWeeks[0];
    const newWeek = new Date(previousWeek.getTime());
    newWeek.setDate(previousWeek.getDate() - 7);
    lastFourWeeks.unshift(newWeek);
    i++;
  }
  return lastFourWeeks;
};

export default getCurrentMonthWeeks;
