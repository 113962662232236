import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const checkForNewLeads = (userFilters, limit, prospects) => {
  const prospectsIds = prospects.map((prospect) => prospect.id);

  const checkNewLeads = httpsCallable(functions, "checkForNewLeads");
  const response = checkNewLeads({ ...userFilters, limit, prospectsIds })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  return response;
};

export default checkForNewLeads;
