import {
  setLeadsCount,
  setAllContactsCount,
  setAllProspectsCount,
  setLoadingDashboardData,
} from "../leadsSlice";
import { setFooterShow } from "../..//layout/layoutSettingSlice";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../../firebase";

export const getLeadsCount = () => async (dispatch) => {
  try {
    dispatch(setLoadingDashboardData(true));
    dispatch(setFooterShow(false));
    const allLeadsRef = collection(db, "RapportsEvaluations");

    const allContactsRef = query(
      allLeadsRef,
      where("ouiContacterParProfessionnel", "==", "oui")
    );

    const snapshotContacts = await getCountFromServer(allContactsRef);
    const countContacts = snapshotContacts.data().count;
    const snapshot = await getCountFromServer(allLeadsRef);
    const count = snapshot.data().count;
    dispatch(setLeadsCount(count));
    dispatch(setAllContactsCount(countContacts));
    dispatch(setAllProspectsCount(count - countContacts));
    dispatch(setLoadingDashboardData(false));
    dispatch(setFooterShow(true));
  } catch (error) {
    dispatch(setLoadingDashboardData(false));
    dispatch(setFooterShow(true));
    console.log("error", error);
  }
};
