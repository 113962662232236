import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const deleteNote = (notesId, noteId, setLoading) => {
  setLoading(true);
  const remove = httpsCallable(functions, "deleteNote");
  remove({ notesId, noteId })
    .then((res) => {
      setLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export default deleteNote;
