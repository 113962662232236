import { useState, useRef, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import { Timestamp } from "firebase/firestore";
import { Dropdown, DropdownButton } from "react-bootstrap";
import updateSale from "../../../redux/slices/sales/actions/updateSale";
import getProperyDetailInfo from "../../../redux/slices/sales/actions/getProperyDetailInfo";

import { useDispatch, useSelector } from "react-redux";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AdressField from "../../../components/forms/AddressField";
import Suggestions from "../../../components/forms/AddressField/Suggestions";
import getPlaceDetail from "../../../components/forms/AddressField/utils/getPlaceDetail";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../components/spinner";
import {
  convertDate,
  placeToAddress,
  assignValueToLabel,
  assignFields,
  convertStringToDate,
} from "./utils/UtilsFunction";
import WarningModal from "./components/WarningModal";

const EditSale = ({ show, onHide, t, selectedSale }) => {
  const dispatch = useDispatch();

  const [showWarning, setShowWarning] = useState(false);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [place, setPlace] = useState("");
  const adressInputRef = useRef(null);

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 1000,
  });

  const salesData = useSelector((state) => state.sales);

  const {
    conditions,
    contractStatus,
    categories,
    genresOne,
    genresTwo,
    types,
  } = salesData;

  const oldSale = useMemo(() => selectedSale?.data, [selectedSale]);

  const validationSchema = Yup.object({
    soldPropertyAddress: Yup.object().required(),
    noMLS: Yup.string().required("NoMLS is required"),
    yearOfConstruction: Yup.string().required(),
    amountOffered: Yup.string().required(),
    propertyCondition: Yup.string()
      .notOneOf(["Select"], "Please select a valid option")
      .required(),
    contractStatus: Yup.string()
      .notOneOf(["Select"], "Please select a valid option")
      .required(),
    dateExpirationContract: Yup.date().when("contractStatus", {
      is: contractStatus[1]?.value,
      then: () => Yup.date().required(),
    }),
    amountSold: Yup.string().when("contractStatus", {
      is: contractStatus[0]?.value,
      then: () => Yup.string().required(),
    }),
    dateSold: Yup.date().when("contractStatus", {
      is: contractStatus[0]?.value,
      then: () => Yup.date().required(),
    }),
    propertyCategory: Yup.string()
      .notOneOf(["Select"], "Please select a valid option")
      .required(),
    propertyGenre: Yup.string()
      .notOneOf(["Select"], "Please select a valid option")
      .required(),
    propertyType: Yup.string()
      .notOneOf(["Select"], "Please select a valid option")
      .required(),
    buildingArea: Yup.number().required(),
    municipalAssessment: Yup.string().required(),
    municipalTax: Yup.string().required(),
    numberOfRooms: Yup.number().required(),
    numberOfBedRooms: Yup.number().required(),
    numberOfBathrooms: Yup.number().required(),
    numberOfParkingSpaces: Yup.number().required(),
    numberOfGarages: Yup.number().required(),
  });

  const handleChangeValue = useCallback(
    async (value, setFieldValue, setFieldTouched) => {
      await setFieldValue("contractStatus", value);
      await setFieldTouched("contractStatus", true);
    },
    []
  );

  const handlepropertyCategoryChange = useCallback(
    async (value, setFieldValue, setFieldTouched) => {
      await setFieldValue("propertyCategory", value);
      await setFieldTouched("propertyCategory", true);
      setFieldValue(
        "propertyGenre",
        t(`profile.sales.${assignValueToLabel(value, categories)}`) ===
          t("profile.sales.13")
          ? genresOne[0].value
          : genresTwo[0].value
      );
    },
    [t, categories, genresOne, genresTwo]
  );

  const handlepropertyGenreChange = useCallback(
    async (value, setFieldValue, setFieldTouched) => {
      await setFieldValue("propertyGenre", value);
      await setFieldTouched("propertyGenre", true);
    },
    []
  );

  const handlepropertyTypeChange = useCallback(
    async (value, setFieldValue, setFieldTouched) => {
      await setFieldValue("propertyType", value);
      await setFieldTouched("propertyType", true);
    },
    []
  );

  const handlepropertyCondition = useCallback(
    async (value, setFieldValue, setFieldTouched) => {
      await setFieldValue("propertyCondition", value);
      await setFieldTouched("propertyCondition", true);
    },
    []
  );

  const resetAddress = useCallback(async (adr = null, setFieldValue) => {
    await setFieldValue("soldPropertyAddress", adr);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowWarning(true)}
        dialogClassName="modal-90w"
        contentClassName="modal-content-custom"
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <div className="profile_sales_preview_title">
            {t("profile.sales.80")}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mySalesDivContainer">
            <Formik
              initialValues={{
                soldPropertyAddress: oldSale?.soldPropertyAddress || "",
                amountOffered: oldSale?.amountOffered || "",
                contractStatus: oldSale?.contractStatus || "Select",
                amountSold: oldSale?.amountSold || "",
                dateExpirationContract: oldSale.dateExpirationContract
                  ? convertStringToDate(oldSale.dateExpirationContract)
                  : "",
                dateSold: oldSale?.dateSold
                  ? convertStringToDate(oldSale?.dateSold)
                  : "",
                propertyCategory: oldSale?.propertyCategory,
                propertyGenre: oldSale?.propertyGenre || "Select",
                propertyType: oldSale?.propertyType || "Select",
                buildingArea: oldSale?.buildingArea || "",
                municipalAssessment: oldSale?.municipalAssessment || "",
                municipalTax: oldSale?.municipalTax || "",
                numberOfRooms: oldSale?.numberOfRooms || "",
                numberOfBedRooms: oldSale?.numberOfBedRooms || "",
                numberOfBathrooms: oldSale?.numberOfBathrooms || "",
                numberOfParkingSpaces: oldSale?.numberOfParkingSpaces || "",
                propertyCondition: oldSale?.propertyCondition || "Select",
                associatedBrokersForSale:
                  oldSale?.associatedBrokersForSale || "",
                noMLS: oldSale?.noMLS || "",
                numberOfGarages: oldSale?.numberOfGarages || 0,
                yearOfConstruction: oldSale?.yearOfConstruction || "",
                licenceNumber: oldSale?.licenceNumber || "",
              }}
              enableReinitialize={false}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                try {
                  const saleData = {
                    soldPropertyAddress: values.soldPropertyAddress,
                    noMLS: values.noMLS,
                    amountOffered: values?.amountOffered,
                    contractStatus: values.contractStatus,
                    amountSold: values?.amountSold,
                    dateSold: values?.dateSold
                      ? convertDate(values?.dateSold)
                      : "",
                    dateExpirationContract: values.dateExpirationContract
                      ? convertDate(values.dateExpirationContract)
                      : "",
                    propertyGenre: values?.propertyGenre,
                    propertyCategory: values?.propertyCategory,
                    propertyType: values?.propertyType,
                    buildingArea: values.buildingArea,
                    municipalAssessment: values?.municipalAssessment,
                    municipalTax: values?.municipalTax,
                    numberOfRooms: values.numberOfRooms,
                    numberOfBedRooms: values.numberOfBedRooms,
                    numberOfBathrooms: values.numberOfBathrooms,
                    numberOfParkingSpaces: values.numberOfParkingSpaces,
                    propertyCondition: values.propertyCondition,

                    associatedBrokersForSale: values.associatedBrokersForSale,
                    updatedAt: Timestamp.fromDate(new Date()),

                    numberOfGarages: values.numberOfGarages,
                    yearOfConstruction: values.yearOfConstruction,
                    licenceNumber: values.licenceNumber,
                  };
                  dispatch(updateSale(saleData, selectedSale.id));
                } catch (error) {
                  console.log("dispatch error: ", error);
                }

                setSubmitting(false);
                onHide();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit} className="mySalesForm">
                  <div className="d-flex flex-column  align-items-start">
                    <div className="profile_sale_add_field">
                      <div className="profile_sale_add_field_label">
                        {t("profile.sales.3")}
                      </div>
                      <div className="position-relative">
                        {showSuggestions && placePredictions?.length > 0 && (
                          <Suggestions
                            placePredictions={placePredictions}
                            getPlaceDetail={getPlaceDetail}
                            placesService={placesService}
                            setValue={setPlace}
                            placeToAddress={placeToAddress}
                            followUpFunc={async (adrs) => {
                              if (
                                adrs?.postcode &&
                                adrs?.StreetNumber?.long_name &&
                                adrs?.StreetName?.long_name
                              ) {
                                dispatch(
                                  getProperyDetailInfo(
                                    adrs?.postcode,
                                    `${adrs?.StreetNumber?.long_name} ${adrs?.StreetName?.long_name}`,
                                    assignFields,
                                    setFieldValue
                                  )
                                );
                              }
                            }}
                            customStyle={{
                              top: "47px",
                              left: "0",
                            }}
                            fieldName="soldPropertyAddress"
                            setFieldValue={setFieldValue}
                            setShowPredictions={setShowSuggestions}
                            adressInputRef={adressInputRef}
                          />
                        )}
                        {isPlacePredictionsLoading && <Spinner />}
                        <AdressField
                          cn={`profile_sale_add_field_input ${
                            errors.soldPropertyAddress &&
                            touched.soldPropertyAddress &&
                            "profile_sale_add_field_input_error"
                          }`}
                          placeholder=" "
                          getPlacePredictions={getPlacePredictions}
                          isPlacePredictionsLoading={isPlacePredictionsLoading}
                          setShowPredictions={setShowSuggestions}
                          value={values?.soldPropertyAddress?.value}
                          setValue={setPlace}
                          adressInputRef={adressInputRef}
                          setFieldTouched={setFieldTouched}
                          setFieldValue={setFieldValue}
                          resetAddress={resetAddress}
                        />
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="profile_sale_add_field col-6">
                        <div className="profile_sale_add_field_label">
                          NoMLS
                        </div>
                        <input
                          type="text"
                          name="noMLS"
                          className={`profile_sale_add_field_input ${
                            errors.noMLS &&
                            touched.noMLS &&
                            "profile_sale_add_field_input_error"
                          }`}
                          value={values.noMLS}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="profile_sale_add_field col-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.84")}
                        </div>
                        <input
                          type="text"
                          name="yearOfConstruction"
                          className={`profile_sale_add_field_input ${
                            errors.yearOfConstruction &&
                            touched.yearOfConstruction &&
                            "profile_sale_add_field_input_error"
                          }`}
                          value={values.yearOfConstruction}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.4")}
                        </div>
                        <input
                          type="text"
                          name="amountOffered"
                          value={values.amountOffered}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.amountOffered &&
                            touched.amountOffered &&
                            "profile_sale_add_field_input_error"
                          }`}
                          style={{}}
                        />
                      </div>
                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.37")}
                        </div>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={
                            values.propertyCondition === "Select"
                              ? values.propertyCondition
                              : t(
                                  `profile.sales.${assignValueToLabel(
                                    values.propertyCondition,
                                    conditions
                                  )}`
                                )
                          }
                          drop="down"
                          variant="Secondary"
                          onSelect={(value) =>
                            handlepropertyCondition(
                              value,
                              setFieldValue,
                              setFieldTouched
                            )
                          }
                          className={`dropdown profile_sale_add_field_input ${
                            errors.propertyCondition &&
                            touched.propertyCondition &&
                            "profile_sale_add_field_input_error"
                          }`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {conditions.map((item, index) => (
                            <Dropdown.Item
                              key={item.label}
                              eventKey={item.value}
                            >
                              {t(`profile.sales.${item.label}`)}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.5")}
                        </div>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={
                            values?.contractStatus === "Select"
                              ? values?.contractStatus
                              : t(
                                  `profile.sales.${assignValueToLabel(
                                    values?.contractStatus,
                                    contractStatus
                                  )}`
                                )
                          }
                          drop="down"
                          variant="Secondary"
                          onSelect={(value) =>
                            handleChangeValue(
                              value,
                              setFieldValue,
                              setFieldTouched
                            )
                          }
                          className={`dropdown profile_sale_add_field_input ${
                            errors.contractStatus &&
                            touched.contractStatus &&
                            "profile_sale_add_field_input_error"
                          }`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {contractStatus.map((item, index) => (
                            <Dropdown.Item
                              key={item.label}
                              eventKey={item.value}
                            >
                              {t(`profile.sales.${item.label}`)}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                      {values.contractStatus === contractStatus[1]?.value && (
                        <div className="profile_sale_add_field col-md-6">
                          <div className="profile_sale_add_field_label">
                            {t("profile.sales.11")}
                          </div>
                          <DatePicker
                            className={`profile_sale_add_field_input ${
                              errors.dateExpirationContract &&
                              touched.dateExpirationContract &&
                              "profile_sale_add_field_input_error"
                            }`}
                            dateFormat="dd/MM/yy"
                            selected={
                              values.dateExpirationContract
                                ? values.dateExpirationContract
                                : ""
                            }
                            onChange={async (date) => {
                              await setFieldValue(
                                "dateExpirationContract",
                                date
                              );
                              await setFieldTouched(
                                "dateExpirationContract",
                                true
                              );
                            }}
                            calendarClassName="custom-calendar"
                          />
                        </div>
                      )}

                      {values.contractStatus === contractStatus[0]?.value && (
                        <div className="profile_sale_add_field col-md-6">
                          <div className="profile_sale_add_field_label">
                            {t("profile.sales.10")}
                          </div>
                          <DatePicker
                            className={`profile_sale_add_field_input ${
                              errors.dateSold &&
                              touched.dateSold &&
                              "profile_sale_add_field_input_error"
                            }`}
                            dateFormat="dd/MM/yy"
                            selected={values.dateSold ? values.dateSold : ""}
                            onChange={async (date) => {
                              await setFieldValue("dateSold", date);
                              await setFieldTouched("dateSold", true);
                            }}
                            calendarClassName="custom-calendar"
                          />
                        </div>
                      )}
                    </div>

                    {values?.contractStatus === contractStatus[0]?.value && (
                      <div className="profile_sale_add_field">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.9")}
                        </div>
                        <input
                          type="text"
                          name="amountSold"
                          value={values.amountSold}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.amountSold &&
                            touched.amountSold &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>
                    )}

                    <div className="profile_sale_add_field">
                      <div className="profile_sale_add_field_label">
                        {t("profile.sales.12")}
                      </div>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={
                          values.propertyCategory === "Select"
                            ? values.propertyCategory
                            : t(
                                `profile.sales.${assignValueToLabel(
                                  values.propertyCategory,
                                  categories
                                )}`
                              )
                        }
                        drop="down"
                        variant="Secondary"
                        onSelect={(value) =>
                          handlepropertyCategoryChange(
                            value,
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                        className={`dropdown profile_sale_add_field_input ${
                          errors.propertyCategory &&
                          touched.propertyCategory &&
                          "profile_sale_add_field_input_error"
                        }`}
                        style={{ paddingLeft: "0px" }}
                      >
                        {categories.map((item, index) => (
                          <Dropdown.Item key={item.label} eventKey={item.value}>
                            {t(`profile.sales.${item.label}`)}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>

                    {values.propertyCategory !== "Select" && (
                      <div className="profile_sale_add_field">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.15")}
                        </div>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={t(
                            `profile.sales.${assignValueToLabel(
                              values.propertyGenre,
                              [...genresOne, ...genresTwo]
                            )}`
                          )}
                          drop="down"
                          variant="Secondary"
                          onSelect={(value) =>
                            handlepropertyGenreChange(
                              value,
                              setFieldValue,
                              setFieldTouched
                            )
                          }
                          className={`dropdown profile_sale_add_field_input ${
                            errors.propertyGenre &&
                            touched.propertyGenre &&
                            "profile_sale_add_field_input_error"
                          }`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {t(
                            `profile.sales.${assignValueToLabel(
                              values.propertyCategory,
                              categories
                            )}`
                          ) === t("profile.sales.13")
                            ? genresOne.map((item, index) => (
                                <Dropdown.Item
                                  key={item.label}
                                  eventKey={item.value}
                                  className="copropertyItems"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  {t(`profile.sales.${item.label}`)}
                                </Dropdown.Item>
                              ))
                            : genresTwo.map((item, index) => (
                                <Dropdown.Item
                                  key={item.label}
                                  eventKey={item.value}
                                  className="pl-0 copropertyItems"
                                >
                                  {t(`profile.sales.${item.label}`)}
                                </Dropdown.Item>
                              ))}
                        </DropdownButton>
                      </div>
                    )}

                    {values.propertyCategory !== "Select" && (
                      <div className="profile_sale_add_field">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.24")}{" "}
                        </div>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={
                            values.propertyType === "Select"
                              ? values.propertyType
                              : t(
                                  `profile.sales.${assignValueToLabel(
                                    values.propertyType,
                                    types
                                  )}`
                                )
                          }
                          drop="down"
                          variant="Secondary"
                          onSelect={(value) =>
                            handlepropertyTypeChange(
                              value,
                              setFieldValue,
                              setFieldTouched
                            )
                          }
                          className={`dropdown profile_sale_add_field_input ${
                            errors.propertyType &&
                            touched.propertyType &&
                            "profile_sale_add_field_input_error"
                          }`}
                          style={{ paddingLeft: "0px" }}
                        >
                          {types.map((item) => (
                            <Dropdown.Item
                              key={item.label}
                              eventKey={item.value}
                            >
                              {t(`profile.sales.${item.label}`)}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    )}

                    <div className="profile_sale_add_field">
                      <div className="profile_sale_add_field_label">
                        {t("profile.sales.30")}
                      </div>
                      <input
                        type="number"
                        name="buildingArea"
                        value={values.buildingArea}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`profile_sale_add_field_input ${
                          errors.buildingArea &&
                          touched.buildingArea &&
                          "profile_sale_add_field_input_error"
                        }`}
                      />
                    </div>

                    <div className="row w-100">
                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">{`${t(
                          "profile.sales.31"
                        )}($)`}</div>
                        <input
                          type="text"
                          name="municipalAssessment"
                          value={values.municipalAssessment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.municipalAssessment &&
                            touched.municipalAssessment &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>

                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">{`${t(
                          "profile.sales.32"
                        )}($)`}</div>

                        <input
                          type="text"
                          name="municipalTax"
                          value={values.municipalTax}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.municipalTax &&
                            touched.municipalTax &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>
                    </div>

                    <div className="row w-100">
                      <div className="profile_sale_add_field col-md-4">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.33")}
                        </div>
                        <input
                          type="number"
                          name="numberOfRooms"
                          value={values.numberOfRooms}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.numberOfRooms &&
                            touched.numberOfRooms &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>

                      <div className="profile_sale_add_field col-md-4">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.34")}
                        </div>
                        <input
                          type="number"
                          name="numberOfBedRooms"
                          value={values.numberOfBedRooms}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.numberOfBedRooms &&
                            touched.numberOfBedRooms &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>

                      <div className="profile_sale_add_field col-md-4">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.35")}
                        </div>
                        <input
                          type="number"
                          name="numberOfBathrooms"
                          value={values.numberOfBathrooms}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.numberOfBathrooms &&
                            touched.numberOfBathrooms &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>
                    </div>

                    <div className="row w-100">
                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.36")}
                        </div>
                        <input
                          type="number"
                          name="numberOfParkingSpaces"
                          value={values.numberOfParkingSpaces}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.numberOfParkingSpaces &&
                            touched.numberOfParkingSpaces &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>

                      <div className="profile_sale_add_field col-md-6">
                        <div className="profile_sale_add_field_label">
                          {t("profile.sales.83")}
                        </div>
                        <input
                          type="number"
                          name="numberOfGarages"
                          value={values.numberOfGarages}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`profile_sale_add_field_input ${
                            errors.numberOfGarages &&
                            touched.numberOfGarages &&
                            "profile_sale_add_field_input_error"
                          }`}
                        />
                      </div>
                    </div>

                    <div className="profile_sale_add_field">
                      <div className="profile_sale_add_field_label">
                        {t("profile.sales.38")}
                      </div>
                      <input
                        type="text"
                        name="associatedBrokersForSale"
                        value={values.associatedBrokersForSale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`profile_sale_add_field_input ${
                          errors.associatedBrokersForSale &&
                          touched.associatedBrokersForSale &&
                          "profile_sale_add_field_input_error"
                        }`}
                      />
                    </div>

                    <div className="profile_sale_add_field">
                      <div className="profile_sale_add_field_label">
                        {t("profile.sales.85")}
                      </div>
                      <input
                        type="text"
                        name="licenceNumber"
                        value={values.licenceNumber}
                        maxLength="5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`profile_sale_add_field_input ${
                          errors.licenceNumber &&
                          touched.licenceNumber &&
                          "profile_sale_add_field_input_error"
                        }`}
                      />
                    </div>

                    <div
                      className="mySalesBtnDiv"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary btn-block mt-3 "
                        style={{
                          width: "30%",
                          marginRight: "16px",
                        }}
                      >
                        {t("profile.sales.82")}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <WarningModal
        t={t}
        showWarning={showWarning}
        setShowWarning={setShowWarning}
        onHide={() => {
          onHide();
        }}
      />
    </>
  );
};

export default EditSale;
