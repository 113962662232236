import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./i18next";
import { RouterProvider } from "react-router-dom";
import router from "routes";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import "./assets/styles/app/app.scss";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/Store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ReduxProvider store={store}>
    <ToastContainer />
    <RouterProvider router={router} />
  </ReduxProvider>
);
