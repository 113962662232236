import { useMemo } from "react";
import { useSelector } from "react-redux";
import { assignValueToLabel, reformulateAmount } from "../utils/UtilsFunction";

const Sale = ({ t, sale, setSelectedSale, selectedSale }) => {
  const { data } = sale;

  const salesData = useSelector((state) => state.sales);

  const { genresOne, genresTwo } = salesData;

  const title = useMemo(
    () =>
      t(
        `profile.sales.${assignValueToLabel(data?.propertyGenre, [
          ...genresOne,
          ...genresTwo,
        ])}`
      ),
    [data?.propertyGenre, genresOne, genresTwo, t]
  );

  const cn = useMemo(
    () =>
      selectedSale?.id === sale?.id
        ? "profile_sales_sale profile_sales_sale_selected"
        : "profile_sales_sale",
    [sale?.id, selectedSale?.id]
  );

  return (
    <div className={cn} onClick={() => setSelectedSale(sale)}>
      <div className="profile_sales_sale_adress">{`${title} ${t(
        "profile.sales.62"
      )} ${data?.soldPropertyAddress?.StreetName?.short_name}`}</div>
      <div className="profile_sales_sale_data">
        <div className="profile_sales_sale_chars">
          <div>{`${data?.numberOfBedRooms} chambres`}</div>
          <div>{`${data?.buildingArea} ft²`}</div>
          <div>{`${data?.numberOfGarages} garages`}</div>
        </div>
        <div className="profile_sales_sale_estimation">
          <span>{t("profile.sales.61")}</span>
          <span>{`$${reformulateAmount(data?.municipalAssessment)}`}</span>
        </div>
      </div>
    </div>
  );
};

export default Sale;
