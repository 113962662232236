import { db } from "../../../../firebase";
import { setAddingSale, setSales, setLastFetchedSale } from "../salesSlice";
import { collection, addDoc } from "firebase/firestore";

import { toast } from "react-toastify";

const addSale = (saleData) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const userId = state.authentication.user;
      dispatch(setAddingSale(true));
      const { count } = state.sales;

      const docRef = await addDoc(collection(db, "sales"), {
        ...saleData,
        userRef: userId,
      });

      const serialzedUpdatedAt = saleData?.updatedAt
        ? saleData?.updatedAt.toDate().getTime()
        : null;

      const serializedtimestamp = saleData?.timestamp
        ? saleData.timestamp.toDate().getTime()
        : null;

      const saleObj = {
        ...saleData,
        updatedAt: serialzedUpdatedAt,
        timestamp: serializedtimestamp,
        userRef: userId,
      };

      const updatedSales = [
        { data: { ...saleObj }, id: docRef.id },
        ...state.sales.sales,
      ];

      dispatch(setSales(updatedSales));
      dispatch(setSales(updatedSales.slice(0, count)));
      dispatch(
        setLastFetchedSale(
          updatedSales.length > count ? updatedSales[count] : null
        )
      );
      dispatch(setAddingSale(false));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
};

export default addSale;
