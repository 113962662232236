import { setProjectValue, setEvaluations } from "../leadsSlice";

export const onStatusChangeAction = (e) => {
  return async (dispatch, getState) => {
    const { All, ownerValue } = getState().leads;

    const value = e.value === "RecherchInformations" ? "" : e.value;
    let evals;
    if (ownerValue && ownerValue.value === "non") {
      evals = All.filter(
        (v) =>
          v.estProprietaireReponse?.toLowerCase() === "non" &&
          v.statutRecherche?.toLowerCase() === value?.toLowerCase()
      );
    } else {
      evals = All.filter(
        (v) =>
          v.estProprietaireReponse?.toLowerCase() === "oui" &&
          v.envisageVendreBienReponse?.toLowerCase() === value?.toLowerCase()
      );
    }
    dispatch(setProjectValue(e));
    dispatch(setEvaluations(evals));
  };
};
