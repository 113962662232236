import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { setAccountData } from "../authenticationSlice";
import { setCurrentPlan } from "../../subscription/subscriptionSlice";
export const getAccountData = (uid) => {
  return async (dispatch, getState) => {
    try {
      const docRef = doc(db, "account", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const accountData = docSnap.data();
        dispatch(setAccountData(accountData));
        if (accountData?.planName) {
          dispatch(setCurrentPlan(accountData?.plan));
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};
