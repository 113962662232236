import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import postEstimation from "../../../redux/slices/evaluation/actions/postEstimation";

const FinalLoading = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userData } = useSelector((state) => state.authentication);
  const { result, formalizedData } = useSelector((state) => state.evaluation);

  useEffect(() => {
    console.log("userData", userData);
    console.log("formalizedData", formalizedData);
    console.log("result", result);
    if (result?.ziaEstimation && userData && formalizedData) {
      dispatch(
        postEstimation(
          userData?.userId,
          userData,
          formalizedData,
          result,
          navigate
        )
      );
    }
  }, [userData, formalizedData, result, navigate, dispatch]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center bg-white shadow-sm rounded-xl p-4">
      <img
        height={200}
        width={200}
        src="/assets/images/ziawayLoader.gif"
        alt=""
      />
      <h4 className="mb-0">{t("Estimate-new.121")}</h4>
    </div>
  );
};
export default FinalLoading;
