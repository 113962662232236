import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

const Timer = ({ lastAcceptedContactDate, setIsRestricted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentTime = useMemo(() => new Date().getTime() - 15 * 60 * 1000, []);

  const lastAcceptedContactTime = useMemo(
    () => lastAcceptedContactDate,
    [lastAcceptedContactDate]
  );

  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    setRemainingTime(lastAcceptedContactTime - currentTime);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const convertMillisecondsToTime = useCallback((milliseconds) => {
    const date = new Date(milliseconds);
    return format(date, "mm:ss");
  }, []);

  useEffect(() => {
    if (remainingTime && remainingTime <= 0) {
      dispatch(setIsRestricted(false));
    }
  }, [remainingTime, dispatch, setIsRestricted]);

  return (
    <div className="newlead_timer">
      <span>{t("Leads.124")}</span>

      <span className="newlead_timer_time">
        {convertMillisecondsToTime(remainingTime)}
      </span>
    </div>
  );
};
export default Timer;
