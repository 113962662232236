import {
  setEstimations,
  setLoading,
  setLastFetchedEstimation,
  setCount,
} from "../myEstimationsSlice";
import { db } from "../../../../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

const getMyEstimations = (userId) => async (dispatch, getState) => {
  try {
    const { fixedLimit, count } = getState().myEstimations;
    dispatch(setLoading(true));
    const estimations = [];
    const q = query(
      collection(db, "RapportsEvaluations"),
      where("userID", "==", userId),
      orderBy("dateCreation", "desc"),
      limit(fixedLimit + 1)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      const data = doc.data();
      const docData = {
        id: doc.id,
        ...data,
      };
      estimations.push(docData);
    });

    dispatch(setEstimations(estimations.slice(0, fixedLimit)));
    dispatch(
      setLastFetchedEstimation(
        estimations.length > fixedLimit
          ? estimations[estimations.length - 1]?.id
          : null
      )
    );
    dispatch(setCount(count + fixedLimit));
    dispatch(setLoading(false));
  } catch (error) {
    console.log("getMyEstimations_error", error);
    dispatch(setLoading(false));
  }
};

export default getMyEstimations;
