import { useState } from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { updateUserInternetData } from "../../../redux/slices/authentication/actions/updateUserInternetData";
import ChangeDialog from "../components/ChangeDialog";
import { useDispatch } from "react-redux";

const InternetInfo = ({ t, profile }) => {
  const [editInternetInfo, setEditInternetInfo] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <div className="profile_personalInfo">
        <div className="row profile_personalInfo_section">
          <div className="col-12">
            <div className="row">
              <div>
                <h4 className="profile_section_title text-capitalize">
                  {t("profile.internetInfo.1")}
                </h4>
                <p className="profile_section_desc">
                  {t("profile.internetInfo.2")}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <Formik
                  initialValues={{
                    website: profile?.officialInformation?.website
                      ? profile?.officialInformation?.website
                      : "",
                    facebook: profile?.officialInformation?.facebook
                      ? profile?.officialInformation?.facebook
                      : "",
                    linkedIn: profile?.officialInformation?.linkedIn
                      ? profile?.officialInformation?.linkedIn
                      : "",
                    insta: profile?.officialInformation?.insta
                      ? profile?.officialInformation?.insta
                      : "",
                    twitter: profile?.officialInformation?.twitter
                      ? profile?.officialInformation?.twitter
                      : "",
                  }}
                  enableReinitialize={true}
                  validate={(values) => {
                    const errors = {};
                    if (values.website && !/^https:\/\//.test(values.website)) {
                      errors.website = `Enter a valid URL that starts with https://`;
                    }
                    if (
                      values.facebook &&
                      !/^https:\/\//.test(values.facebook)
                    ) {
                      errors.facebook = `Enter a valid URL that starts with https://`;
                    }
                    if (
                      values.linkedIn &&
                      !/^https:\/\//.test(values.linkedIn)
                    ) {
                      errors.linkedIn = `Enter a valid URL that starts with https://`;
                    }
                    if (values.insta && !/^https:\/\//.test(values.insta)) {
                      errors.insta = `Enter a valid URL that starts with https://`;
                    }
                    if (values.twitter && !/^https:\/\//.test(values.twitter)) {
                      errors.twitter = `Enter a valid URL that starts with https://`;
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    console.log(values);
                    dispatch(
                      updateUserInternetData(
                        values,
                        t("profile.23"),
                        t("profile.24"),
                        t("profile.25"),
                        t("profile.26")
                      )
                    );
                    setEditInternetInfo(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,

                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="py-3 d-flex flex-column">
                        <div className="mb-3">
                          <label className="profile_section_label">
                            {t("profile.internetInfo.3")}
                          </label>
                          <input
                            type="text"
                            className="form-control profile_input"
                            name="website"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.website}
                            disabled={!editInternetInfo}
                            style={{
                              backgroundImage:
                                "url(/assets/icons/socials/web.png)",
                              backgroundPosition: "left",
                              backgroundRepeat: "no-repeat",
                              paddingLeft: "70px",
                            }}
                          />
                          {errors.website && touched.website && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.website}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            className="profile_section_label"
                            htmlFor="validationCustom202"
                          >
                            {t("profile.internetInfo.4")}
                          </label>
                          <input
                            type="text"
                            className="form-control profile_input"
                            name="facebook"
                            disabled={!editInternetInfo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.facebook}
                            style={{
                              backgroundImage:
                                "url(/assets/icons/socials/fb.png)",
                              backgroundPosition: "left",
                              backgroundRepeat: "no-repeat",
                              paddingLeft: "70px",
                            }}
                          />
                          {errors.facebook && touched.facebook && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.facebook}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label className="profile_section_label">
                            {t("profile.internetInfo.6")}
                          </label>
                          <input
                            type="text"
                            className="form-control profile_input"
                            name="linkedIn"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.linkedIn}
                            disabled={!editInternetInfo}
                            style={{
                              backgroundImage:
                                "url(/assets/icons/socials/in.png)",
                              backgroundPosition: "left",
                              backgroundRepeat: "no-repeat",
                              paddingLeft: "70px",
                            }}
                          />
                          {errors.linkedIn && touched.linkedIn && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.linkedIn}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            className="profile_section_label"
                            htmlFor="validationCustom202"
                          >
                            {t("profile.internetInfo.5")}
                          </label>
                          <input
                            type="text"
                            className="form-control profile_input"
                            name="insta"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.insta}
                            disabled={!editInternetInfo}
                            style={{
                              backgroundImage:
                                "url(/assets/icons/socials/insta.png)",
                              backgroundPosition: "left",
                              backgroundRepeat: "no-repeat",
                              paddingLeft: "70px",
                            }}
                          />
                          {errors.insta && touched.insta && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.insta}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label className="profile_section_label">
                            {t("profile.internetInfo.7")}
                          </label>
                          <input
                            type="text"
                            className="form-control profile_input"
                            name="twitter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.twitter}
                            disabled={!editInternetInfo}
                            style={{
                              backgroundImage:
                                "url(/assets/icons/socials/tw.png)",
                              backgroundPosition: "left",
                              backgroundRepeat: "no-repeat",
                              paddingLeft: "70px",
                            }}
                          />
                          {errors.twitter && touched.twitter && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.twitter}
                            </div>
                          )}
                        </div>
                        {!editInternetInfo ? (
                          <div className="col-12 d-flex justify-content-end">
                            <Button
                              className="profile_submit_btn"
                              type="button"
                              onClick={() => setEditInternetInfo(true)}
                              disabled={isSubmitting}
                            >
                              {t("NProfile.12")}
                            </Button>
                          </div>
                        ) : (
                          <div className="col-12">
                            <ChangeDialog
                              isSubmitting={isSubmitting}
                              cancelFunc={() => {
                                setEditInternetInfo(false);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetInfo;
