import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
// import { Loading } from "@gull";
import Spinner from "../../../components/spinner";
const ChangeDialog = ({ isSubmitting, cancelFunc }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between align-items-center">
      {isSubmitting && <Spinner />}
      <Button
        className="btn btn-secondary profile_cancel_btn"
        disabled={isSubmitting}
        type="button"
        onClick={cancelFunc}
      >
        {t("profile.22")}
      </Button>
      <Button
        className="profile_submit_btn"
        type="submit"
        disabled={isSubmitting}
      >
        {t("profile.21")}
      </Button>
    </div>
  );
};

export default ChangeDialog;
