import { useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import ChangeDialog from "../components/ChangeDialog";
import { updateUserMessages } from "../../../redux/slices/authentication/actions/updateUserMessages";

const UserMessages = ({ t }) => {
  const { userData } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [editEmail, setEditEmail] = useState(false);
  const [editSms, setEditSms] = useState(false);

  const updateDetails = useCallback(
    (data) => {
      dispatch(
        updateUserMessages(
          data,
          t("swal.22"),
          t("swal.23"),
          t("swal.1"),
          t("swal.4"),
          userData,
          () => {
            setEditEmail(false);
            setEditSms(false);
          }
        )
      );
    },
    [dispatch, userData, t]
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="row">
            <div className="col-12 mb-4">
              <Formik
                initialValues={{
                  emailText: userData?.messages?.emailText
                    ? userData?.messages?.emailText
                    : t("swal.20"),

                  smsText: userData?.messages?.smsText
                    ? userData?.messages?.smsText
                    : t("swal.21"),
                }}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  updateDetails(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div>
                        <div className="profile_section_message_container">
                          <div className="profile_section_message">
                            <h4 className="profile_section_message_title text-capitalize">
                              {t("profile.usermessages.2")}
                            </h4>
                            <p className="profile_section_message_desc">
                              {t("profile.usermessages.4")}
                            </p>
                            <p className="profile_section_message_label">
                              {t("profile.usermessages.5")}
                            </p>
                            <textarea
                              className="form-control"
                              rows={10}
                              name="emailText"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.emailText}
                              disabled={!editEmail}
                            />
                            <div className="mt-2">
                              {!editEmail ? (
                                <div className="d-flex justify-content-end">
                                  <Button
                                    className="profile_submit_btn"
                                    type="button"
                                    onClick={() => setEditEmail(true)}
                                    disabled={isSubmitting}
                                  >
                                    {t("NProfile.25")}
                                  </Button>
                                </div>
                              ) : (
                                <ChangeDialog
                                  isSubmitting={isSubmitting}
                                  cancelFunc={() => {
                                    setEditEmail(false);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="profile_section_message">
                            <h4 className="profile_section_message_title text-capitalize">
                              {t("profile.usermessages.3")}
                            </h4>
                            <p className="profile_section_message_desc">
                              {t("profile.usermessages.4")}
                            </p>
                            <p className="profile_section_message_label">
                              {t("profile.usermessages.5")}
                            </p>
                            <textarea
                              className="form-control profile_input"
                              rows={10}
                              name="smsText"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.smsText}
                              disabled={!editSms}
                            />
                            <div className="mt-2">
                              {!editSms ? (
                                <div className="d-flex justify-content-end">
                                  <Button
                                    className="profile_submit_btn"
                                    type="button"
                                    onClick={() => setEditSms(true)}
                                    disabled={isSubmitting}
                                  >
                                    {t("NProfile.25")}
                                  </Button>
                                </div>
                              ) : (
                                <ChangeDialog
                                  isSubmitting={isSubmitting}
                                  cancelFunc={() => {
                                    setEditSms(false);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessages;
