import { signOut } from "firebase/auth";
import { auth } from "../../../../firebase";
import { setLoading, setUser, setError } from "../authenticationSlice";

export const logOut = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      signOut(auth)
        .then(() => {
          dispatch(setUser(null));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setError(error.code));
          dispatch(setLoading(false));
          console.log("error", error.code);
        });
    } catch (error) {
      console.log("error", error);
      dispatch(setError(error.code));
      dispatch(setLoading(false));
    }
  };
};
