import { setOwnerValue, setUserFilters } from "../leadsSlice";
import moment from "moment";

export const refreshFilterAction = (label) => {
  return async (dispatch, getState) => {
    dispatch(setOwnerValue({ value: "all", label: label }));
    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment().subtract(31, "days").format("YYYY-MM-DD");
    dispatch(
      setUserFilters({
        ...getState().leads.userFilters,
        endDate,
        startDate,
        dateFilterType: "31days",
      })
    );
  };
};
