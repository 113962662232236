import { createSlice } from "@reduxjs/toolkit";

export const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    leads: [],
    returningLeads: [],
    leadsCount: 0,
    allContactsCount: 0,
    allProspectsCount: 0,
    loading: false,
    error: null,
    loadingDashboardData: false,
    loadingLeads: false,

    All: null,
    evaluations: [],
    myEvaluations: [],
    initialDate: null,
    finalDate: null,
    filters: {},
    userFilters: {},
    reports: [],
    markers: [],
    selectedMarker: null,
    cities: [],
    muncipalities: [],
    projectStatus: null,
    coordinates: [],
    cityValue: null,
    munciValue: null,
    projectValue: null,
    ownerValue: null,
    dateInfo: null,

    dateFilterType: "31days",
    currentMunicipality: null,
    limit: 5,
    prospectsCount: 5,
    nextProspectId: null,
    sortedBy: null,
    sortingType: null,
    shownProspects: "opportunities",
    needToRefresh: false,
    newProspects: [],
    isRestricted: false,
    uniqueEvaluations: [],

    actionLoading: false,
    firstFetchLoading: false,
    fetchMoreLoading: false,

    myContactsLineData: [],
    lastFourWeeksStrings: [],
    allLeadsLineData: [],
  },
  reducers: {
    setActionLoading: (state, action) => {
      state.actionLoading = action.payload;
    },

    setLoadingLeads: (state, action) => {
      state.loadingLeads = action.payload;
    },

    setFirstFetchLoading: (state, action) => {
      state.firstFetchLoading = action.payload;
    },

    setFetchMoreLoading: (state, action) => {
      state.fetchMoreLoading = action.payload;
    },

    setLeads: (state, action) => {
      state.leads = action.payload;
    },

    setLoadingDashboardData: (state, action) => {
      state.loadingDashboardData = action.payload;
    },

    setReturningLeads: (state, action) => {
      state.returningLeads = action.payload;
    },

    setLeadsCount: (state, action) => {
      state.leadsCount = action.payload;
    },

    setAllContactsCount: (state, action) => {
      state.allContactsCount = action.payload;
    },

    setAllProspectsCount: (state, action) => {
      state.allProspectsCount = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setAll: (state, action) => {
      state.All = action.payload;
    },

    setEvaluations: (state, action) => {
      state.evaluations = action.payload;
    },

    setMyEvaluations: (state, action) => {
      state.myEvaluations = action.payload;
    },

    setInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },

    setFinalDate: (state, action) => {
      state.finalDate = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setUserFilters: (state, action) => {
      state.userFilters = action.payload;
    },

    setReports: (state, action) => {
      state.reports = action.payload;
    },

    setMarkers: (state, action) => {
      state.markers = action.payload;
    },

    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },

    setCities: (state, action) => {
      state.cities = action.payload;
    },

    setMuncipalities: (state, action) => {
      state.muncipalities = action.payload;
    },

    setProjectStatus: (state, action) => {
      state.projectStatus = action.payload;
    },

    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },

    setCityValue: (state, action) => {
      state.cityValue = action.payload;
    },

    setMunciValue: (state, action) => {
      state.munciValue = action.payload;
    },

    setProjectValue: (state, action) => {
      state.projectValue = action.payload;
    },

    setOwnerValue: (state, action) => {
      state.ownerValue = action.payload;
    },

    setDateInfo: (state, action) => {
      state.dateInfo = action.payload;
    },

    setDateFilterType: (state, action) => {
      state.dateFilterType = action.payload;
    },

    setCurrentMunicipality: (state, action) => {
      state.currentMunicipality = action.payload;
    },

    setLimit: (state, action) => {
      state.limit = action.payload;
    },

    setProspectsCount: (state, action) => {
      state.prospectsCount = action.payload;
    },

    setNextProspectId: (state, action) => {
      state.nextProspectId = action.payload;
    },

    setSortedBy: (state, action) => {
      state.sortedBy = action.payload;
    },

    setSortingType: (state, action) => {
      state.sortingType = action.payload;
    },

    setShownProspects: (state, action) => {
      state.shownProspects = action.payload;
    },

    setNeedToRefresh: (state, action) => {
      state.needToRefresh = action.payload;
    },

    setNewProspects: (state, action) => {
      state.newProspects = action.payload;
    },

    setIsRestricted: (state, action) => {
      state.isRestricted = action.payload;
    },

    setUniqueEvaluations: (state, action) => {
      state.uniqueEvaluations = action.payload;
    },

    setProspects: (state, action) => {
      state.prospects = action.payload;
    },

    clearState: (state) => {
      state.loading = false;
      state.All = null;
      state.evaluations = [];
      state.myEvaluations = [];
      state.initialDate = null;
      state.finalDate = null;
      state.filters = {};
      state.userFilters = {};
      state.reports = [];
      state.markers = [];
      state.selectedMarker = false;
      state.cities = [];
      state.muncipalities = [];
      state.projectStatus = null;
      state.coordinates = [];
      state.cityValue = null;
      state.munciValue = null;
      state.projectValue = null;
      state.ownerValue = null;
      state.dateInfo = null;
      state.dateFilterType = "31days";
      state.currentMunicipality = null;
      state.limit = 5;
      state.leadsCount = 5;
      state.nextProspectId = null;
      state.sortedBy = null;
      state.sortingType = null;
      state.shownProspects = "opportunities";
      state.needToRefresh = false;
      state.newProspects = [];
      state.isRestricted = false;
      state.uniqueEvaluations = [];
    },

    setMyContactsLineData: (state, action) => {
      state.myContactsLineData = action.payload;
    },

    setLastFourWeeksStrings: (state, action) => {
      state.lastFourWeeksStrings = action.payload;
    },

    setAllLeadsLineData: (state, action) => {
      state.allLeadsLineData = action.payload;
    },
  },
});

export const {
  setLeads,
  setLeadsCount,
  setLoading,
  setError,
  setAllContactsCount,
  setAllProspectsCount,
  setLoadingDashboardData,
  setReturningLeads,
  setAll,
  setEvaluations,
  setMyEvaluations,
  setInitialDate,
  setFinalDate,
  setFilters,
  setUserFilters,
  setReports,
  setMarkers,
  setSelectedMarker,
  setCities,
  setMuncipalities,
  setProjectStatus,
  setCoordinates,
  setCityValue,
  setMunciValue,
  setProjectValue,
  setOwnerValue,
  setDateInfo,
  setDateFilterType,
  setCurrentMunicipality,
  setLimit,
  setProspectsCount,
  setNextProspectId,
  setSortedBy,
  setSortingType,
  setShownProspects,
  setNeedToRefresh,
  setNewProspects,
  setIsRestricted,
  setUniqueEvaluations,
  setProspects,
  clearState,
  setActionLoading,
  setFirstFetchLoading,
  setFetchMoreLoading,
  setLoadingLeads,

  setLastFourWeeksStrings,
  setMyContactsLineData,
  setAllLeadsLineData,
} = leadsSlice.actions;

export default leadsSlice.reducer;
