import AuthGuard from "guards/AuthGuard";
import CarteProspection from "./CarteProspection";
import { authRoles } from "../../guards/authRoles";

const carteProspectionRoute = [
  {
    path: "/mes-biens",
    element: <AuthGuard component={<CarteProspection />} />,
    auth: authRoles.Essentiel,
  },
];

export default carteProspectionRoute;
