import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import editNote from "../../utils/editNote";

const EditNoteModal = ({
  show,
  setShow,
  setLoading,
  setNotes,
  notesList,
  note,
  notesId,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(note?.text);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setText(note?.text);
  }, [note]);

  const editNoteFunc = async () => {
    const newNote = {
      text: text,
      date: new Date(),
    };

    const response = await editNote(newNote, note?.id, notesId, setLoading);
    if (!response?.error) {
      const noteIndex = notesList.findIndex((item) => item.id === note?.id);
      const newNotes = [...notesList];
      newNotes[noteIndex].text = newNote.text;
      newNotes[noteIndex].date = newNote.date;
      await setNotes(notesList);
      setShow(false);
    } else {
      setShow(false);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      style={{ maxWidth: "100vw" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Leads.115")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="w-100 "
          style={{ height: "100px" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={editNoteFunc}>
          {t("Leads.116")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditNoteModal;
