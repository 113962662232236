import { useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { useDispatch, useSelector } from "react-redux";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";

const AdditionalPropertyInformationStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { nextStep, previousStep } = useWizard();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        updateFormalizedData({
          chambres: parseFloat(data?.chambres),
          bains: parseFloat(data?.bains),
          garages: parseFloat(data?.garages),
          stationnement: parseFloat(data?.stationnement),
        })
      );
      nextStep();
    },
    [dispatch, nextStep]
  );

  const validateNonNegative = useCallback((value) => {
    if (parseFloat(value) < 0) {
      return false; // Return false if the value is negative
    }
    return true; // Return true if the value is non-negative
  }, []);

  const numberOptions = Array.from({ length: 11 }, (_, index) => index);
  const numberOptions2 = Array.from({ length: 6 }, (_, index) => index);

  useEffect(() => {
    setValue(
      "chambres",
      formalizedData?.chambres ? formalizedData?.chambres : 1
    );
    setValue("bains", formalizedData?.bains ? formalizedData?.bains : 1);
    setValue("garages", formalizedData?.garages ? formalizedData?.garages : 1);
    setValue(
      "stationnement",
      formalizedData?.stationnement ? formalizedData?.stationnement : 1
    );
  }, [
    setValue,
    formalizedData?.chambres,
    formalizedData?.bains,
    formalizedData?.garages,
    formalizedData?.stationnement,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="bg-white rounded-xl p-4">
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
          {t("Estimate-new.46")}
        </h2>
        <div className="row align-items-center">
          <div className="col">
            <ul
              className="max-width-274 nav flex-column flex-nowrap"
              style={{ rowGap: "1rem" }}
            >
              <li>
                <label
                  htmlFor="number-of-rooms"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.47")}
                </label>
                <Controller
                  name="chambres" // Name for your select field
                  control={control}
                  rules={{
                    required: true,
                    validate: validateNonNegative,
                    min: 0,
                    max: 10,
                  }} // Add rules if needed
                  render={({ field }) => (
                    <select
                      style={{ height: "50px" }}
                      placeholder={t("Estimate-new.51")}
                      className="form-control py-2 font-poppins text-14"
                      {...field}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.chambres?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.chambres?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
                {errors.chambres?.type === "max" && (
                  <span className="text-danger"> {t("Estimate-new.129")} </span>
                )}
              </li>
              <li>
                <label
                  htmlFor="number-of-bathrooms"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.48")}
                </label>
                <Controller
                  name="bains" // Name for your select field
                  control={control}
                  // defaultValue={""}
                  rules={{
                    required: true,
                    validate: validateNonNegative,
                    min: 0,
                    max: 10,
                  }} // Add rules if needed
                  render={({ field }) => (
                    <select
                      style={{ height: "50px" }}
                      placeholder={t("Estimate-new.52")}
                      className="form-control py-2 font-poppins text-14"
                      {...field}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.bains?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.bains?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
                {errors.bains?.type === "max" && (
                  <span className="text-danger"> {t("Estimate-new.129")} </span>
                )}
              </li>
              <li>
                <label
                  htmlFor="number-of-garages"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.49")}
                </label>
                <Controller
                  name="garages" // Name for your select field
                  control={control}
                  rules={{
                    required: true,
                    validate: validateNonNegative,
                    min: 0,
                    max: 5,
                  }} // Add rules if needed
                  render={({ field }) => (
                    <select
                      style={{ height: "50px" }}
                      placeholder={t("Estimate-new.51")}
                      className="form-control py-2 font-poppins text-14"
                      {...field}
                      //value={field.value}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions2.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.garages?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.garages?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
              </li>
              <li>
                <label
                  htmlFor="parking-spaces"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.50")}
                </label>
                <Controller
                  name="stationnement" // Name for your select field
                  control={control}
                  rules={{
                    required: true,
                    validate: validateNonNegative,
                    min: 0,
                    max: 5,
                  }} // Add rules if needed
                  render={({ field }) => (
                    <select
                      style={{ height: "50px" }}
                      placeholder={t("Estimate-new.51")}
                      className="form-control py-2 font-poppins text-14"
                      {...field}
                      //value={field.value}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions2.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.stationnement?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.stationnement?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
                {errors.stationnement?.type === "max" && (
                  <span className="text-danger"> {t("Estimate-new.130")} </span>
                )}
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-7 d-none d-md-block">
            <div className="step-image-box-2 mx-auto">
              <img
                className="d-block h-100 w-100"
                src="/assets/images/steps/select-house-rafiki-2.png"
                alt="..."
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={previousStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default AdditionalPropertyInformationStep;
