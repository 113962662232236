import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setSubError } from "../subscriptionSlice";
import { listCards } from "./listCards";

export function detachCard(card, cb) {
  return (dispatch, getState) => {
    const removeCard = httpsCallable(functions, "detachCard");
    removeCard({ card })
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          const state = getState();
          const selectedCardId = state.subscription.selectedCardId;
          dispatch(listCards(selectedCardId));
          cb(res);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setSubError(err));
      });
  };
}
