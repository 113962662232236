import { extractMunicipalitiesFromFilterSummary } from "../../../../pages/prospect/utils/extractMunicipalitiesFromFilterSummary";
import { setMunciValue, setUserFilters } from "../leadsSlice";

export const updateFilterSummaryAction = (
  municipality,
  city,
  setSelectedOptions
) => {
  return async (dispatch, getState) => {
    const { userFilters } = getState().leads;
    console.log(
      "updateFilterSummaryAction_municipality_city: ",
      municipality,
      city
    );
    let newFilterSummary = { ...userFilters.filterSummary };
    if (municipality && city && newFilterSummary) {
      if (
        newFilterSummary &&
        newFilterSummary[city?.value] &&
        newFilterSummary[city?.value].length > 0
      ) {
        const idx = newFilterSummary[city?.value].findIndex(
          (v) => v.value === municipality.value
        );
        if (idx >= 0) {
          let updatedFilterSummary = {
            ...newFilterSummary,
            [city.value]: newFilterSummary[city.value].filter(
              (item) => item.value !== municipality.value
            ),
          };
          newFilterSummary = updatedFilterSummary;
          if (newFilterSummary[city?.value].length === 0) {
            delete newFilterSummary[city?.value];
          }
        } else {
          let updatedFilterSummary = {
            ...newFilterSummary,
            [city.value]: [...newFilterSummary[city.value], municipality],
          };
          newFilterSummary = updatedFilterSummary;
        }
      } else {
        const filterSummary = {
          ...newFilterSummary,
          [city.value]: [municipality],
        };
        newFilterSummary = filterSummary;
      }

      const selectedMunicipalites =
        extractMunicipalitiesFromFilterSummary(newFilterSummary);
      console.log("selectedMunicipalites: ", selectedMunicipalites);
      setSelectedOptions(selectedMunicipalites);
      dispatch(setMunciValue(selectedMunicipalites));
      const selectedMunicipalitesString = selectedMunicipalites.join(",");
      const newUserFilters = {
        ...userFilters,
        municipalite: selectedMunicipalitesString,
        filterSummary: newFilterSummary,
      };
      console.log("newUserFilters: ", newUserFilters);
      dispatch(setUserFilters(newUserFilters));
    }
  };
};
