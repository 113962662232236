const getArticles = (t) => {
  const articles = [
    {
      title: `${t("Report_res.articles_titles.1")}`,
      image: "https://pro.ziaway.ca/images/Exp%C3%A9rience%20client.jpg",
      link: "https://pro.ziaway.ca/blogs/post/Experience-client-et-le-numerique-dans-l-immobilier",
    },

    {
      title: `${t("Report_res.articles_titles.2")}`,
      image:
        "https://pro.ziaway.ca/images/g50cdfda92a0f8bf0a170cb1da6c1f12833b315d08b7f7e86e2267046761705a90c2f9fcfaaf333d22e2072965b672f8c346285ec694a20c8907f1bc9b9e166a8_1280.jpg",
      link: "https://pro.ziaway.ca/blogs/post/la-visite-libre-et-les-mandats-vendeurs",
    },
    {
      title: `${t("Report_res.articles_titles.3")}`,
      image:
        "https://images.unsplash.com/photo-1427751840561-9852520f8ce8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw0NTc5N3wwfDF8c2VhcmNofDE1fHxyZXBvcnR8ZW58MHx8fHwxNjIyNzYwMTI5&ixlib=rb-1.2.1&q=80&w=1080",
      link: "https://pro.ziaway.ca/blogs/post/Le-rapport-de-suivi-vendeurs",
    },
    {
      title: `${t("Report_res.articles_titles.4")}`,
      image:
        "https://pro.ziaway.ca/images/11062b_4ff4e8758f9b420794b9d985a802d9f0_mv2.jpg",
      link: "https://pro.ziaway.ca/blogs/post/La-technologie-au-service-des-courtiers",
    },
  ];

  return articles;
};

export default getArticles;
