import { createBrowserRouter } from "react-router-dom";
import SessionsRoutes from "pages/sessions/sessionsRoutes";
import dashboardRoutes from "pages/dashboard/dashboardRoutes";
import { PublicFaqRoutes } from "pages/faq/faqRoutes";
import monCompteRoutes from "pages/monCompte/monCompteRoutes";
import profileCreationRoute from "pages/profileCreation/profileCreationRoute";
import abonnementRoutes from "pages/abonnement/abonnementRoutes";
import prospectsPageRoutes from "pages/prospect/ProspectRoutes";
import carteProspectionRoute from "pages/carteProspection/carteProspectionRoute";
import inboxRoutes from "pages/rapportEvaluation/rapportRoutes";
import evaluationRoute from "pages/evaluationDeBien/evaluationRoutes";
// import retainRoute from "pages/retain/retainRoutes";
import mesVentesRoutes from "pages/mesVentes/mesVentesRoutes";
import { Navigate } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/dashboard" replace={true} />,
  },
  ...PublicFaqRoutes,
  ...SessionsRoutes,
  ...dashboardRoutes,
  ...monCompteRoutes,
  ...profileCreationRoute,
  ...abonnementRoutes,
  ...prospectsPageRoutes,
  ...carteProspectionRoute,
  ...inboxRoutes,
  ...evaluationRoute,
  ...mesVentesRoutes,
  // ...retainRoute,
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
]);

export default router;
