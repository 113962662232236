import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { deleteAccount } from "../../../redux/slices/subscription/actions/deleteAccount";
import { useDispatch } from "react-redux";

const DeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  currentAccount,
  password,
  setPassword,
  loading,
  setLoading,
}) => {
  const distpatch = useDispatch();
  const { t } = useTranslation();
  const getDate = useCallback((date) => {
    const offset = new Date().getTimezoneOffset();
    const d = new Date(date * 1000 + offset * 60000);
    return d.toISOString().slice(0, 10);
  }, []);

  const handleDelete = useCallback(() => {
    distpatch(
      deleteAccount(
        password,
        setLoading,
        setShowDeleteModal,
        showDeleteModal,
        setPassword,
        t
      )
    );
  }, [
    distpatch,
    password,
    setLoading,
    setShowDeleteModal,
    showDeleteModal,
    setPassword,
  ]);

  return (
    <Modal
      className="suspended-modal"
      show={showDeleteModal}
      onHide={() => {
        setShowDeleteModal(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="suspended-modal-body">
          <p> {t("profile.subscription.current_plan.13")}</p>
          <p className="norml-txt">
            {currentAccount?.subscriptionCurrentPeriodEnd &&
              t("NProfile.88", {
                deletionDate: getDate(
                  currentAccount.subscriptionCurrentPeriodEnd
                ),
              })}
          </p>
          <p className="norml-txt">{t("NProfile.89")}</p>
          <div className="sub-container">
            <div className="white-input-container">
              <label> {t("profile.subscription.current_plan.12")}</label>
              <input
                className="card-inputs"
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="suspended-modal-footer">
              <button
                className="non-filled-bg-btn"
                onClick={() => {
                  setLoading(false);
                  setPassword("");
                  setShowDeleteModal(!showDeleteModal);
                }}
                disabled={loading}
                style={{
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {t("profile.subscription.current_plan.9")}
              </button>
              <button
                className="bg-filled-btn"
                onClick={handleDelete}
                disabled={loading}
                style={{
                  opacity: loading ? 0.5 : 1,
                }}
              >
                {t("profile.subscription.current_plan.10")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
