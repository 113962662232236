import Abonnement from "./Abonnement";
import AuthGuard from "../../guards/AuthGuard";

const abonnementRoutes = [
  {
    path: "/abonnement",
    element: <AuthGuard component={<Abonnement />} />,
  },
];

export default abonnementRoutes;
