import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

const ActionButton = ({ text, onClick, icon, cn }) => {
  const {
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    loading: mainLoading,
  } = useSelector((state) => state.leads);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(
      actionLoading ||
        fetchMoreLoading ||
        firstFetchLoading ||
        loadingLeads ||
        mainLoading
    );
  }, [
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    mainLoading,
  ]);

  return (
    <>
      <button
        className={`newLeads_prospects_list_item_actionType d-flex justify-content-center align-items-center ${cn} ${
          loading && "newLeads_prospects_list_item_actionType_disabled"
        }`}
        onClick={onClick}
        disabled={loading}
      >
        {icon && (
          <img
            src={`assets/icons/${icon}.svg`}
            alt="icon"
            style={{
              maxWidth: "16px",
            }}
          />
        )}
        <span>{text}</span>
      </button>
    </>
  );
};

export default ActionButton;
