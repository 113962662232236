import { useCallback } from "react";
import swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PushToZoho } from "../../../utils/pushToZoho";
import { db } from "../../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { getUserData } from "../../../redux/slices/authentication/actions/getUserData";

const PreviewYourProfileStep = ({
  logo,
  userPhoto,
  regulatory,
  information,
  messages,
  handleStepPrev,
}) => {
  const { userData } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateDetails = useCallback(() => {
    const json_data = {
      info: {
        data: [
          {
            Agency: regulatory?.agency || "",

            Authorized_field_of_practice: regulatory?.practice?.value || "",

            Business_adress: regulatory?.BusinessAddress.value || "",

            E_mail: information?.officialEmail || "",

            Email: userData.email || "",

            Licence_status: regulatory?.status?.value || "",

            First_and_last_name: information?.officialName || "",

            Phone: information?.officialPhoneNumber || "",

            Licence_Number: userData?.licenseId || "",

            Last_Name: userData?.displayName || "",

            Mail_message: messages?.emailText || "",

            SMS_Message: messages?.smsText || "",

            Broker_Photo: userPhoto || "",

            Agency_Logo: logo || "",

            Cellulaire: userData.phoneNumber || "",

            Facebook: information?.facebook || "",

            Linkedin: information?.linkedIn || "",

            Twitter_Address: information?.twitter || "",

            Website: information?.website || "",

            Instagram: information?.insta || "",
          },
        ],

        duplicate_check_fields: ["Licence_Number"],
      },

      vmodule: "Contacts/upsert",
    };

    updateDoc(doc(db, "users", userData.id), {
      regulatory: regulatory,
      officialInformation: information,
      messages: messages,
      image: userPhoto,
      logo: logo,
      creationProcessFinished: true,
    })
      .then(async (res) => {
        await dispatch(getUserData(userData.id));

        await Object.entries(json_data.info).forEach((sub) => {
          console.log(sub);
          if (!sub[1]) {
            delete json_data.info[sub[0]];
          }
        });

        if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
          await PushToZoho(json_data)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
        if (userData?.licenceVerified) {
          swal.fire(t("swal.22"), t("swal.23"), "success");
        } else {
          swal.fire(t("Sign_up.113"), t("Sign_up.114"), "info");
        }
      })
      .catch((err) => {
        console.log(err);
        swal.fire(t("swal.1"), t("swal.2"), "error");
      });
  }, [
    dispatch,
    information,
    logo,
    messages,
    regulatory,
    t,
    userData,
    userPhoto,
  ]);

  return (
    <div>
      <h4 className="mb-4 text-violet text-18">{t("Sign_up.72")}</h4>
      <div className="d-flex flex-column flex-lg-row">
        <div className="bg-white rounded-xl shadow text-center p-4 col-lg-5">
          {logo && (
            <div className="b-logo shadow bg-white rounded-lg mb-1">
              <img
                className="d-block h-100 w-100"
                src={logo ? logo : "/assets/packages/b-logo.png"}
                alt="..."
              />
            </div>
          )}

          <div className="user-image bg-gray-100 mx-auto rounded-circle mb-3">
            <img
              className="d-block h-100 w-100 rounded-circle"
              src={userPhoto ? userPhoto : "/assets/packages/placeholder.png"}
              alt="...."
            />
          </div>
          <h5 className="mb-4 font-weight-bold text-20 text-violet text-capitalize">
            {information?.officialName}
          </h5>

          <div
            className="d-flex align-items-center justify-content-center mb-4"
            style={{ gap: "0.5rem" }}
          >
            <button type="button" className="btn-pack py-2 px-3">
              {userData?.email}
            </button>
          </div>

          <div className="bg-gray-100 p-4 rounded-xl">
            <h6 className="text-gray-600 text-13 font-weight-bolder">
              {information?.officialPhoneNumber}
            </h6>
            <p className="mb-0 text-gray-600 text-12 font-weight-normal">
              {regulatory?.BusinessAddress?.value}
            </p>
          </div>
        </div>
        <div className="bg-gray-100 text-black-50 rounded-xl-r p-4 my-lg-4 flex-fill">
          <h6 className="text-gray-600 text-13 font-weight-bolder">
            {t("Sign_up.73")}
          </h6>
          <p>{messages?.emailText}</p>
          <h6 className="text-gray-600 text-13 font-weight-bolder">
            {t("Sign_up.74")}
          </h6>
          <p>{messages?.smsText}</p>
        </div>
      </div>
      <div className=" pb-4 text-right mt-5 ">
        <button
          type="button"
          className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
          onClick={handleStepPrev}
        >
          {t("Sign_up.59")}
        </button>
        <button
          type="button"
          className="btn btn-lg btn-primary rounded-xl px-4"
          onClick={updateDetails}
        >
          {t("Sign_up.60")}
        </button>
      </div>
    </div>
  );
};

export default PreviewYourProfileStep;
