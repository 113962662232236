import { useState } from "react";
import Contact from "./contact";
import Notes from "./notes";
import Project from "./project";
import PropertyInfo from "./propertyInfo";

import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NewLeadStatus from "./NewLeadStatus";
import { useSelector } from "react-redux";
import Spinner from "../../../../components/spinner";

const ProspecCardTabs = ({
  prospect,
  updateEvaluation,
  profile,
  setLoading,
  setLiveProspect,
  refreshUserData,
  setSelectedProspect,
}) => {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.leads);

  const checkBookmarks = (id) => {
    if (profile.bookmarks?.some((person) => person?.id === id)) {
      return true;
    } else {
      return false;
    }
  };

  const [currentTab, setCurrentTab] = useState("Info");

  const profileInformation = {
    name: prospect?.displayName,
    phone: prospect?.phoneNumber,
    email: prospect?.userEmail,
    address: prospect?.location?.value,
    type: prospect?.type,
    call: "tbd",
  };

  return (
    <>
      {loading && <Spinner />}
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="Info"
        onSelect={(key) => {
          setCurrentTab(key);
        }}
      >
        <div className="">
          <div className="">
            <ul
              className="nav align-items-center p-3 p-md-0"
              style={{ rowGap: "1rem" }}
            >
              <li className="mt-5 d-flex justify-content-between flex-fill pr-2">
                <Nav
                  variant="pills"
                  className="text-uppercase w-100 overflow-auto text-nowrap flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-start"
                >
                  <Nav.Item className="newLeads_card_tab_title">
                    <Nav.Link eventKey="Info"> {t("Leads.93")}</Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="newLeads_card_tab_title">
                    <Nav.Link eventKey="Project">{t("Leads.94")}</Nav.Link>
                  </Nav.Item>
                  {checkBookmarks(prospect?.id) === true && (
                    <Nav.Item className="newLeads_card_tab_title">
                      <Nav.Link eventKey="Notes">{t("Leads.95")}</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="newLeads_card_tab_title">
                    <Nav.Link eventKey="Contact">{t("Leads.96")}</Nav.Link>
                  </Nav.Item>

                  {prospect?.type === "contact" &&
                    checkBookmarks(prospect?.id) === true && (
                      <Nav.Item className="newLeads_card_tab_title">
                        <Nav.Link eventKey="Status">{t("Leads.104")}</Nav.Link>
                      </Nav.Item>
                    )}
                </Nav>

                <img
                  src="assets/icons/close.svg"
                  onClick={() => setSelectedProspect(null)}
                  alt="icon"
                  style={{
                    maxWidth: "20px",
                    cursor: "pointer",
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="overflow-auto">
            <Tab.Content className="h-100">
              <Tab.Pane eventKey="Info">
                {currentTab === "Info" && (
                  <ul className="nav flex-column gy-2">
                    <PropertyInfo
                      prospect={prospect}
                      accepted={
                        prospect?.type === "contact" &&
                        prospect?.broker &&
                        prospect?.broker[0]?.brokerId === profile.userId
                      }
                    />
                  </ul>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="Project">
                {currentTab === "Project" && <Project prospect={prospect} />}
              </Tab.Pane>
              {checkBookmarks(prospect?.id) === true && (
                <Tab.Pane className="h-100" eventKey="Notes">
                  {currentTab === "Notes" && (
                    <Notes prospect={prospect} setLoading={setLoading} />
                  )}
                </Tab.Pane>
              )}
              <Tab.Pane className="h-100" eventKey="Contact">
                {currentTab === "Contact" && (
                  <Contact
                    contact={profileInformation}
                    prospect={prospect}
                    accepted={
                      prospect?.type === "contact" &&
                      prospect?.broker &&
                      prospect?.broker[0]?.brokerId === profile.userId
                    }
                    profile={profile}
                    refreshUserData={refreshUserData}
                    updateEvaluation={updateEvaluation}
                    setLiveProspect={setLiveProspect}
                    setLoading={setLoading}
                  />
                )}
              </Tab.Pane>
              {prospect?.type === "contact" &&
                checkBookmarks(prospect?.id) === true && (
                  <Tab.Pane className="h-100" eventKey="Status">
                    {currentTab === "Status" && (
                      <NewLeadStatus
                        progress={
                          prospect?.broker &&
                          prospect?.broker[0]?.projectProgress
                        }
                        id={prospect?.id}
                        setLoading={setLoading}
                        updateEvaluation={updateEvaluation}
                        setLiveProspect={setLiveProspect}
                        leadEmail={prospect?.userEmail}
                        brokerLicenceId={profile?.licenseId}
                        profile={profile}
                      />
                    )}
                  </Tab.Pane>
                )}
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default ProspecCardTabs;
