import { useEffect, useState, useCallback, useRef } from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import swal from "sweetalert2";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import AdressField from "../../../components/forms/AddressField";
import Suggestions from "../../../components/forms/AddressField/Suggestions";
import getPlaceDetail from "../../../components/forms/AddressField/utils/getPlaceDetail";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import placeholder from "../placeholder.png";
import { PushToZoho } from "../../../utils/pushToZoho";
import DropZone from "../components/DropZone";
import Spinner from "../../../components/spinner";
import ChangeDialog from "../components/ChangeDialog";
import { storage, db } from "../../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateUserLogo } from "../../../redux/slices/authentication/actions/updateUserLogo";
import { updateRegulatoryDetails } from "../../../redux/slices/authentication/actions/updateRegulatoryDetails";

const Regulatory = ({ t }) => {
  const dispatch = useDispatch();
  const adressInputRef = useRef(null);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 1000,
  });

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [place, setPlace] = useState("");

  const { userData } = useSelector((state) => state.authentication);

  const [image, setImage] = useState();
  const [imageUploading, setImageUploading] = useState(false);
  const [editRegulatoryInfo, setEditRegulatoryInfo] = useState(false);

  const colourStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#EEEEEE" : "#fff",
      border: "1px solid #dddce1",
      borderRadius: "10px",
      height: "44px",
    }),
  };

  function placeToAddress(place) {
    var address = {};
    if (place.address_components) {
      address.value = place.formatted_address;
      address.latlng = place.geometry && {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      place.address_components.forEach(function (c) {
        switch (c.types[0]) {
          case "street_number":
            address.StreetNumber = c;
            break;
          case "route":
            address.StreetName = c;
            break;
          case "neighborhood":
          case "locality": // North Hollywood or Los Angeles?
            address.city = c.long_name;
            break;
          case "administrative_area_level_1": //  Note some countries don't have states
            address.state = c;
            break;
          case "postal_code":
            address.postcode = c.short_name;
            break;
          case "country":
            address.Country = c;
            break;
          /*
           *   . . .
           */
        }
      });
    }
    return address;
  }

  const updateZoho = useCallback(
    (values) => {
      const json_data = {
        info: {
          data: [
            {
              Agency: values?.agency || "",

              Authorized_field_of_practice: values?.practice?.value || "",

              Business_adress: values?.BusinessAddress.value || "",

              E_mail: userData?.officialInformation?.officialEmail || "",

              Email: userData?.email || "",

              Licence_status: values?.status?.value || "",

              First_and_last_name:
                userData?.officialInformation?.officialName || "",

              Phone: userData?.officialInformation?.officialPhoneNumber || "",

              Licence_Number: userData?.licenseId || "",

              Last_Name: userData?.displayName || "",

              Mail_message: userData?.messages?.emailText || "",

              SMS_Message: userData?.messages?.smsText || "",

              Broker_Photo: userData?.image || "",

              Agency_Logo: userData?.logo || "",

              Cellulaire: userData?.phoneNumber || "",

              Facebook: userData?.officialInformation?.facebook || "",

              Linkedin: userData?.officialInformation?.linkedIn || "",

              Twitter_Address: userData?.officialInformation?.twitter || "",

              Website: userData?.officialInformation?.website || "",

              Instagram: userData?.officialInformation?.insta || "",
            },
          ],

          duplicate_check_fields: ["Licence_Number"],
        },

        vmodule: "Contacts/upsert",
      };

      if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
        PushToZoho(json_data)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    },
    [userData]
  );

  const addimage = useCallback(async () => {
    if (image) {
      if (
        image.type === "image/png" ||
        image.type === "image/jpeg" ||
        image.type === "image/jpg"
      ) {
        if (image.size > 5000000) {
          setImageUploading(false);
          swal.fire(t("profile.25"), t("profile.28"), "error");
        } else {
          setImageUploading(true);
          const fileRef = ref(storage, `images/${image.name}`);
          uploadBytes(fileRef, image).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              updateDoc(doc(db, "users", userData.id), {
                logo: url,
              })
                .then((res) => {
                  dispatch(updateUserLogo(url));

                  const json_data = {
                    info: {
                      data: [
                        {
                          Agency: userData?.regulatory?.agency || "",

                          Business_adress:
                            userData?.regulatory?.BusinessAddress.value || "",

                          Authorized_field_of_practice:
                            userData?.regulatory?.practice?.value || "",

                          E_mail:
                            userData?.officialInformation?.officialEmail || "",

                          Email: userData?.userEmail || "",

                          Licence_status:
                            userData?.regulatory?.status?.value || "",

                          First_and_last_name:
                            userData?.officialInformation?.officialName || "",

                          Phone:
                            userData?.officialInformation
                              ?.officialPhoneNumber || "",

                          Licence_Number: userData?.licenseId || "",

                          Last_Name: userData?.displayName || "",

                          Mail_message: userData?.messages?.emailText || "",

                          SMS_Message: userData?.messages?.smsText || "",

                          Broker_Photo: userData?.image || "",

                          Agency_Logo: url || "",

                          Cellulaire: userData?.phoneNumber || "",

                          Facebook:
                            userData?.officialInformation?.facebook || "",

                          Linkedin:
                            userData?.officialInformation?.linkedIn || "",

                          Twitter_Address:
                            userData?.officialInformation?.twitter || "",

                          Website: userData?.officialInformation?.website || "",

                          Instagram: userData?.officialInformation?.insta || "",
                        },
                      ],

                      duplicate_check_fields: ["Licence_Number"],
                    },

                    vmodule: "Contacts/upsert",
                  };
                  Object.entries(json_data.info).forEach((sub) => {
                    if (!sub[1]) {
                      delete json_data.info[sub[0]];
                    }
                  });

                  if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
                    PushToZoho(json_data)
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }

                  setImageUploading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setImageUploading(false);
                });
            });
          });
        }
      } else {
        setImageUploading(false);
        swal.fire(t("profile.25"), t("profile.27"), "error");
      }
    }
  }, [image, userData, dispatch, t]);

  useEffect(() => {
    if (image) {
      addimage();
    }
  }, [image]);

  const updateDetails = useCallback(
    (data) => {
      updateDoc(doc(db, "users", userData.id), {
        regulatory: data,
      })
        .then((res) => {
          dispatch(updateRegulatoryDetails(data));
          swal.fire(t("profile.23"), t("profile.24"), "success");
        })
        .catch((err) => {
          swal.fire(t("profile.25"), t("profile.26"), "error");
          console.log("error", err);
        });
    },
    [dispatch, userData, t]
  );

  const statusOptions = [
    { value: "Valid", label: t("NProfile.15") },
    { value: "Suspended", label: t("NProfile.16") },
    { value: "Revoked", label: t("NProfile.17") },
  ];

  const practiceOptions = [
    { value: "Residential", label: t("NProfile.18") },
    { value: "Commercial", label: t("NProfile.19") },
    { value: "All areas of practice", label: t("NProfile.20") },
  ];

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
  }, []);

  const resetAddress = useCallback((value, setFieldValue) => {
    setFieldValue("BusinessAddress", value);
  }, []);

  const clearErrors = useCallback(
    (setFieldError, setFieldValue, BusinessAddress) => {
      setFieldValue("BusinessAddress", BusinessAddress);
      setFieldError("BusinessAddress", "");
    },
    []
  );

  return (
    <div className="profile_personalInfo">
      {imageUploading && <Spinner />}
      <div className="profile_personalInfo_section row">
        <div className="col-12">
          <div className="row">
            <div>
              <h4 className="profile_section_title text-capitalize">
                {t("NProfile.13")}
              </h4>
              <p className="profile_section_desc">
                {t("profile.personalInfo.5")}
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  license: userData?.licenseId,
                  BusinessAddress: userData?.regulatory?.BusinessAddress,
                  status: userData?.regulatory?.status,
                  practice: userData?.regulatory?.practice,
                  agency: userData?.regulatory?.agency,
                }}
                enableReinitialize={true}
                validate={(values) => {
                  const errors = {};

                  if (!values.license) {
                    errors.license = t("profile.29");
                  }
                  if (!values.BusinessAddress) {
                    errors.BusinessAddress = t("profile.30");
                  }
                  if (!values.status) {
                    errors.status = t("profile.29");
                  }
                  if (!values.practice) {
                    errors.practice = t("profile.29");
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  updateDetails(values);
                  updateZoho(values);
                  setEditRegulatoryInfo(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldError,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="py-3 d-flex flex-column">
                        <div>
                          <div className="mt-2 mb-4 mr-3 profile_img_container">
                            <DropZone
                              onDrop={onDrop}
                              isDisabled={!editRegulatoryInfo}
                              imageUploading={imageUploading}
                            />

                            <div className="profile_img_container_inner">
                              {userData?.logo ? (
                                <a
                                  href={userData?.logo}
                                  download
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    className="profile_img"
                                    src={userData?.logo}
                                    alt="..."
                                  />
                                </a>
                              ) : (
                                <img
                                  className="profile_img"
                                  src={placeholder}
                                  alt="..."
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row row-cols-1 row-cols-md-2">
                            <div className="mb-3 px-3 ">
                              <div>
                                <label
                                  className="profile_section_label"
                                  htmlFor="validationCustom202"
                                >
                                  {t("NProfile.80")}
                                </label>
                                <input
                                  type="text"
                                  className="form-control profile_input"
                                  name="license"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.license}
                                  disabled
                                />
                                {errors.license && touched.license && (
                                  <div className="text-danger mt-1 ml-2">
                                    {errors.license}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 px-3 ">
                              <div>
                                <label
                                  className="profile_section_label"
                                  htmlFor="validationCustom202"
                                >
                                  {t("NProfile.85")}
                                </label>
                                <input
                                  type="text"
                                  disabled={!editRegulatoryInfo}
                                  className="form-control profile_input"
                                  name="agency"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.agency}
                                />
                              </div>
                            </div>
                            <div className="mb-3 px-3 ">
                              <div>
                                <label
                                  className="profile_section_label"
                                  htmlFor="validationCustom202"
                                >
                                  {t("NProfile.81")}
                                </label>

                                <Select
                                  placeholder="Select Field"
                                  options={practiceOptions}
                                  onChange={(value) =>
                                    setFieldValue("practice", value)
                                  }
                                  value={values.practice}
                                  onBlur={handleBlur}
                                  styles={colourStyles}
                                  isDisabled={!editRegulatoryInfo}
                                />
                                {errors.practice && touched.practice && (
                                  <div className="text-danger mt-1 ml-2">
                                    {errors.practice}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 px-3">
                              <label
                                className="profile_section_label"
                                htmlFor="validationCustom202"
                              >
                                {t("NProfile.82")}
                              </label>

                              <Select
                                placeholder="Select Status"
                                options={statusOptions}
                                onChange={(value) =>
                                  setFieldValue("status", value)
                                }
                                value={values.status}
                                onBlur={handleBlur}
                                styles={colourStyles}
                                isDisabled={!editRegulatoryInfo}
                              />
                              {errors.status && touched.status && (
                                <div className="text-danger mt-1 ml-2">
                                  {errors.status}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="mb-3 px-3 d-flex flex-column col-12">
                              <label className="profile_section_label">
                                {t("NProfile.83")}
                              </label>

                              <div className="position-relative">
                                {showSuggestions &&
                                  placePredictions?.length > 0 && (
                                    <Suggestions
                                      placePredictions={placePredictions}
                                      getPlaceDetail={getPlaceDetail}
                                      placesService={placesService}
                                      setValue={setPlace}
                                      placeToAddress={placeToAddress}
                                      customStyle={{
                                        top: "35px",
                                        left: "0",
                                      }}
                                      fieldName="BusinessAddress"
                                      setFieldValue={setFieldValue}
                                      setShowPredictions={setShowSuggestions}
                                      adressInputRef={adressInputRef}
                                    />
                                  )}
                                {isPlacePredictionsLoading && <Spinner />}
                                <AdressField
                                  cn="profile_input"
                                  customStyle={{
                                    backgroundImage:
                                      "url(/assets/icons/pin.png)",
                                    backgroundPosition: "left",
                                    paddingLeft: "30px",
                                    backgroundSize: "20px",
                                    backgroundRepeat: "no-repeat",
                                    width: "100%",
                                    backgroundColor: editRegulatoryInfo
                                      ? "#fff"
                                      : "#EEEEEE",
                                  }}
                                  disabled={!editRegulatoryInfo}
                                  placeholder=" "
                                  getPlacePredictions={getPlacePredictions}
                                  isPlacePredictionsLoading={
                                    isPlacePredictionsLoading
                                  }
                                  setShowPredictions={setShowSuggestions}
                                  value={values?.BusinessAddress?.value}
                                  setValue={setPlace}
                                  adressInputRef={adressInputRef}
                                  setFieldValue={setFieldValue}
                                  resetAddress={resetAddress}
                                />
                              </div>

                              {errors.BusinessAddress &&
                                touched.BusinessAddress && (
                                  <div className="text-danger mt-1 ml-2">
                                    {errors.BusinessAddress}
                                  </div>
                                )}
                            </div>
                          </div>

                          {!editRegulatoryInfo ? (
                            <div className="col-12 d-flex justify-content-end">
                              <Button
                                className="profile_submit_btn"
                                type="button"
                                onClick={() => setEditRegulatoryInfo(true)}
                                disabled={isSubmitting}
                              >
                                {t("NProfile.12")}
                              </Button>
                            </div>
                          ) : (
                            <div className="col-12">
                              <ChangeDialog
                                isSubmitting={isSubmitting || imageUploading}
                                cancelFunc={() => {
                                  clearErrors(
                                    setFieldError,
                                    setFieldValue,
                                    userData?.regulatory?.BusinessAddress
                                  );
                                  setEditRegulatoryInfo(false);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regulatory;
