import { setUniqueEvaluations, setMarkers } from "../leadsSlice";

export const filterDuplicatedEvaluationsAction = () => {
  return async (dispatch, getState) => {
    const { evaluations, myEvaluations } = getState().leads;
    const combined = [...evaluations, ...myEvaluations];

    const uniqueEvaluations = Array.from(
      new Set(combined.map((evaluation) => evaluation.id))
    ).map((id) => combined.find((evaluation) => evaluation.id === id));
    dispatch(setUniqueEvaluations(uniqueEvaluations));
    dispatch(setMarkers(uniqueEvaluations));
  };
};
