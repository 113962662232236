import { useState } from "react";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import ChangeDialog from "../components/ChangeDialog";
import { resetPassword } from "../../../redux/slices/authentication/actions/resetPassword";
import { useDispatch } from "react-redux";
import Spinner from "../../../components/spinner";

const ResetPassword = ({ t }) => {
  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch();

  const ValidationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .min(8, t("Sign_up.106"))
      .required(t("Sign_up.107")),
    newPassword: yup
      .string()
      .min(8, t("Sign_up.106"))
      .required(t("Sign_up.107")),
    changepassword: yup
      .string()
      .min(8, t("Sign_up.106"))
      .required(t("Sign_up.107"))
      .oneOf([yup.ref("newPassword")], t("Sign_up.109")),
  });

  return (
    <div className="profile_section">
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          changepassword: "",
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          dispatch(
            resetPassword(
              values,
              resetForm,
              t("profile.security.2"),
              t("profile.security.3"),
              t("profile.security.4"),
              setShowDialog
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          resetForm,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {isSubmitting && <Spinner />}
            <h4 className="profile_title">{t("profile.security.1")}</h4>

            <div className={`mb-3`}>
              <label htmlFor="validationCustom202">{t("profile.9")}</label>
              <div className="position-relative">
                <input
                  type={show ? "text" : "password"}
                  className="form-control profile_input"
                  name="currentPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currentPassword || ""}
                  disabled={!showDialog}
                />
              </div>
              {errors.currentPassword && touched.currentPassword && (
                <span className="error" style={{ color: "red" }}>
                  {errors.currentPassword}
                </span>
              )}
            </div>

            <div className={`mb-3`}>
              <div className="position-relative">
                <label htmlFor="validationCustom202">{t("profile.10")}</label>
                <input
                  type={show ? "text" : "password"}
                  className="form-control profile_input"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  disabled={!showDialog}
                />
              </div>
              {errors.newPassword && touched.newPassword && (
                <span className="error" style={{ color: "red" }}>
                  {errors.newPassword}
                </span>
              )}
            </div>

            <div className={`mb-3`}>
              <div className="position-relative">
                <label htmlFor="validationCustom202">{t("profile.11")}</label>

                <input
                  className="form-control profile_input"
                  type={show ? "text" : "password"}
                  name="changepassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.changepassword}
                  disabled={!showDialog}
                />
              </div>
              {errors.changepassword && touched.changepassword && (
                <span className="error" style={{ color: "red" }}>
                  {errors.changepassword}
                </span>
              )}
            </div>

            {!showDialog ? (
              <div className="d-flex justify-content-end">
                <Button
                  className="profile_submit_btn"
                  onClick={() => setShowDialog(true)}
                  type="button"
                >
                  {t("profile.4")}
                </Button>
              </div>
            ) : (
              <ChangeDialog
                isSubmitting={isSubmitting}
                cancelFunc={() => {
                  setShowDialog(false);
                  resetForm({ values: "" });
                }}
              />
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
