import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "./slices/authentication/authenticationSlice";
import leadsSlice from "./slices/leads/leadsSlice";
import layoutSettingSlice from "./slices/layout/layoutSettingSlice";
import subscriptionSlice from "./slices/subscription/subscriptionSlice";
import myEstimationsSlice from "./slices/myEstimations/myEstimationsSlice";
import currentReportSlice from "./slices/currentRapport/currentReportSlice";
import evaluationSlice from "./slices/evaluation/evaluationSlice";
import salesSlice from "./slices/sales/salesSlice";

export default configureStore({
  reducer: {
    authentication: authenticationSlice,
    leads: leadsSlice,
    layoutSetting: layoutSettingSlice,
    subscription: subscriptionSlice,
    myEstimations: myEstimationsSlice,
    currentReport: currentReportSlice,
    evaluation: evaluationSlice,
    sales: salesSlice,
  },
});
