import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SuspendModal from "./SuspendModal";
import DeleteModal from "./DeleteModal";

const CurrentPlan = ({ currentPlan, products, currentAccount }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState({});

  useEffect(() => {
    if (products?.length > 0 && currentPlan) {
      const selected = products.find((plan) => plan.id === currentPlan);
      setSelectedPackage(selected);
    }
  }, [products, currentPlan]);

  return (
    <div className="card-container">
      <div className="current-selected-plan">
        <div className="current-selected-plan-inner">
          {selectedPackage?.productMetadata?.image && (
            <img src={selectedPackage?.productMetadata?.image} alt="icon" />
          )}
          <div>
            <div className="flex-row mobile-colmn">
              <p className="name">
                {selectedPackage?.nickname?.replace("-", " ")}
              </p>
              <p className="plan-label">
                {t("profile.subscription.current_plan.1")}
              </p>
            </div>
            <p className="current-selected-plan-time">
              {t("profile.subscription.current_plan.2")}
              <span>
                {currentAccount?.subscriptionCurrentPeriodEnd &&
                  moment().to(
                    moment(currentAccount.subscriptionCurrentPeriodEnd * 1000)
                  )}
              </span>
            </p>
          </div>
        </div>
        <p className="current-selected-plan-price">
          $
          {(currentAccount?.amount / 100)
            ?.toFixed()
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          <span>
            {selectedPackage?.recurring?.interval &&
              t(
                `profile.subscription.current_plan.${selectedPackage?.recurring?.interval}`
              )}
          </span>
        </p>
      </div>
      <p className="current-plan-desc current-plan-desc-bold">
        {selectedPackage?.description}
      </p>
      <p className="current-plan-desc">
        {selectedPackage?.productMetadata?.plan_description &&
          t(
            `profile.subscription.${selectedPackage?.productMetadata?.plan_description}`
          )}
      </p>
      {currentAccount?.isToDelete ? null : (
        <div className="current-plan-action-btns">
          <button
            className="non-filled-bg-btn mob-min-width-250"
            onClick={() => {
              setPassword("");
              setShowDeleteModal(!showDeleteModal);
            }}
          >
            {t("profile.subscription.current_plan.3")}
          </button>
          <button
            className="bg-filled-btn curr-plan-btn-margin mob-min-width-250"
            onClick={() => {
              setPassword("");
              setModal(!modal);
            }}
          >
            {t("profile.subscription.current_plan.4")}
          </button>
        </div>
      )}
      <SuspendModal
        setModal={setModal}
        modal={modal}
        password={password}
        setPassword={setPassword}
        loading={loading}
        setLoading={setLoading}
      />

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        currentAccount={currentAccount}
        password={password}
        setPassword={setPassword}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default CurrentPlan;
