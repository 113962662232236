import React, { useCallback, useState } from "react";
import ProspecCardTabs from "./prospectCardTabs";
import ActionsButton from "./ActionsButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { db } from "../../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { setActionLoading } from "../../../redux/slices/leads/leadsSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";

const ProspectCard = ({
  prospect,
  setSelectedProspect,
  selectedProspect,
  onClick,
  profile,
  refreshUserData,
  updateEvaluation,
  setLoading,
  shownProspects,
}) => {
  const dispatch = useDispatch();
  const { actionLoading } = useSelector((state) => state.leads);

  const updateActionLoading = useCallback(
    (loading) => {
      dispatch(setActionLoading(loading));
    },
    [dispatch]
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [liveProspect, setLiveProspect] = useState(prospect);

  useEffect(() => {
    setLiveProspect(prospect);
  }, [prospect]);

  const { t } = useTranslation();

  const getStatus = (liveProspect) => {
    if (liveProspect?.type === "prospect") return "N/A";
    if (liveProspect?.broker && liveProspect?.broker[0]?.projectProgress) {
      return t("Leads.105");
    } else {
      return t("Leads.24");
    }
  };

  const isSelected = selectedProspect === liveProspect.id;
  const isAccepted =
    liveProspect?.type !== "prospect" &&
    liveProspect?.broker?.length > 0 &&
    liveProspect?.broker[0]?.brokerId !== profile.userId;

  useEffect(() => {
    if (liveProspect?.id && shownProspects === "opportunities") {
      const unsubscribe = onSnapshot(
        doc(db, "RapportsEvaluations", liveProspect.id),
        (doc) => {
          const newData = { ...doc.data(), id: doc.id, type: prospect?.type };
          setLiveProspect(newData);
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [shownProspects, liveProspect?.id, prospect?.type]);

  const handleCardClick = (liveProspect) => {
    if (
      liveProspect?.broker?.length > 0 &&
      liveProspect?.broker[0]?.brokerId !== profile.userId
    ) {
      return;
    } else {
      onClick(liveProspect);
      setSelectedProspect(liveProspect.id);
    }
  };

  return (
    <>
      {actionLoading && <Spinner />}
      <div
        key={liveProspect.id}
        className={
          "newLeads_prospects_list_item_container" +
          (isSelected ? " newLeads_prospects_list_item_active" : "")
        }
        style={isAccepted ? { opacity: 0.7 } : {}}
      >
        <div
          className="newLeads_prospects_list_item"
          style={isAccepted ? { cursor: "auto" } : {}}
          onClick={() => {
            handleCardClick(liveProspect);
          }}
        >
          <div
            className="newLeads_prospects_list_item_profils"
            style={{
              backgroundColor:
                liveProspect.type === "prospect" ? "#BEE3F8" : "#FEEBCB",
            }}
          >
            {liveProspect.type}
          </div>
          <div className="newLeads_prospects_list_item_status d-none d-md-flex justify-content-center">
            {getStatus(liveProspect)}
          </div>
          <div
            className={
              liveProspect.remiseSurMarche
                ? "newLeads_prospects_list_item_remiseSurMarche_oui d-none d-lg-flex justify-content-center"
                : "newLeads_prospects_list_item_remiseSurMarche_non d-none d-lg-flex justify-content-center"
            }
          >
            {liveProspect.remiseSurMarche ? "Oui" : "Non"}
          </div>
          <div className="newLeads_prospects_list_item_date">
            {liveProspect.dateCreation}
          </div>
          <div className="newLeads_prospects_list_item_valeurVMZ  d-none d-md-flex  justify-content-center">
            {formatter.format(liveProspect.ziaEstimation.prediction)}
          </div>
          <ActionsButton
            prospect={liveProspect}
            profile={profile}
            refreshUserData={refreshUserData}
            setLoading={updateActionLoading}
            loading={actionLoading}
            setSelectedProspect={setSelectedProspect}
          />
        </div>
        {isSelected && (
          <ProspecCardTabs
            prospect={liveProspect}
            setLiveProspect={setLiveProspect}
            updateEvaluation={updateEvaluation}
            refreshUserData={refreshUserData}
            profile={profile}
            setLoading={setLoading}
            setSelectedProspect={setSelectedProspect}
          />
        )}
      </div>
    </>
  );
};

export default ProspectCard;
