import { useEffect } from "react";
import { useFormik } from "formik";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import PacksSelection from "../components/packsSelection";
import { findLicenceIdInDatabase } from "../../../redux/slices/subscription/actions/findLicenceIdInDatabase";
import { createSubscription } from "../../../redux/slices/subscription/actions/createSubscription";
import { useNavigate } from "react-router-dom";
import { setSubError } from "../../../redux/slices/subscription/subscriptionSlice";

const FinalizeOrderStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationSchema = () =>
    yup.object().shape({
      name: yup.string().required(t("Sign_up.117")),
      licenseId: yup
        .string()
        .required(t("Sign_up.118"))
        .max(5, t("Sign_up.120"))
        .min(5, t("Sign_up.121")),
      acceptTerms: yup.bool().oneOf([true], t("Sign_in.21")),
      acceptCondition: yup.bool().oneOf([true], t("Sign_in.21")),
    });

  const {
    subLoading,
    current,
    plan: selectedPackage,
    licenceIdFound,
    subError,
    subMessage,
  } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const submitPayment = async (values) => {
    if (!stripe || !elements) {
      return;
    }
    dispatch(setSubError(false));
    const cardElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      dispatch(setSubError(error.message));
    } else {
      dispatch(
        createSubscription(
          {
            source: token.id,
            plan: selectedPackage,
            user: current,
            cardName: values.name,
            licenseId: values.licenseId,
            coupon: values.couponVal,
            licenceIdFound,
          },
          t,
          navigate
        )
      );
    }
  };

  const checkLicenceId = async (licenseId, formik) => {
    console.log("checkLicenceId", licenseId);
    formik.setErrors({ licenseId: "" });
    formik.setFieldTouched("licenseId", true);
    if (!licenseId) {
      formik.setErrors({ licenseId: t("Sign_up.118") });
    } else if (licenseId.length !== 5) {
      formik.setErrors({ licenseId: t("Sign_up.122") });
    } else dispatch(findLicenceIdInDatabase(licenseId));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      licenseId: "",
      acceptTerms: false,
      acceptCondition: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (selectedPackage) submitPayment(values);
    },
  });

  useEffect(() => {
    if (elements) {
      elements.getElement(CardNumberElement).on("change", (event) => {
        if (event.error) {
          document.getElementById("CardNumberElementERROR").textContent =
            event.error.message;
        } else {
          document.getElementById("CardNumberElementERROR").textContent = "";
        }
      });
      elements.getElement(CardCvcElement).on("change", (event) => {
        if (event.error) {
          document.getElementById("CardCvcElementERROR").textContent =
            event.error.message;
        } else {
          document.getElementById("CardCvcElementERROR").textContent = "";
        }
      });
      elements.getElement(CardExpiryElement).on("change", (event) => {
        if (event.error) {
          document.getElementById("CardExpiryElementERROR").textContent =
            event.error.message;
        } else {
          document.getElementById("CardExpiryElementERROR").textContent = "";
        }
      });
    }
  }, [elements]);

  return (
    <>
      {subLoading && <Spinner />}
      <PacksSelection />
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-3">
            <label htmlFor="licenseId">{t("Sign_up.43")}*</label>
            <input
              className="form-control "
              name="licenseId"
              type="text"
              onChange={formik.handleChange}
              maxLength="5"
              onBlur={() => checkLicenceId(formik.values.licenseId, formik)}
              value={formik.values.licenseId}
            />
            {formik.errors.licenseId && formik.touched.licenseId && (
              <div className="text-danger mt-1 ml-2">
                {formik.errors.licenseId}
              </div>
            )}
            {subError && (
              <div className="text-danger mt-1 ml-2">
                {subMessage === "license_already_taken" && (
                  <span>{t("Sign_up.124")}</span>
                )}
                {subMessage !== "license_not_exists" &&
                  subMessage !== "license_already_taken" && (
                    <span>{subMessage}</span>
                  )}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="licenseId">{t("Sign_up.116")}</label>
            <div
              className="form-control d-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <span className="h2 text-muted mb-0">
                <i className="i-Visa"></i>
              </span>
              <CardNumberElement className="flex-fill" />
              <CardExpiryElement className="w-56px" />
              <CardCvcElement className="w-32px" />
            </div>

            <div>
              <div
                className="text-danger mt-1 ml-2"
                id="CardNumberElementERROR"
              />
              <div
                className="text-danger mt-1 ml-2"
                id="CardExpiryElementERROR"
              />
              <div className="text-danger mt-1 ml-2" id="CardCvcElementERROR" />
            </div>
          </div>
          <div className="mb-3">
            <input
              className="form-control"
              name="name"
              type="text"
              placeholder="Nom sur la carte"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />

            {formik.errors.name && formik.touched.name && (
              <div className="text-danger mt-1 ml-2">{formik.errors.name}</div>
            )}
          </div>
          <div>
            <input
              className="form-control"
              name="couponVal"
              type="text"
              placeholder="Appliquer Coupon"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.couponVal}
            />
          </div>

          <div className="mb-3 mt-4">
            <div className="d-flex">
              <label className="checkbox checkbox-outline-primary mb-0">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  onChange={formik.handleChange}
                  checked={formik.values.acceptTerms}
                  className={
                    "form-check-input " +
                    (formik.errors.acceptTerms && formik.touched.acceptTerms
                      ? " is-invalid"
                      : "")
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="mb-0 text-13" htmlFor="one">
                {t("Sign_up.46")}{" "}
                <span
                  style={{ color: "#663399", cursor: "pointer" }}
                  className="font-weight-bold"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/termofservice?newTab=true`,
                      "_blank"
                    );
                  }}
                >
                  {t("Sign_up.47")}
                </span>
              </label>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <small className="text-danger mt-1 ml-2">
                {t(formik.errors.acceptTerms)}
              </small>
            )}
            <div className="d-flex mt-3">
              <label className="checkbox checkbox-outline-primary mb-0">
                <input
                  type="checkbox"
                  name="acceptCondition"
                  onChange={formik.handleChange}
                  checked={formik.values.acceptCondition}
                  className={
                    "form-check-input " +
                    (formik.errors.acceptCondition &&
                    formik.touched.acceptCondition
                      ? " is-invalid"
                      : "")
                  }
                />
                <span className="checkmark"></span>
              </label>
              <label className="mb-0 text-13" htmlFor="two">
                {t("Sign_up.46")}{" "}
                <span
                  style={{ color: "#663399", cursor: "pointer" }}
                  className="font-weight-bold"
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/generalPolicy?newTab=true`,
                      "_blank"
                    );
                  }}
                >
                  {t("Sign_up.48")}
                </span>
              </label>
            </div>
            {formik.touched.acceptCondition &&
              formik.errors.acceptCondition && (
                <small className="text-danger mt-1 ml-2">
                  {t(formik.errors.acceptCondition)}
                </small>
              )}
          </div>
          <button
            className="btn w-100 btn-lg btn-primary rounded-xl"
            disabled={subLoading}
            type="submit"
          >
            {`${t("Sign_up.49")} - ${t("Sign_up.50")}`}
          </button>
        </div>
      </form>
    </>
  );
};

export default FinalizeOrderStep;
