import { useEffect, useState, useCallback, useMemo } from "react";
import FormModify from "./FormModify";
import MapEstimate from "../../../components/map/MapEstimate";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateFormalizedData } from "../../../redux/slices/evaluation/actions/updateFormalizedData";
import postEstimation from "../../../redux/slices/evaluation/actions/postEstimation";
import getPropertyDetailInfo from "../utils/getProperyDetailInfo";
import formatData from "../utils/formatData";
import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";

const AccommodationStep = ({ updateLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nextStep, goToStep } = useWizard();
  const { formalizedData, loading, result } = useSelector(
    (state) => state.evaluation
  );
  const { userData } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const [data, setData] = useState(null);
  const [charData, setCharData] = useState([]);
  const [information, setInformation] = useState([]);
  const [finalLoading, setFinalLoading] = useState(false);

  const characteristicsData = useMemo(
    () => [
      {
        title: t("Estimate-new.8"),
        badges: ["Appartement", "1980", "54m2", "3 pièces"],
      },
      {
        title: t("Estimate-new.9"),
        badges: [
          "3 chambres",
          "2 salles de bain",
          "1 garage",
          "2 stationnements",
        ],
      },
    ],
    [t]
  );

  useEffect(() => {
    if (formalizedData && formalizedData?.location) {
      getPropertyDetailInfo(
        formalizedData,
        updateLoading,
        nextStep,
        setData,
        setCharData,
        setInformation
      );
    }
  }, [formalizedData, updateLoading, nextStep, dispatch]);

  useEffect(() => {
    if (result?.ziaEstimation && userData && formalizedData) {
      dispatch(
        postEstimation(
          userData?.userId,
          userData,
          formalizedData,
          result,
          navigate,
          setFinalLoading
        )
      );
    }
  }, [userData, formalizedData, result, navigate, dispatch, setFinalLoading]);

  if (loading || finalLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center bg-white shadow-sm rounded-xl p-4">
        <img
          height={200}
          width={200}
          src="/assets/images/ziawayLoader.gif"
          alt=""
        />
        <h4 className="mb-0">{t("Estimate-new.120")}</h4>
      </div>
    );
  }

  return (
    <>
      {formalizedData && (
        <div className="container px-0 px-md-3">
          <div className="px-0 mx-auto col-12 col-xl-10">
            <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
              {t("Estimate-new.1")}
            </h2>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="bg-white box-shadow-3 rounded-2 p-3">
                  <div
                    className="bg-gray-100 rounded-lg"
                    style={{ height: 180 }}
                  >
                    <div className="d-block h-100 w-100 rounded-lg border-0">
                      {
                        <MapEstimate
                          markers={[
                            {
                              location: formalizedData?.location,
                            },
                          ]}
                        />
                      }
                    </div>
                  </div>
                  <ul
                    className="nav flex-column flex-nowrap my-4"
                    style={{ gap: 20 }}
                  >
                    <li>
                      <h6 className="mb-2 d-block text-dark-gray font-weight-normal font-poppins">
                        {t("Estimate-new.2")}
                      </h6>
                      <h6 className="mb-0 d-block text-black-2 font-weight-bold font-roboto">
                        {formalizedData?.location?.value}
                      </h6>
                    </li>
                    {!loading &&
                      data &&
                      characteristicsData.map((data) => (
                        <li key={data.title}>
                          <h6 className="mb-3 d-block text-dark-gray font-weight-normal font-poppins">
                            {data.title}
                          </h6>
                          <div className="d-flex flex-wrap" style={{ gap: 10 }}>
                            {data?.title === "Caractéristiques :"
                              ? charData.map((badge) => (
                                  <span
                                    key={badge}
                                    className="badge-box font-roboto"
                                  >
                                    {badge}
                                  </span>
                                ))
                              : information.map((badge) => (
                                  <span
                                    key={badge}
                                    className="badge-box font-roboto"
                                  >
                                    {badge}
                                  </span>
                                ))}
                          </div>
                        </li>
                      ))}
                  </ul>
                  {!loading && data && (
                    <div
                      className="d-flex flex-column flex-sm-row"
                      style={{ gap: 10 }}
                    >
                      <button
                        type="button"
                        onClick={() => setShowForm(true)}
                        className="w-100 btn btn-lg text-14 btn-outline-primary font-weight-600 font-poppins rounded-lg py-2"
                      >
                        {t("Estimate-new.3")}
                      </button>
                      <button
                        onClick={() => {
                          dispatch(
                            updateFormalizedData({
                              ...formatData(data),
                              formComplete: true,
                            })
                          );
                          setFinalLoading(true);
                        }}
                        className="w-100 btn btn-lg text-14 btn-primary font-weight-600 font-poppins rounded-lg py-2"
                      >
                        {t("Estimate-new.4")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                {showForm ? (
                  <FormModify
                    data={data}
                    address={formalizedData?.location}
                    setShowForm={setShowForm}
                    goToStep={goToStep}
                    setFinalLoading={setFinalLoading}
                  />
                ) : (
                  <div className="bg-white box-shadow-3 rounded-2 p-3 h-100 d-flex flex-column align-items-center justify-content-center text-center">
                    <img src="/assets/images/rent.png" alt="..." />
                    <h6 className="mt-4 mb-3 d-block text-violet-2 font-weight-bold font-poppins">
                      {t("Estimate-new.5")} <br /> {t("Estimate-new.6")}
                    </h6>
                    <button
                      onClick={nextStep}
                      className="btn btn-lg text-12 btn-primary font-weight-600 font-poppins rounded-lg py-2"
                    >
                      {t("Estimate-new.7")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccommodationStep;
