import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const addNote = (contactId, note, setLoading) => {
  setLoading(true);
  const add = httpsCallable(functions, "addNote");
  const response = add({ contactId, note })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });

  return response;
};

export default addNote;
