import "./style.scss";
import ChoiceOfferPage from "../../../components/packages/choiceoffer";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="auth-layout-wrap poppins_font">
      <div className="main-header px-0">
        <div
          className="logo"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img src="/assets/images/logo.png" alt="" />
        </div>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            id="dropdown-basic"
            className="d-flex align-items-center lang_dropdown mr-4"
          >
            {t("Sign_up.90")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguage("fr")}>
              <div className="d-flex align-items-center">
                <span>{t("Sign_up.92")}</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguage("en")}>
              <div className="d-flex align-items-center">
                <span>{t("Sign_up.91")}</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="offer_header">
        <h1>{t("Sign_up.93")}</h1>
        <p>{t("Sign_up.94")}</p>
        <p>{t("Sign_up.95")}</p>
      </div>
      <div className="auth-content py-5">
        <div
          className="card o-hidden"
          style={{ maxWidth: "610px", marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="row">
            <div className="col-md-12">
              <ChoiceOfferPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
