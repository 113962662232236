import { useEffect, useState, useMemo, useCallback } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import SimpleCard from "../../components/simpleCard";
import Spinner from "../../components/spinner";
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLeadsCount } from "../../redux/slices/leads/actions/getLeadsCount";
import contactFormatter from "./utils/contactFormatter";
import { getEchartLines } from "../../redux/slices/leads/actions/getEchartLines";
import getUserStatusClass from "./utils/getUserStatusClass";
import getMaxValue from "./utils/getMaxValue";
import sortingByDate from "./utils/sortingByDate";
import ReactPaginate from "react-paginate";

const DashboardGraphs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const [asending, setAsending] = useState(false);
  const [contacts, setContacts] = useState([]);

  const {
    leadsCount,
    allContactsCount,
    allProspectsCount,
    loadingDashboardData,
    myContactsLineData,
    lastFourWeeksStrings,
    allLeadsLineData,
  } = useSelector((state) => state.leads);
  const { userData } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (userData?.bookmarks?.length > 0) {
      let myContacts = userData?.bookmarks?.filter(
        (item) => item?.ouiContacterParProfessionnel === "oui"
      );
      myContacts = contactFormatter(myContacts);
      setContacts(myContacts);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      dispatch(getEchartLines(userData?.bookmarks));
    }
  }, [userData, dispatch]);

  useEffect(() => {
    dispatch(getLeadsCount());
  }, [dispatch]);

  const options4 = useCallback((data) => {
    return {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#888",
              fontSize: "14px",
            },
            value: {
              formatter: function (val) {
                return `${parseFloat(val).toFixed(0)}%`;
              },
              offsetY: 7,
              offsetX: 2,
              color: "#111",
              fontSize: "12px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      series: [data],
      stroke: {
        lineCap: "round",
      },
    };
  }, []);

  const handlePageClick = useCallback((data) => {
    setPageNumber(data.selected);
  }, []);

  const series = useMemo(
    () => [
      {
        name: "My Leads",
        data: myContactsLineData,
      },
      {
        name: "All Leads",
        data: allLeadsLineData,
      },
    ],
    [myContactsLineData, allLeadsLineData]
  );

  const options = useMemo(
    () => ({
      chart: {
        height: 400,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: lastFourWeeksStrings,
      },
      yaxis: {
        min: 0,
        max: getMaxValue(allLeadsLineData) + 1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: 0,
        offsetX: -10,
      },
    }),
    [lastFourWeeksStrings, allLeadsLineData]
  );

  return (
    <>
      {!loadingDashboardData ? (
        <>
          <div>
            <h2 className="m-3">{t("Dashboard.1")}</h2>
          </div>
          <hr className="mt-0 ml-1" />
          <div className="border shadow-sm p-4">
            <div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col xs={12} sm={12} md={4} className="mb-2">
                    <SimpleCard className="h-100" title={t("DGraphs.1")}>
                      <div className="d-flex flex-column flex-xl-row align-items-center justify-content-center">
                        <div className="chart-small flex-shrink-0">
                          <Chart
                            options={options4()}
                            series={
                              options4((0 * 100) / allContactsCount).series
                            }
                            type={options4().chart.type}
                          />
                        </div>
                        <div>
                          <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.2")} {leadsCount}
                            </span>
                          </p>
                          {/* <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.3")}
                            </span>
                          </p> */}
                        </div>
                      </div>
                    </SimpleCard>
                  </Col>
                  <Col xs={12} sm={12} md={4} className="mb-2">
                    <SimpleCard className="h-100" title={t("DGraphs.4")}>
                      <div className="d-flex flex-column flex-xl-row align-items-center justify-content-center">
                        <div className="chart-small flex-shrink-0">
                          <Chart
                            options={options4()}
                            series={
                              options4(
                                (
                                  (userData?.bookmarks?.length * 100) /
                                    allProspectsCount || 1
                                ).toFixed(2)
                              ).series
                            }
                            type={options4().chart.type}
                          />
                        </div>
                        <div>
                          <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.5")} {allProspectsCount}
                            </span>
                          </p>
                          <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.6")} {userData?.bookmarks?.length}
                            </span>
                          </p>
                        </div>
                      </div>
                    </SimpleCard>
                  </Col>
                  <Col xs={12} sm={12} md={4} className="mb-2">
                    <SimpleCard className="h-100" title={t("DGraphs.7")}>
                      <div className="d-flex flex-column flex-xl-row align-items-center justify-content-center">
                        <div className="chart-small flex-shrink-0">
                          <Chart
                            options={options4()}
                            series={
                              options4(
                                (
                                  (contacts.length * 100) /
                                  allContactsCount
                                ).toFixed(2)
                              ).series
                            }
                            type={options4().chart.type}
                          />
                        </div>
                        <div>
                          <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.8")} {allContactsCount}
                            </span>
                          </p>
                          <p>
                            <span className="font-weight-bold">
                              {t("DGraphs.9")} {contacts.length}
                            </span>
                          </p>
                        </div>
                      </div>
                    </SimpleCard>
                  </Col>
                </Row>
                <div style={{ top: 100 }}>
                  <div>
                    <div className="card">
                      <div className="card-header card-title mb-0 d-flex align-items-center justify-content-between border-0">
                        <h3 className="w-50 float-left card-title m-0">
                          {t("DGraphs.10")}
                        </h3>
                      </div>
                      <div className="pl-4">
                        <Chart
                          options={options}
                          series={series}
                          type="line"
                          height={350}
                        />
                      </div>
                    </div>
                    <div className="card mt-4">
                      <div className="card-header card-title mb-0 d-flex align-items-center justify-content-between border-0">
                        <h3 className="w-50 float-left card-title m-0">
                          {t("DGraphs.11")}
                        </h3>
                      </div>

                      <div className="pb-2">
                        <div className="table-responsive">
                          <table id="user_table" className="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t("DGraphs.15")}</th>
                                <th
                                  onClick={() =>
                                    sortingByDate(
                                      asending,
                                      contacts,
                                      setContacts,
                                      setAsending
                                    )
                                  }
                                  scope="col"
                                >
                                  {t("DGraphs.16")}
                                  {asending === true ? (
                                    <i className="i-Arrow-Down-3 pl-2"></i>
                                  ) : (
                                    <i className="i-Arrow-Up-3 pl-2"></i>
                                  )}
                                </th>
                                <th scope="col">{t("DGraphs.17")}</th>
                                <th scope="col">{t("DGraphs.19")}</th>
                                <th scope="col">{t("DGraphs.18")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contacts
                                .slice(10 * pageNumber, 10 * (pageNumber + 1))
                                .map((user, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td
                                      className="text-justify"
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        minWidth: "200px",
                                      }}
                                    >
                                      {user.name}
                                    </td>
                                    <td>{user?.dateCreation}</td>

                                    <td className="text-justify">
                                      {user.email}
                                    </td>
                                    <td className="text-justify">
                                      {user.phone}
                                    </td>
                                    <td>
                                      <span
                                        className={`badge ${getUserStatusClass(
                                          user?.status
                                        )}`}
                                      >
                                        {user?.status &&
                                          t(
                                            "Dashboard." +
                                              user?.status?.replaceAll(" ", "_")
                                          )}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-center my-1">
                          <ReactPaginate
                            previousLabel={<i className="i-Previous"></i>}
                            nextLabel={<i className="i-Next1"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(contacts.length / 10)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default DashboardGraphs;
