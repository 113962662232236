import React from "react";
import { useTranslation } from "react-i18next";
import MySales from "./mySales";

const MesVentes = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        minHeight: "85vh",
      }}
    >
      <MySales t={t} />
    </div>
  );
};

export default MesVentes;
