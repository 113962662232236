import Modal from "react-bootstrap/Modal";
import SalePreview from "./components/SalePreview";
import EditSale from "./EditSale";

const SideSlide = ({
  show,
  onHide,
  t,
  selectedSale,
  isDeleting,
  isEditing,
  setIsEditing,
  setIsDeleting,
  setShowModal,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="modal-90w"
      contentClassName="modal-content-custom"
    >
      {show && (
        <>
          {isEditing ? (
            <EditSale
              selectedSale={selectedSale}
              t={t}
              onHide={onHide}
              show={isEditing}
            />
          ) : (
            <SalePreview
              selectedSale={selectedSale}
              t={t}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              onHide={onHide}
              setShowModal={setShowModal}
              setIsEditing={setIsEditing}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default SideSlide;
