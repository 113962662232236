import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setProducts,
  setTaxes,
  setSubLoading,
  setSubError,
} from "../subscriptionSlice";
import { setFooterShow } from "../../layout/layoutSettingSlice";

export function getAllProducts() {
  return (dispatch) => {
    dispatch(setFooterShow(false));
    dispatch(setSubLoading(true));
    const listProducts = httpsCallable(functions, "getProducts");
    listProducts()
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          dispatch(setProducts(res.data?.plans));
          dispatch(setTaxes(res.data?.taxes));
        }
        dispatch(setSubLoading(false));
        dispatch(setFooterShow(true));
      })
      .catch((err) => {
        dispatch(setSubLoading(false));
        dispatch(setFooterShow(true));
        dispatch(setSubError(err));

        console.log("err", err);
        toast.error(err.message);
      });
  };
}
