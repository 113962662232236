import { useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { sendResetPasswordEmail } from "../../redux/slices/authentication/actions/sendResetPasswordEmail";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const ForgotPasswordSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("email is required"),
  });
  const { t } = useTranslation();
  const handleSubmit = useCallback((value, { setSubmitting }) => {
    console.log("value", value?.email);
    dispatch(sendResetPasswordEmail(value?.email, setSubmitting, t));
  }, []);
  return (
    <div
      className="auth-layout-wrap"
      style={{
        backgroundImage: "url(/assets/images/photo-wide-4.jpg)",
      }}
    >
      <div className="auth-content">
        <div className="card o-hidden">
          <div className="row">
            <div className="col-md-6">
              <div className="p-4">
                <div className="auth-logo text-center mb-4">
                  <img src="/assets/images/logo.png" alt="" />
                </div>
                <h1 className="mb-3 text-18">{t("resetPassword.1")}</h1>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={ForgotPasswordSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {isSubmitting && <Spinner />}
                      <div className="form-group">
                        <label htmlFor="email">{t("resetPassword.7")}</label>
                        <input
                          className="form-control form-control-rounded position-relative"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Ziaway@example.com"
                        />
                        {errors.email && (
                          <div className="text-danger mt-1 ml-2">
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-rounded btn-primary btn-block mt-2"
                        type="submit"
                      >
                        {t("resetPassword.2")}
                      </button>
                    </form>
                  )}
                </Formik>

                <div className="mt-3 text-center">
                  <Link to="/signin" className="text-muted">
                    <u>{t("resetPassword.8")}</u>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 text-center "
              style={{
                backgroundSize: "cover",
                backgroundImage: "url(/assets/images/photo-long-3.jpg)",
              }}
            >
              <div className="pr-3 auth-right">
                <Button className="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text">
                  <Link to="/signin">
                    <i className="i-Mail-with-At-Sign"></i>
                    {t("resetPassword.8")}
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
