export const extractMunicipalitiesFromFilterSummary = (filterSummary) => {
  if (!filterSummary) return ["Pointe-de-Sainte-Foy"];
  const municipalities = [];
  Object.keys(filterSummary).forEach((city) => {
    filterSummary[city].forEach((item) => {
      municipalities.push(item.value);
    });
  });
  return municipalities;
};
