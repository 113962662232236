import { extractMunicipalitiesFromFilterSummary } from "../utils/extractMunicipalitiesFromFilterSummary";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import swal from "sweetalert2";

function MultilevelSelectWithCheckboxes({
  cities,
  cityValue,
  onCityChange,
  muncipalities,
  munciValue,
  updateFilterSummary,
  filterSummary,
  filters,
  userFilters,
}) {
  const { t } = useTranslation();
  const {
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    loading: mainLoading,
  } = useSelector((state) => state.leads);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(
      actionLoading ||
        fetchMoreLoading ||
        firstFetchLoading ||
        loadingLeads ||
        mainLoading
    );
  }, [
    actionLoading,
    fetchMoreLoading,
    firstFetchLoading,
    loadingLeads,
    mainLoading,
  ]);

  const [selectedOptions, setSelectedOptions] = useState(munciValue || []);
  const [filteredOptions, setFilteredOptions] = useState(cities);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {}, [userFilters]);

  useEffect(() => {
    setFilteredOptions(cities);
  }, [cities]);

  useEffect(() => {
    if (Array.isArray(munciValue)) {
      setSelectedOptions(munciValue);
    } else {
      setSelectedOptions(munciValue ? [munciValue] : []);
    }
  }, [munciValue]);

  const handleOptionToggle = async (
    e,
    option,
    selectedOptions,
    filterSummary
  ) => {
    const selectedMunicipalites =
      extractMunicipalitiesFromFilterSummary(filterSummary);
    e.stopPropagation();
    if (selectedMunicipalites?.length >= 30 && e.target.checked) {
      await swal.fire({
        titleText: t("Leads.122"),
        showCancelButton: false,
        confirmButtonText: "Okay",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
      });
    } else if (selectedMunicipalites?.length === 1 && !e.target.checked) {
      await swal.fire({
        titleText: t("Leads.123"),
        showCancelButton: false,
        confirmButtonText: "Okay",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
      });
    } else {
      await updateFilterSummary(option, cityValue, setSelectedOptions);
    }
  };

  const FilterOptionsOnIputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value === "") {
      setFilteredOptions(cities);
    } else {
      const filteredOptions = cities.filter((option) =>
        option.label
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setFilteredOptions(filteredOptions);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [showSideDropdown, setShowSideDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [sideDropdownOptions, setSideDropdownOptions] = useState(
    muncipalities || []
  );

  useEffect(() => {
    if (muncipalities) {
      setSideDropdownOptions(muncipalities);
    }
  }, [muncipalities]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowSideDropdown(false);
        setSearchInput("");
        setFilteredOptions(cities);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, cities]);

  const checkIfMunicipalityIsSelected = (option, filterSummary, cityValue) => {
    let isChecked = false;
    for (const [key, value] of Object.entries(filterSummary)) {
      if (key === cityValue?.value) {
        for (const item of value) {
          if (item.value === option.value) {
            isChecked = true;
            break;
          }
        }
      }
    }
    return isChecked;
  };

  return (
    <div className="newleads_multiselect" ref={wrapperRef}>
      <img src="assets/icons/searchIcon.svg" alt="icon" />
      <input
        type="text"
        value={searchInput}
        placeholder={t("Leads.91")}
        className="newleads_multiselect_input"
        onClick={() => setShowDropdown(true)}
        onChange={(event) => FilterOptionsOnIputChange(event)}
        disabled={loading}
      />
      {showDropdown && (
        <div className="newleads_multiselect_dropdown">
          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className={
                cityValue?.value === option?.value
                  ? "newleads_multiselect_dropdown_option_selected"
                  : "newleads_multiselect_dropdown_option"
              }
              id={option.value}
              onClick={async () => {
                setSelectedOption(option);
                await onCityChange(option, filters);

                setShowSideDropdown(true);
              }}
            >
              {option.label}
              {selectedOption?.value === option?.value &&
                selectedOptions?.length > 0 &&
                showSideDropdown &&
                sideDropdownOptions?.length < 3 && (
                  <>
                    {sideDropdownOptions?.map((option) => (
                      <div
                        className="newleads_multiselect_sidedropdown_option"
                        id={option.value}
                        key={option.value}
                      >
                        <label>
                          <input
                            type="checkbox"
                            disabled={loading}
                            value={option.value}
                            checked={checkIfMunicipalityIsSelected(
                              option,
                              filterSummary,
                              cityValue
                            )}
                            onChange={(e) =>
                              handleOptionToggle(
                                e,
                                option,
                                selectedOptions,
                                filterSummary
                              )
                            }
                          />
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </>
                )}
            </div>
          ))}
        </div>
      )}

      {selectedOptions?.length > 0 &&
        showSideDropdown &&
        selectedOption &&
        sideDropdownOptions?.length >= 4 && (
          <div className="newleads_multiselect_sidedropdown">
            {sideDropdownOptions?.map((option) => (
              <div
                className="newleads_multiselect_sidedropdown_option"
                id={option.value}
                key={option.value}
              >
                <label>
                  <input
                    disabled={loading}
                    type="checkbox"
                    value={option.value}
                    checked={checkIfMunicipalityIsSelected(
                      option,
                      filterSummary,
                      cityValue
                    )}
                    onChange={(e) =>
                      handleOptionToggle(
                        e,
                        option,
                        selectedOptions,
                        filterSummary
                      )
                    }
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        )}
    </div>
  );
}

export default MultilevelSelectWithCheckboxes;
