import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";

const CustomTable = ({
  data,
  titles,
  handleDelete,
  values,
  setSelectedMarker,
  estimations,
}) => {
  const handleClick = (e, row) => {
    e.stopPropagation();
    const selectedEstimation = estimations.find(
      (estimation) => estimation.id === row.id
    );
    setSelectedMarker(selectedEstimation);
  };

  return (
    <div>
      <div className="row white-space-nowrap" style={{ margin: 20 }}>
        <table className="table table-responsive" style={{ width: "100%" }}>
          <thead style={{ width: "100%" }}>
            <tr style={{ width: "100%" }}>
              {titles.map((title) => (
                <th scope="col" key={title}>
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {data.map((row, index) => (
              <tr
                onClick={(e) => {
                  handleClick(e, row);
                }}
                className="custom_tr"
                key={index}
                style={{ width: "100%" }}
              >
                {Object.keys(row).map((key) => {
                  if (key === "id") {
                    return (
                      <td className="centred_td" key="id">
                        <span>
                          <Button
                            variant="danger"
                            onClick={(e) => handleDelete(e, row.id)}
                          >
                            <span>{values["1"]}</span>
                          </Button>
                        </span>
                        <span className="ml-2">
                          <Link to={"/vmz/" + row.id}>
                            <Button>
                              <span>{values["2"]}</span>
                            </Button>
                          </Link>
                        </span>
                      </td>
                    );
                  } else if (key === "ziaEstimation") {
                    return (
                      <td className="centred_td" key="ziaEstimation">
                        {row.ziaEstimation
                          ? row?.ziaEstimation?.prediction?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }
                            )
                          : "N/A"}
                      </td>
                    );
                  } else
                    return (
                      <td className="centred_td" key={key}>
                        {row[key]}
                      </td>
                    );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomTable;
