import { useEffect, useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";
import { useDispatch, useSelector } from "react-redux";

const SituationPropertyStep = ({ loggedIn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nextStep, goToStep } = useWizard();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    (data) => {
      dispatch(updateFormalizedData({ type: data?.type }));
      nextStep();
    },
    [dispatch, nextStep]
  );

  const handleSelectClick = (item) => {
    setValue("type", item);
    setSelectedItem(item);
  };

  const situationProperty = useMemo(
    () => [
      {
        label: t("Estimate-new.27"),
        text: t("Estimate-new.28"),
        value: "Unifamiliale",
      },
      {
        label: t("Estimate-new.29"),
        text: t("Estimate-new.30"),
        value: "condo",
      },
    ],
    [t]
  );

  useEffect(() => {
    setValue(
      "type",
      formalizedData?.type ? formalizedData?.type : "Unifamiliale"
    );
    setSelectedItem(
      formalizedData?.type ? formalizedData?.type : "Unifamiliale"
    );
  }, [setValue, setSelectedItem, formalizedData?.type]);

  const handlePrevStep = useCallback(() => {
    if (loggedIn === true) {
      goToStep(0);
    } else {
      navigate("/");
    }
  }, [goToStep, navigate, loggedIn]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
          {t("Estimate-new.24")}
        </h2>
        <div className="row row-cols-1 row-cols-sm-2" style={{ rowGap: 20 }}>
          {situationProperty.map((data) => (
            <div className="col" key={data.value}>
              <div
                className={`step-box-1 rounded-2 align-items-start p-4 flex-fill ${
                  selectedItem === data.value ? "active" : ""
                }`}
                onClick={() => handleSelectClick(data.value)}
              >
                <h6 className="mb-2 text-18 text-black-2 font-weight-700 font-poppins">
                  {data.label}
                </h6>
                <div className="bg-violet-o-5 rounded p-2 text-14 text-violet-2 font-weight-normal">
                  {data.text}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={handlePrevStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            disabled={selectedItem === null}
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default SituationPropertyStep;
