import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import LogoUpload from "../components/LogoUpload";
import { Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import swal from "sweetalert2";
import Spinner from "../../../components/spinner";

const InformationDisplayStep = ({
  handleStepNext,
  handleStepPrev,
  setInformation,
  information,
  setUserPhoto,
}) => {
  const [image, setImage] = useState(null);

  const { t } = useTranslation();
  const [imageUploading, setImageUploading] = useState(false);
  const { userData } = useSelector((state) => state.authentication);
  const [url, setUrl] = useState(null);

  const addimage = useCallback(async () => {
    if (image) {
      setImageUploading(true);

      if (
        image.type === "image/png" ||
        image.type === "image/jpeg" ||
        image.type === "image/jpg"
      ) {
        if (image.size > 5000000) {
          setImageUploading(false);
          swal.fire(t("profile.25"), t("profile.28"), "error");
        } else {
          const fileRef = ref(storage, `images/${image.name}`);

          uploadBytes(fileRef, image).then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setUrl(url);
                setUserPhoto(url);
                setImageUploading(false);
              })
              .catch((err) => {
                console.log(err);
                setImageUploading(false);
              });
          });
        }
      } else {
        setImageUploading(false);
        swal.fire(t("profile.25"), t("profile.27"), "error");
      }
    }
  }, [image, t, setUserPhoto]);

  useEffect(() => {
    if (image) {
      addimage();
    }
  }, [image, addimage]);

  const formShema = yup.object().shape({
    officialName: yup.string().required("Please enter official name"),

    officialPhoneNumber: yup
      .string()
      .matches(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
        "Format non valide, XXX-XXX-XXXX est le bon format"
      )
      .required("Please enter official phone number"),

    officialEmail: yup
      .string()
      .email("Please enter valid email")
      .required("Please enter official email"),

    website: yup
      .string()
      .matches(
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
        "Enter correct url!"
      ),

    facebook: yup
      .string()
      .matches(
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
        "Enter correct url!"
      ),

    linkedIn: yup
      .string()
      .matches(
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
        "Enter correct url!"
      ),

    insta: yup
      .string()
      .matches(
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
        "Enter correct url!"
      ),

    twitter: yup
      .string()
      .matches(
        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
        "Enter correct url!"
      ),
    photoUrl: yup.mixed().required("Please select a Picture"),
  });

  const extractFieldValue = useCallback((defaultValueOne, defaultValueTwo) => {
    if (defaultValueOne) {
      return defaultValueOne ? defaultValueOne : "";
    }
    if (defaultValueTwo) {
      return defaultValueTwo ? defaultValueTwo : "";
    }
    return "";
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          officialName: userData?.officialInformation?.officialName
            ? userData?.officialInformation?.officialName
            : userData?.displayName,
          officialPhoneNumber: userData?.officialInformation
            ?.officialPhoneNumber
            ? userData?.officialInformation?.officialPhoneNumber
            : userData?.phoneNumber,
          officialEmail: userData?.officialInformation?.officialEmail
            ? userData?.officialInformation?.officialEmail
            : userData?.email,
          photoUrl: url,
          website: extractFieldValue(
            userData?.officialInformation?.website,
            information?.website
          ),
          facebook: extractFieldValue(
            userData?.officialInformation?.facebook,
            information?.facebook
          ),
          linkedIn: extractFieldValue(
            userData?.officialInformation?.linkedIn,
            information?.linkedIn
          ),
          insta: extractFieldValue(
            userData?.officialInformation?.insta,
            information?.insta
          ),
          twitter: extractFieldValue(
            userData?.officialInformation?.twitter,
            information?.twitter
          ),
        }}
        enableReinitialize={true}
        validationSchema={formShema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setInformation(values);
          handleStepNext();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {imageUploading && <Spinner />}
            <div>
              <h4 className="mb-4 text-violet text-18">{t("Sign_up.51")}</h4>
              <h4 className="mb-4 text-black text-18">{t("Sign_up.52")}</h4>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  {t("Sign_up.53")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="officialName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.officialName}
                />
                {errors.officialName && errors.officialName && (
                  <div className="text-danger mt-1 ml-2">
                    {errors.officialName}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  {t("Sign_up.54")}
                </label>
                <input
                  className="form-control"
                  id="officialPhoneNumber"
                  value={values.officialPhoneNumber}
                  name="officialPhoneNumber"
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                />
                {errors.officialPhoneNumber && touched.officialPhoneNumber && (
                  <div className="text-danger mt-1 ml-2">
                    {errors.officialPhoneNumber}
                  </div>
                )}
              </div>
              <div className="mb-4 h-100 w-100">
                <label className="mb-0 text-black font-weight-normal text-14">
                  {t("Sign_up.55")}
                </label>
                <p className="mb-3 text-black-50 font-weight-normal text-14">
                  {t("Sign_up.56")}
                </p>
                <LogoUpload
                  defaultUrl={"/assets/packages/placeholder.png"}
                  setImage={setImage}
                  url={url}
                  text={t("Sign_up.78")}
                  type="photo"
                />

                {errors.photoUrl && touched.photoUrl && (
                  <div className="text-danger mt-1 ml-2">{errors.photoUrl}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  {t("Sign_up.57")}
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="officialEmail"
                  disabled=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.officialEmail}
                />
                {errors.officialEmail && touched.officialEmail && (
                  <div className="text-danger mt-1 ml-2">
                    {errors.officialEmail}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  {t("Sign_up.58")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="website"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.website}
                />
                {errors.website && touched.website && (
                  <div className="text-danger mt-1 ml-2">{errors.website}</div>
                )}
              </div>
              <h4 className="mb-4 text-black text-18">{t("Sign_up.61")}</h4>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  Facebook
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="facebook"
                  disabled=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.facebook}
                />
                {errors.facebook && touched.facebook && (
                  <div className="text-danger mt-1 ml-2">{errors.facebook}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  LinkedIn
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="linkedIn"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.linkedIn}
                />
                {errors.linkedIn && touched.linkedIn && (
                  <div className="text-danger mt-1 ml-2">{errors.linkedIn}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  Instagram
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="insta"
                  disabled=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.insta}
                />
                {errors.insta && touched.insta && (
                  <div className="text-danger mt-1 ml-2">{errors.insta}</div>
                )}
              </div>
              <div className="mb-4">
                <label className="text-black font-weight-normal text-14">
                  Twitter
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="twitter"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.twitter}
                />
                {errors.twitter && touched.twitter && (
                  <div className="text-danger mt-1 ml-2">{errors.twitter}</div>
                )}
              </div>
              <div className=" pb-4 text-right mt-5 ">
                <button
                  type="submit"
                  className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
                >
                  {t("Sign_up.60")}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default InformationDisplayStep;
