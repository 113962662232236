import { useTranslation } from "react-i18next";

const RefreshButton = ({ refresh }) => {
  const { t } = useTranslation();
  return (
    <div
      className="refresh_button"
      title={t("Leads.120")}
      onClick={() => refresh()}
    >
      <img
        src={`assets/icons/reload.svg`}
        alt="icon"
        style={{
          maxWidth: "16px",
          marginRight: "5px",
        }}
      />
      {t("Leads.119")}
    </div>
  );
};
export default RefreshButton;
