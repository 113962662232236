import {
  setEstimations,
  setLoading,
  setLastFetchedEstimation,
} from "../myEstimationsSlice";
import { db } from "../../../../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDoc,
  doc,
  startAt,
} from "firebase/firestore";

const fetchMoreEstimations = (userId) => async (dispatch, getState) => {
  try {
    const { fixedLimit, count, lastFetchedEstimation, estimations } =
      getState().myEstimations;

    const newLimit = count + fixedLimit;

    const estimationsRef = collection(db, "RapportsEvaluations");
    const lastFetchedEstimationSnapshot = await getDoc(
      doc(estimationsRef, lastFetchedEstimation)
    );
    dispatch(setLoading(true));
    const newEstimations = [];
    const q = query(
      collection(db, "RapportsEvaluations"),
      where("userID", "==", userId),
      orderBy("dateCreation", "desc"),
      limit(newLimit + 1),
      startAt(lastFetchedEstimationSnapshot)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      const data = doc.data();
      const docData = {
        id: doc.id,
        ...data,
      };
      newEstimations.push(docData);
    });

    dispatch(
      setEstimations([...estimations, ...newEstimations.slice(0, fixedLimit)])
    );
    dispatch(
      setLastFetchedEstimation(
        newEstimations.length > fixedLimit
          ? newEstimations[newEstimations.length - 1]?.id
          : null
      )
    );

    dispatch(setLoading(false));
  } catch (error) {
    console.log("getMyEstimations_error", error);
    dispatch(setLoading(false));
  }
};

export default fetchMoreEstimations;
