import UserProfile from "./UserProfile";
import AuthGuard from "../../guards/AuthGuard";

const monCompteRoutes = [
  {
    path: "/moncompte",
    element: <AuthGuard component={<UserProfile />} />,
  },
];

export default monCompteRoutes;
