import { useTranslation } from "react-i18next";
import { Nav } from "react-bootstrap";

const Opportunities = ({
  updateShownProspects,
  evaluationsLength,
  myEvaluationsLength,
  updateSortedBy,
  updateSortingType,
}) => {
  const { t } = useTranslation();

  const opportunitiesLength = evaluationsLength;
  const mesOpportunitiesLength = myEvaluationsLength;
  return (
    <div className="my-2">
      <Nav
        variant="pills"
        className="text-uppercase w-100 overflow-auto text-nowrap flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-start"
        defaultActiveKey="opportunities"
      >
        <Nav.Item
          className="newleads_opportunities"
          onClick={() => {
            updateSortedBy(null);
            updateSortingType(null);
            updateShownProspects("opportunities");
          }}
        >
          <Nav.Link eventKey="opportunities">{`${t(
            "Leads.88"
          )} (${opportunitiesLength})`}</Nav.Link>
        </Nav.Item>
        <Nav.Item
          className="newleads_opportunities"
          onClick={() => {
            updateSortedBy(null);
            updateSortingType(null);
            updateShownProspects("mesopportunities");
          }}
        >
          <Nav.Link eventKey="mesOpportunities">{`${t(
            "Leads.89"
          )} (${mesOpportunitiesLength})`}</Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default Opportunities;
