import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setUserData } from "../../../redux/slices/authentication/authenticationSlice";

const removeContact = (contact, setLoading, dispatch, profile) => {
  setLoading(true);
  const remove = httpsCallable(functions, "removeContact");
  const response = remove({ contact })
    .then((res) => {
      let bookmarks = profile?.bookmarks;
      let newBookmarks = bookmarks.filter((item) => item.id !== contact.id);
      dispatch(setUserData({ ...profile, bookmarks: newBookmarks }));
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });

  return response;
};

export default removeContact;
