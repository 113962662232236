import Signup from "./register";
import Signin from "./login";
import ForgotPassword from "./ForgotPassword";
import Error404 from "./Error";
import UnAuthGuard from "../../guards/UnAuthGuard";
import ResetPasswordPage from "./ResetPasswordPage";

const SessionsRoutes = [
  {
    path: "/signup",
    element: <UnAuthGuard component={<Signup />} />,
  },
  {
    path: "/signin",
    element: <UnAuthGuard component={<Signin />} />,
  },
  {
    path: "/forgot-password",
    element: <UnAuthGuard component={<ForgotPassword />} />,
  },
  {
    path: "/reset-password",
    element: <UnAuthGuard component={<ResetPasswordPage />} />,
  },
  {
    path: "/404",
    Component: Error404,
  },
];

export default SessionsRoutes;
