import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const YourMessagesStep = ({
  setMessages,
  handleStepNext,
  handleStepPrev,
  messages,
}) => {
  const { userData } = useSelector((state) => state.authentication);
  const { t } = useTranslation();

  const extractFieldValue = useCallback(
    (defaultValueOne, defaultValueTwo, df) => {
      if (defaultValueOne) {
        return defaultValueOne ? defaultValueOne : "";
      }
      if (defaultValueTwo) {
        return defaultValueTwo ? defaultValueTwo : "";
      }
      return df;
    },
    []
  );

  const onStepPrev = useCallback(
    (values) => {
      setMessages(values);
      handleStepPrev();
    },
    [handleStepPrev, setMessages]
  );

  return (
    <Formik
      initialValues={{
        emailText: extractFieldValue(
          messages?.emailText,
          userData?.messages?.emailText,
          "Je vous confirme avoir bien reçu votre demande et vous remercie de votre confiance. ​\nJe vous contacterai très prochainement pour avoir plus d'informations sur votre projet avant de convenir d'un rendez-vous dans les meilleurs délais. ​\n ​\nN'hésitez pas à me contacter directement, vous trouverez mes coordonnées ci-dessous.​\n ​\nDans l'attente de notre prochaine rencontre, je vous souhaite une très belle journée.​\n"
        ),
        smsText: extractFieldValue(
          messages?.smsText,
          userData?.messages?.smsText,
          "Je confirme que j'ai bien reçu votre demande et je vous remercie de votre confiance. ​\nJe vous contacterai très prochainement ​\n"
        ),
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        console.log(errors);
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        setMessages(values);
        handleStepNext();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <h4 className="mb-4 text-violet text-18">{t("Sign_up.69")}</h4>

            <div className="mb-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.70")}
              </label>
              <textarea
                rows="10"
                type="text"
                className="form-control text-pre-line"
                name="emailText"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailText}
              />
            </div>
            <div className="mb-4">
              <label className="text-black font-weight-normal text-14">
                {t("Sign_up.71")}
              </label>
              <textarea
                rows="10"
                type="text"
                className="form-control text-pre-line"
                name="smsText"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.smsText}
              />
            </div>
          </div>
          <div className=" pb-4 text-right mt-5 ">
            <button
              type="button"
              className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
              onClick={() => onStepPrev(values)}
            >
              {t("Sign_up.59")}
            </button>
            <button
              type="submit"
              className="btn btn-lg btn-outline-primary rounded-xl px-4 mr-3"
            >
              {t("Sign_up.60")}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default YourMessagesStep;
