import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const getNotes = (contactId, setLoading) => {
  setLoading(true);
  const get = httpsCallable(functions, "getNotes");
  const response = get({ contactId })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });

  return response;
};

export default getNotes;
