import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { setUserMessages } from "../authenticationSlice";
import swal from "sweetalert2";
import { PushToZoho } from "../../../../utils/pushToZoho";

export function updateUserMessages(
  values,
  successTitle,
  successMessage,
  errorTitle,
  errorMessage,
  userData,
  followUpFunc
) {
  return (dispatch) => {
    try {
      const userId = userData.id;
      const json_data = {
        info: {
          data: [
            {
              Agency: userData?.regulatory?.agency || "",

              Authorized_field_of_practice:
                userData?.regulatory?.practice?.value || "",

              Business_adress:
                userData?.regulatory?.BusinessAddress.value || "",

              E_mail: userData?.officialInformation?.officialEmail || "",

              Email: userData?.email || "",

              Licence_status: userData?.regulatory?.status?.value || "",

              First_and_last_name:
                userData?.officialInformation?.officialName || "",

              Phone: userData?.officialInformation?.officialPhoneNumber || "",

              Licence_Number: userData?.licenseId || "",

              Last_Name: userData?.displayName || "",

              Mail_message: values?.emailText || "",

              SMS_Message: values?.smsText || "",

              Broker_Photo: userData?.image || "",

              Agency_Logo: userData?.logo || "",

              Cellulaire: userData?.phoneNumber || "",

              Facebook: userData?.officialInformation?.facebook || "",

              Linkedin: userData?.officialInformation?.linkedIn || "",

              Twitter_Address: userData?.officialInformation?.twitter || "",

              Website: userData?.officialInformation?.website || "",

              Instagram: userData?.officialInformation?.insta || "",
            },
          ],

          duplicate_check_fields: ["Licence_Number"],
        },

        vmodule: "Contacts/upsert",
      };
      const docRef = doc(db, "users", userId);
      updateDoc(docRef, { messages: values });
      if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
        PushToZoho(json_data);
      }
      dispatch(setUserMessages(values));
      followUpFunc();
      swal.fire(successTitle, successMessage, "success");
    } catch (error) {
      console.log(error);
      swal.fire(errorTitle, errorMessage, "error");
    }
  };
}
