import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SelectField from "./selectField";
import DropDownFilter from "./dropdownFilter";
import MultilevelSelectWithCheckboxes from "./multiLevelSelect";
import Summary from "./Summary";

export default function Filters(props) {
  const {
    cities,
    cityValue,
    onCityChange,
    muncipalities,
    munciValue,
    ownerValue,
    onOwnerChange,
    onStatusChange,
    onDaysSubtract,
    refreshFilter,
    onDateChange,
    finalDate,
    setFinalDate,
    initialDate,
    setInitialDate,
    projectValue,
    projectStatus,
    dateFilterType = "31days",
    setDateFilterType,
    userFilters,
    currentMunicipality,
    setCurrentMunicipality,
    updateFilterSummary,
    filters,
  } = props;
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const options = [
    { value: "oui", label: t("Leads.77") },
    { value: "non", label: t("Leads.78") },
    { value: "all", label: t("Leads.79") },
  ];
  const formatter = (data) => {
    return data?.map((v) => ({
      value: v,
      label: v,
    }));
  };

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (window) window.addEventListener("resize", setWindowDimensions);
    return () => {
      if (window) window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  return (
    <div className="pb-4 mx-2 ">
      <Summary
        munciValue={munciValue}
        filterSummary={userFilters?.filterSummary}
      />
      <div className="row px-2 newleads_filter">
        <h4>{t("Leads.90")}</h4>
        <ul className="nav row ">
          <li className="col-12 mb-2 mb-lg-0 col-lg-4">
            <MultilevelSelectWithCheckboxes
              cityValue={cityValue}
              filters={filters}
              onCityChange={onCityChange}
              cities={cities}
              muncipalities={muncipalities}
              munciValue={munciValue}
              userFilters={userFilters}
              currentMunicipality={currentMunicipality}
              setCurrentMunicipality={setCurrentMunicipality}
              updateFilterSummary={updateFilterSummary}
              filterSummary={userFilters?.filterSummary}
            />
          </li>
          <li className="col-12 mb-2 mb-lg-0 col-lg-3">
            <SelectField
              options={options}
              placeholder={t("Leads.56")}
              value={ownerValue}
              onChange={onOwnerChange}
            />
          </li>
          <li className="col-12 mb-2 mb-lg-0 col-lg-3">
            <SelectField
              isDisabled={!ownerValue || ownerValue.value === "all"}
              placeholder={t("Leads.92")}
              value={projectValue}
              options={formatter(projectStatus)}
              onChange={(e) => onStatusChange(e)}
            />
          </li>
          <DropDownFilter
            setMenu={setMenu}
            menu={menu}
            windowWidth={windowWidth}
            dateFilterType={dateFilterType}
            onDaysSubtract={onDaysSubtract}
            setDateFilterType={setDateFilterType}
            setInitialDate={setInitialDate}
            initialDate={initialDate}
            setFinalDate={setFinalDate}
            finalDate={finalDate}
            onDateChange={onDateChange}
            refreshFilter={refreshFilter}
          />
        </ul>
      </div>
    </div>
  );
}
