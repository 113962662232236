import {
  setSubMessage,
  setSubLoading,
  setSubError,
} from "../subscriptionSlice";
import { functions, auth } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { signInWithCustomToken } from "firebase/auth";
import { toast } from "react-toastify";
import { PushToZoho } from "../../../../utils/pushToZoho";

export function createSubscription(data, t, navigate) {
  return (dispatch) => {
    dispatch(setSubLoading(true));
    const createSubs = httpsCallable(functions, "createSubscription");
    createSubs(data)
      .then((res) => {
        if (res.data && res.data.error) {
          dispatch(setSubLoading(false));
          dispatch(setSubError(res.data.message));
          dispatch(setSubMessage(res.data.message));
        } else {
          toast.success(t("subscription.welcome"));
          signInWithCustomToken(auth, res.data.token).then((result) => {
            const json_data = {
              info: {
                data: [
                  {
                    Agency: "",

                    Authorized_field_of_practice: "",

                    Business_adress: "",

                    E_mail: "",

                    Email: result.user.email || "",

                    Licence_status: "",

                    First_and_last_name: result.user.displayName || "",

                    Phone: "",

                    Licence_Number: data?.licenseId || "",

                    Licence_Id_Found:
                      data?.licenceIdFound === false ? "no" : "yes",

                    Last_Name: result.user.displayName || "",

                    Mail_message:
                      "Je vous confirme avoir bien reçu votre demande et vous remercie de votre confiance. ​\nJe vous contacterai très prochainement pour avoir plus d'informations sur votre projet avant de convenir d'un rendez-vous dans les meilleurs délais. ​\n ​\nN'hésitez pas à me contacter directement, vous trouverez mes coordonnées ci-dessous.​\n ​\nDans l'attente de notre prochaine rencontre, je vous souhaite une très belle journée.​\n",

                    SMS_Message:
                      "Je confirme que j'ai bien reçu votre demande et je vous remercie de votre confiance. ​\nJe vous contacterai très prochainement ​\n",

                    Broker_Photo: "",

                    Agency_Logo: "",

                    Cellulaire: res.data.phoneNumber || "",

                    Facebook: "",

                    Linkedin: "",

                    Twitter_Address: "",

                    Website: "",

                    Instagram: "",
                  },
                ],

                duplicate_check_fields: ["Licence_Number"],
              },

              vmodule: "Contacts/upsert",
            };

            if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
              PushToZoho(json_data)
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }

            navigate("/profile-create");
          });
          dispatch(setSubLoading(false));
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
}
