import { useState, useEffect, useCallback } from "react";
import Spinner from "../../components/spinner";
import { Tab } from "react-bootstrap";
import swal from "sweetalert2";
import ResetPassword from "./sections/ResetPassword";
import Regulatory from "./sections/Regulatory";
import UserMessages from "./sections/UserMessages";
import Preview from "./sections/Preview";
import { PushToZoho } from "../../utils/pushToZoho";
import { useTranslation } from "react-i18next";
import "./style.scss";
import PersonalInfo from "./sections/PersonalInfo";
import { updateUserData } from "../../redux/slices/authentication/actions/updateUserData";
import InternetInfo from "./sections/InternetInfo";
import DesktopPreview from "./sections/DesktopPreview";
import { storage, db } from "../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useSelector, useDispatch } from "react-redux";
import { updateUserImage } from "../../redux/slices/authentication/actions/updateUserImage";

const UserProfile = () => {
  const { userData, loadingUserData } = useSelector(
    (state) => state.authentication
  );
  const dispatch = useDispatch();

  console.log("loadingUserData", loadingUserData);

  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("profil");
  const [imageUploading, setImageUploading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addimage = useCallback(async () => {
    if (image) {
      setImageUploading(true);

      if (
        image.type === "image/png" ||
        image.type === "image/jpeg" ||
        image.type === "image/jpg"
      ) {
        if (image.size > 5000000) {
          setImageUploading(false);
          swal.fire(t("profile.25"), t("profile.28"), "error");
        } else {
          const fileRef = ref(storage, `images/${image.name}`);

          uploadBytes(fileRef, image).then((snapshot) => {
            console.log("Uploaded a blob or file!", snapshot.ref);
            getDownloadURL(snapshot.ref).then((url) => {
              updateDoc(doc(db, "users", userData.id), {
                image: url,
              })
                .then((res) => {
                  dispatch(updateUserImage(url));

                  const json_data = {
                    info: {
                      data: [
                        {
                          Agency: userData?.regulatory?.agency || "",

                          Authorized_field_of_practice:
                            userData?.regulatory?.practice?.value || "",

                          Business_adress:
                            userData?.regulatory?.BusinessAddress.value || "",

                          E_mail:
                            userData?.officialInformation?.officialEmail || "",

                          Email: userData?.email || "",

                          Licence_status:
                            userData?.regulatory?.status?.value || "",

                          First_and_last_name:
                            userData?.officialInformation?.officialName || "",

                          Phone:
                            userData?.officialInformation
                              ?.officialPhoneNumber || "",

                          Licence_Number: userData?.licenseId || "",

                          Last_Name: userData?.displayName || "",

                          Mail_message: userData?.messages?.emailText || "",

                          SMS_Message: userData?.messages?.smsText || "",

                          Broker_Photo: url || "",

                          Agency_Logo: userData?.logo || "",

                          Cellulaire: userData?.phoneNumber || "",

                          Facebook:
                            userData?.officialInformation?.facebook || "",

                          Linkedin:
                            userData?.officialInformation?.linkedIn || "",

                          Twitter_Address:
                            userData?.officialInformation?.twitter || "",

                          Website: userData?.officialInformation?.website || "",

                          Instagram: userData?.officialInformation?.insta || "",
                        },
                      ],

                      duplicate_check_fields: ["Licence_Number"],
                    },

                    vmodule: "Contacts/upsert",
                  };
                  Object.entries(json_data.info).forEach((sub) => {
                    if (!sub[1]) {
                      delete json_data.info[sub[0]];
                    }
                  });

                  if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
                    PushToZoho(json_data)
                      .then((res) => console.log(res))
                      .catch((err) => console.log(err));
                  }

                  setImageUploading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setImageUploading(false);
                });
            });
          });
        }
      } else {
        setImageUploading(false);
        swal.fire(t("profile.25"), t("profile.27"), "error");
      }
    }
  }, [image, userData, dispatch, t]);

  useEffect(() => {
    if (image) {
      addimage();
    }
  }, [image]);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback((e) => {
    e.preventDefault();
    setShow(true);
  }, []);

  const updateZoho = useCallback(
    (values) => {
      const json_data = {
        info: {
          data: [
            {
              Agency: userData?.regulatory?.agency || "",

              Authorized_field_of_practice:
                userData?.regulatory?.practice?.value || "",

              Business_adress:
                userData?.regulatory?.BusinessAddress.value || "",

              E_mail: userData?.officialInformation?.officialEmail || "",

              Email: values.email,

              Licence_status: userData?.regulatory?.status?.value || "",

              First_and_last_name:
                userData?.officialInformation?.officialName || "",

              Phone: userData?.officialInformation?.officialPhoneNumber || "",

              Licence_Number: userData?.licenseId || "",

              Last_Name: values?.displayName,

              Mail_message: userData?.messages?.emailText || "",

              SMS_Message: userData?.messages?.smsText || "",

              Broker_Photo: userData?.image || "",

              Agency_Logo: userData?.logo || "",

              Cellulaire: values?.phoneNumber,

              Facebook: userData?.officialInformation?.facebook || "",

              Linkedin: userData?.officialInformation?.linkedIn || "",

              Twitter_Address: userData?.officialInformation?.twitter || "",

              Website: userData?.officialInformation?.website || "",

              Instagram: userData?.officialInformation?.insta || "",
            },
          ],

          duplicate_check_fields: ["Licence_Number"],
        },

        vmodule: "Contacts/upsert",
      };

      if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
        PushToZoho(json_data)
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    },
    [userData]
  );

  return (
    <>
      {imageUploading && <Spinner />}
      {!loadingUserData ? (
        <div>
          {windowWidth < 992 && (
            <Preview
              show={show}
              onClose={() => onHide()}
              profile={userData}
              t={t}
            />
          )}
          {!loadingUserData && (
            <div>
              <div>
                <Tab.Container
                  id="left-tabs-example"
                  activeKey={activeTab}
                  defaultActiveKey={activeTab}
                  onSelect={(tab) => setActiveTab(tab || "profil")}
                >
                  <button
                    className={
                      activeTab === "profil" ? "active-tab" : "inactive-tab"
                    }
                    onClick={() => setActiveTab("profil")}
                  >
                    {t("profile.15")}
                  </button>
                  <button
                    className={
                      activeTab === "userMessages"
                        ? "active-tab"
                        : "inactive-tab"
                    }
                    onClick={() => setActiveTab("userMessages")}
                  >
                    {t("profile.usermessages.1")}
                  </button>
                  <button
                    className={
                      activeTab === "reset" ? "active-tab" : "inactive-tab"
                    }
                    onClick={() => setActiveTab("reset")}
                  >
                    {t("profile.16")}
                  </button>
                  <Tab.Content>
                    <Tab.Pane eventKey="profil" mountOnEnter unmountOnExit>
                      {windowWidth < 992 && (
                        <div className="mb-4">
                          <ul
                            className="nav justify-content-end"
                            style={{ gap: 10 }}
                          >
                            <li>
                              <button
                                className="btn btn-primary"
                                onClick={(e) => onShow(e)}
                              >
                                {t("NProfile.1")}
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12 col-lg-9">
                          <PersonalInfo
                            profile={userData}
                            updateInfosPersonnelles={updateUserData}
                            addimage={addimage}
                            updateZoho={updateZoho}
                            t={t}
                            setImage={setImage}
                            imageUploading={imageUploading}
                          />
                          <Regulatory t={t} />
                          <InternetInfo profile={userData} t={t} />
                        </div>
                        <DesktopPreview profile={userData} t={t} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="userMessages"
                      mountOnEnter
                      unmountOnExit
                    >
                      <UserMessages t={t} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="reset" mountOnEnter unmountOnExit>
                      <ResetPassword t={t} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default UserProfile;
