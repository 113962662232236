import Select from "react-select";

const SelectField = ({ options, placeholder, onChange, value, isDisabled }) => {
  return (
    <div>
      <Select
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        className="newleads_filter-select"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectField;
