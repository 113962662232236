import AuthGuard from "../../guards/AuthGuard";
import NewEstimationLoggedIn from "./components/NewEstimationLoggedIn";
import { authRoles } from "../../guards/authRoles";

const evaluationRoute = [
  {
    path: "/evaluation-bien",
    element: <AuthGuard component={<NewEstimationLoggedIn />} />,
    auth: authRoles.Essentiel,
  },
];

export default evaluationRoute;
