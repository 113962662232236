import { auth } from "../../../../firebase";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

export const suspendAccount = (
  password,
  reactivationDate,
  suspendDate,
  setLoading,
  setPassword,
  setModal,
  modal,
  t
) => {
  return (dispatch) => {
    setLoading(true);

    if (!suspendDate) {
      toast.error(t("profile.subscription.current_plan.14"));
      return;
    }
    if (!reactivationDate) {
      toast.error(t("profile.subscription.current_plan.15"));
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    reauthenticateWithCredential(user, credential)
      .then(() => {
        const suspendUser = httpsCallable(functions, "suspendAccount");
        suspendUser({ reactivationDate, suspendDate })
          .then((res) => {
            setModal(!modal);
            if (res.data.success) {
              setLoading(false);
              setPassword("");
              toast.success(
                `Your account will be suspended at ${suspendDate}, it will be activated when you log in again, or at ${reactivationDate}`
              );
            } else {
              setLoading(false);
              setPassword("");
              toast.error(res.data.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            setPassword("");
            setModal(!modal);
            toast.error(e.message);
          });
      })
      .catch((err) => {
        setLoading(false);
        setPassword("");
        setModal(!modal);
        if (err.code === "auth/wrong-password") {
          toast.error(t("profile.subscription.current_plan.16"));
        } else toast.error(err.message);
      });
  };
};
