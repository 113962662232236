import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import {
  setUserData,
  setLoadingUserData,
  setUser,
} from "../authenticationSlice";
export const getUserData = (uid, dontLoad) => {
  return async (dispatch, getState) => {
    try {
      if (!dontLoad) dispatch(setLoadingUserData(true));
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.lastAcceptedContactDate) {
          userData.lastAcceptedContactDate =
            userData.lastAcceptedContactDate.toMillis();
        }
        dispatch(setUser(uid));
        dispatch(setUserData(userData));
        dispatch(setLoadingUserData(false));
      } else {
        console.log("No such document!");
        dispatch(setLoadingUserData(false));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
};
