import { useState, useEffect } from "react";
import "../../packages.scss";
import { useDispatch, useSelector } from "react-redux";
// import { setSubscriptionData } from "app/redux/actions/SubscriptionActions";
import { setPlan } from "../../../../redux/slices/subscription/subscriptionSlice";
import { useTranslation } from "react-i18next";
// import { Loading } from "@gull";
import Spinner from "../../../spinner";

const PacksSelection = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const plans = useSelector((state) => state.subscription.plans);

  console.log("plans", plans);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packImage, setPackImage] = useState(null);

  const abounements = ["month", "year"];

  const [selectedAbounement, setSelectedAbounement] = useState(abounements[0]);

  const [displayedPlans, setDisplayedPlans] = useState([]);

  useEffect(() => {
    setDisplayedPlans(plans[selectedAbounement]);
  }, [plans, selectedAbounement]);

  useEffect(() => {
    if (displayedPlans && selectedAbounement) {
      setSelectedPackage(
        displayedPlans.filter((item) => item?.name === "Performance")[0]
      );
    }
  }, [displayedPlans, selectedAbounement]);

  useEffect(() => {
    if (selectedPackage) {
      const imageFromMetadata = selectedPackage?.metadata.find(
        (el) => el.key === "image"
      )?.value;
      setPackImage(imageFromMetadata);
      dispatch(setPlan(selectedPackage));
    }
  }, [selectedPackage]);

  return (
    <div>
      {selectedPackage ? (
        <>
          <h4 className="mb-4 text-capitalize">{t("Sign_up.110")}</h4>
          <div
            className="bg-violet bg-cover rounded-xl d-flex flex-column justify-content-center align-items-center"
            style={{
              height: "170px",
              width: "255px",
              maxWidth: "60%",
              backgroundImage: `url(/assets/packages/bg-p.svg)`,
            }}
          >
            {selectedPackage && (
              <div className="step-icon">
                <img src={packImage} alt="step-icon" />
              </div>
            )}
            <h4 className="text-white font-weight-bold text-capitalize mt-3">
              {selectedPackage?.name}
            </h4>
          </div>
          <h6 className="mt-4 mb-3 packsSelection_packs_title">Plan :</h6>
          <ul className="d-flex flex-row justify-content-between align-items-center mb-4 packsSelection_packs">
            {displayedPlans &&
              displayedPlans.map((item) => {
                return (
                  <li key={item?.id}>
                    <label
                      htmlFor={item?.interval}
                      className={
                        selectedPackage?.id === item?.id
                          ? "packsSelection_pack_active"
                          : "packsSelection_pack"
                      }
                      onClick={() => setSelectedPackage(item)}
                    >
                      <div className="d-flex align-items-start">
                        <div className="">
                          <h6 className="mb-0 text-14 font-weight-bold text-capitalize">
                            {`${item?.name} - `}
                          </h6>
                          {/* <p className="mb-0 text-12">{item?.description}</p> */}
                        </div>
                        <h6 className="mb-0 text-14 font-weight-normal">
                          $
                          {new Intl.NumberFormat("us-US", {
                            maximumFractionDigits: 2,
                          }).format(item?.amount / 100)}
                        </h6>
                      </div>
                    </label>
                  </li>
                );
              })}
          </ul>
          <h6 className="mt-4 mb-3 packsSelection_packs_title">{`${t(
            "Sign_up.111"
          )}  :`}</h6>
          <div
            className="d-flex flex-row justify-content-start align-items-center mb-4"
            style={{ gap: "2.5rem" }}
          >
            {abounements.map((item) => {
              return (
                <div
                  key={item}
                  className={
                    selectedAbounement === item
                      ? "packsSelection_pack_active"
                      : "packsSelection_pack"
                  }
                  onClick={() => setSelectedAbounement(item)}
                >
                  {t(`Sign_up.${item}`)}
                </div>
              );
            })}
          </div>

          <div className="d-flex flex-row justify-content-between align-items-center mt-3 mb-4 total_container">
            <h5 style={{ fontWeight: "600" }}>{`${t("Sign_up.112")}:`}</h5>
            <h5 style={{ fontWeight: "400" }}>
              $
              {new Intl.NumberFormat("us-US", {
                maximumFractionDigits: 2,
              }).format(selectedPackage?.amount / 100)}{" "}
              / {t(`Sign_up.${selectedAbounement}Unit`)}
            </h5>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default PacksSelection;
