import { createSlice } from "@reduxjs/toolkit";

export const layoutSettingSlice = createSlice({
  name: "layoutSetting",
  initialState: {
    leftSidebar: {
      open: true,
      secondaryNavOpen: false,
      theme: "sidebar-dark-purple",
    },
    footer: {
      show: true,
    },
  },
  reducers: {
    setLeftSidebarOpen: (state, action) => {
      state.leftSidebar.open = action.payload;
    },
    setFooterShow: (state, action) => {
      state.footer.show = action.payload;
    },
  },
});

export const { setLeftSidebarOpen, setFooterShow } = layoutSettingSlice.actions;

export default layoutSettingSlice.reducer;
