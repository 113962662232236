import { db } from "../../../../firebase";
import { setLoading, setSales } from "../salesSlice";
import { deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";

const deleteSale = (saleId, setIsModal) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      await deleteDoc(doc(db, "sales", saleId));
      const state = getState();
      const statesList = state.sales.sales;
      const updatedSales = statesList.filter((item) => item.id !== saleId);

      dispatch(setSales(updatedSales));
      dispatch(setLoading(false));
      setIsModal(false);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      dispatch(setLoading(false));
      setIsModal(false);
    }
  };
};

export default deleteSale;
