import { setProjectValue, setOwnerValue } from "../leadsSlice";
import { getUserDataAction } from "./getUserDataAction";

export const refreshUserDataAction = () => {
  return async (dispatch, getState) => {
    await getUserDataAction();

    dispatch(setProjectValue(null));
    dispatch(setOwnerValue(null));
  };
};
