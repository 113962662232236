import { useState } from "react";
import { useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import Sale from "./components/Sale";
import SideSlide from "./SideSlide";
import Modall from "./utils/Modal";
import { fetchSales } from "../../../redux/slices/sales/actions/fetchSales";
import fetchMoreSales from "../../../redux/slices/sales/actions/fetchMoreSales";
import deleteSale from "../../../redux/slices/sales/actions/deleteSale";
import { useDispatch, useSelector } from "react-redux";

function MySalesList({
  t,
  setSelectedSale,
  selectedSale,
  showModal,
  setShowModal,
}) {
  const [isModal, setIsModal] = useState(false);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const {
    sales: salesList,
    loading: fetchingSales,
    lastFetchedSale,
    loadingMore,
  } = useSelector((state) => state.sales);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) dispatch(fetchSales(user));
  }, [user, dispatch]);

  if (fetchingSales) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden"></span>
      </Spinner>
    );
  }
  return (
    <div className="">
      <SideSlide
        show={showModal && selectedSale !== null}
        onHide={() => {
          setShowModal(false);
          setSelectedSale(null);
          setIsEditing(false);
        }}
        selectedSale={selectedSale}
        t={t}
        isDeleting={isModal}
        setIsDeleting={setIsModal}
        setShowModal={setShowModal}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {salesList.length === 0 && !fetchingSales ? (
        <div> {t("profile.sales.52")}</div>
      ) : (
        <div>
          <div className="profile_sales_container">
            {salesList.map((item, index) => (
              <Sale
                key={item.id}
                sale={item}
                t={t}
                setSelectedSale={setSelectedSale}
                selectedSale={selectedSale}
              />
            ))}
          </div>
          {lastFetchedSale && (
            <div className="profile_sales_btn">
              {loadingMore ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => {
                    dispatch(fetchMoreSales(user));
                  }}
                >
                  {t("profile.sales.81")}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
      {isModal && (
        <Modall
          setIsModal={setIsModal}
          setSelectedSale={setSelectedSale}
          onClick={() => dispatch(deleteSale(selectedSale?.id, setIsModal))}
          t={t}
        />
      )}
    </div>
  );
}

export default MySalesList;
