import moment from "moment";
import getCurrentMonthWeeks from "../../../../pages/dashboard/utils/getCurrentMonthWeeks";
import sortLeads from "../../../../pages/dashboard/utils/sortLeads";
import getLeadsCountPerWeek from "../../../../pages/dashboard/utils/getLeadsCountPerWeek";
import {
  setLastFourWeeksStrings,
  setMyContactsLineData,
  setAllLeadsLineData,
} from "../leadsSlice";
import { toast } from "react-toastify";

export const getEchartLines = (myContacts) => async (dispatch) => {
  try {
    const lastFourWeeks = getCurrentMonthWeeks();

    const lastFourWeeksStrings = await lastFourWeeks.map((week) => {
      return moment(week).format("YYYY-MM-DD");
    });
    const firstDay = lastFourWeeks[0];
    firstDay.setDate(firstDay.getDate() - 7);

    const firstDayString = moment(firstDay).format("YYYY-MM-DD");

    const myLineData = [
      sortLeads(myContacts, new Date(firstDay.getTime()), lastFourWeeks[0])
        .length,
      sortLeads(myContacts, lastFourWeeks[0], lastFourWeeks[1]).length,
      sortLeads(myContacts, lastFourWeeks[1], lastFourWeeks[2]).length,
      sortLeads(myContacts, lastFourWeeks[2], lastFourWeeks[3]).length,
    ];

    const lineData = await Promise.all([
      getLeadsCountPerWeek(firstDayString, lastFourWeeksStrings[0]),
      getLeadsCountPerWeek(lastFourWeeksStrings[0], lastFourWeeksStrings[1]),
      getLeadsCountPerWeek(lastFourWeeksStrings[1], lastFourWeeksStrings[2]),
      getLeadsCountPerWeek(lastFourWeeksStrings[2], lastFourWeeksStrings[3]),
    ]);

    dispatch(setLastFourWeeksStrings(lastFourWeeksStrings));
    dispatch(setMyContactsLineData(myLineData));
    dispatch(setAllLeadsLineData(lineData));
  } catch (error) {
    console.error("Error getting documents: ", error);
    toast.error("Erreur lors de la récupération des leads");
  }
};
