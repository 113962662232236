const contactFormatter = (data) => {
  let sorted = data.sort(
    (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
  );
  sorted = data.map((v) => {
    return {
      name: v?.location?.value,
      email: v?.userEmail,
      status: v?.broker?.length > 0 && v?.broker[0]?.projectProgress,
      dateCreation: v?.dateCreation,
      phone: v?.phoneNumber,
      photoUrl: "/assets/images/faces/1.jpg",
    };
  });

  return sorted;
};

export default contactFormatter;
