import { useEffect, useCallback } from "react";
import { Wizard } from "react-use-wizard";
import EstimateStep from "./steps/EstimateStep";
import PropertyInformationStep from "./steps/PropertyInformationStep";
import AdditionalPropertyInformationStep from "./steps/AdditionalPropertyInformationStep";
import GeneralConditionStep from "./steps/GeneralConditionStep";
import SituationPropertyStep from "./steps/SituationPropertyStep";
import TypeBaitment from "./steps/TypeBaitment";
import FinalLoading from "./FinalLoading";
import NewAddressStep from "./NewAddressStep";
import { setLoading } from "../../../redux/slices/evaluation/evaluationSlice";
import AccommodationStep from "./AccommodationStep";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../evaluationDeBien.scss";

import evaluerBien from "../../../redux/slices/evaluation/actions/evaluerBien";

const NewEstimationLoggedIn = ({ user, setUserData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { formalizedData } = useSelector((state) => state.evaluation);

  const updateLoading = useCallback(
    (value) => {
      dispatch(setLoading(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (formalizedData?.formComplete === true) {
      dispatch(evaluerBien(navigate));
    }
  }, [formalizedData?.formComplete, dispatch, formalizedData, navigate]);

  return (
    <>
      <main className="bg-gray-100 min-height-80vh d-flex flex-column">
        <section className="steps-form d-flex align-items-center flex-fill py-5 my-xl-5 overflow-hidden">
          <div className="container">
            <div>
              <Wizard>
                <NewAddressStep updateLoading={updateLoading} />
                <AccommodationStep updateLoading={updateLoading} />
                <SituationPropertyStep loggedIn={true} />
                <EstimateStep />
                {formalizedData?.type === "Unifamiliale" && <TypeBaitment />}
                <PropertyInformationStep />
                <AdditionalPropertyInformationStep />
                <GeneralConditionStep />
                <FinalLoading />
              </Wizard>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default NewEstimationLoggedIn;
