import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const editNote = (note, noteId, notesId, setLoading) => {
  setLoading(true);
  const update = httpsCallable(functions, "updateNote");
  const response = update({ note, noteId, notesId })
    .then((res) => {
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });

  return response;
};

export default editNote;
