import { setSortedBy, setSortingType } from "../leadsSlice";

export const sortEvaluationsByDateAction = (
  evaluations,
  sortingType,
  setState
) => {
  return async (dispatch, getState) => {
    dispatch(setSortedBy("date"));
    dispatch(setSortingType(sortingType));
    const sortedEvaluations = [...evaluations];
    sortedEvaluations.sort((a, b) => {
      const dateA = new Date(a.dateCreation);
      const dateB = new Date(b.dateCreation);
      if (sortingType === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setState(sortedEvaluations);
  };
};
