import { useState, useCallback } from "react";
import { Formik } from "formik";
import placeholder from "../placeholder.png";
import { Button } from "react-bootstrap";
import ChangeDialog from "../components/ChangeDialog";
import DropZone from "../components/DropZone";
import { useDispatch } from "react-redux";

const PersonalInfo = ({
  profile,
  t,
  updateInfosPersonnelles,
  updateZoho,
  setImage,
  imageUploading,
}) => {
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);

  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
  }, []);

  return profile?.email ? (
    <div className="profile_personalInfo">
      <div className="row profile_personalInfo_section">
        <div className="col-12">
          <div className="row">
            <div>
              <h4 className="profile_section_title text-capitalize">
                {t("profile.personalInfo.1")}
              </h4>
              <p className="profile_section_desc">
                {t("profile.personalInfo.5")}
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  email: profile.email,
                  displayName: profile.displayName,
                  phoneNumber: profile.phoneNumber,
                }}
                enableReinitialize={true}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = ` ${t("profile.5")}`;
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = ` ${t("profile.6")}`;
                  }
                  if (!values.displayName) {
                    errors.displayName = ` ${t("profile.7")}`;
                  }
                  if (!values.phoneNumber) {
                    errors.phoneNumber = ` ${t("profile.8")}`;
                  } else if (
                    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
                      values.phoneNumber
                    )
                  ) {
                    errors.phoneNumber = ` ${t(
                      "Format non valide, XXX-XXX-XXXX est le bon format"
                    )}`;
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  dispatch(
                    updateInfosPersonnelles(
                      values,
                      t("profile.23"),
                      t("profile.24"),
                      t("profile.25"),
                      t("profile.26")
                    )
                  );
                  updateZoho(values);
                  setEditPersonalInfo(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="py-3 d-flex flex-column">
                      <div>
                        <div className="mt-2 mb-4 mr-3 profile_img_container">
                          <DropZone
                            onDrop={onDrop}
                            isDisabled={!editPersonalInfo}
                            imageUploading={imageUploading}
                          />
                          <div className="profile_img_container_inner">
                            {profile.image ? (
                              <a
                                href={profile?.image}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img
                                  className="profile_img"
                                  src={profile?.image}
                                  alt="profile"
                                />
                              </a>
                            ) : (
                              <img
                                className="profile_img"
                                src={placeholder}
                                alt="profile"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-1 ">
                        <div className="mb-3 px-3 ">
                          <div>
                            <label
                              className="profile_section_label"
                              htmlFor="validationCustom202"
                            >
                              {t("NProfile.8")}
                            </label>
                            <input
                              type="text"
                              className="form-control profile_input"
                              disabled={!editPersonalInfo}
                              name="displayName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.displayName}
                            />
                            {errors.displayName && touched.displayName && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.displayName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 pl-3 row">
                          <div className="col-md-6 col-12 pr-0">
                            <label
                              className="profile_section_label"
                              htmlFor="validationCustom202"
                            >
                              {t("profile.3")}
                            </label>
                            <input
                              className="form-control profile_input"
                              disabled={!editPersonalInfo}
                              id="phoneNumber"
                              value={values.phoneNumber}
                              name="phoneNumber"
                              onChange={handleChange}
                              required
                              onBlur={handleBlur}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.phoneNumber}
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 col-12 pr-0">
                            <label
                              className="profile_section_label"
                              htmlFor="validationCustom202"
                            >
                              {t("NProfile.10")}
                            </label>
                            <input
                              type="email"
                              className="form-control profile_input"
                              name="email"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <div className="text-danger mt-1 ml-2">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </div>
                        {!editPersonalInfo ? (
                          <div className="col-12 d-flex justify-content-end">
                            <Button
                              className="profile_submit_btn"
                              type="button"
                              onClick={() => setEditPersonalInfo(true)}
                              disabled={isSubmitting}
                            >
                              {t("NProfile.12")}
                            </Button>
                          </div>
                        ) : (
                          <div className="col-12">
                            <ChangeDialog
                              isSubmitting={isSubmitting || imageUploading}
                              cancelFunc={() => {
                                setEditPersonalInfo(false);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PersonalInfo;
