import { useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { useDispatch, useSelector } from "react-redux";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";

const PropertyInformationStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { nextStep, previousStep } = useWizard();

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = useCallback(
    (data) => {
      dispatch(
        updateFormalizedData({
          pieces: parseFloat(data?.pieces),
          superficie: parseFloat(data?.superficie),
          anneeConstruction: parseInt(data?.anneeConstruction),
        })
      );
      nextStep();
    },
    [dispatch, nextStep]
  );

  const validateNonNegative = useCallback((value) => {
    if (parseFloat(value) < 0) {
      return false; // Return false if the value is negative
    }
    return true; // Return true if the value is non-negative
  }, []);

  const numberValue = watch("superficie", ""); // Watching the 'number' field

  const enforceMaxLength = useCallback(
    (e) => {
      const inputValue = e.target.value;
      const currentValue = numberValue;
      const limit = formalizedData?.type === "Unifamiliale" ? 6 : 5;

      if (
        inputValue.length > limit ||
        inputValue.length < currentValue.length
      ) {
        e.preventDefault();
        setValue("superficie", currentValue);
      } else {
        setValue("superficie", inputValue);
      }
    },
    [formalizedData?.type, numberValue, setValue]
  );

  const handleBackspace = useCallback(
    (e) => {
      if (e.key === "Backspace") {
        e.preventDefault();
        const currentValue = numberValue;
        setValue("superficie", currentValue.slice(0, -1));
      }
    },
    [numberValue, setValue]
  );

  const numberValueyear = watch("anneeConstruction", ""); // Watching the 'number' field

  const enforceMaxLengthYear = useCallback(
    (e) => {
      const inputValue = e.target.value;
      const currentValue = numberValueyear;

      if (inputValue.length > 4 || inputValue.length < currentValue.length) {
        e.preventDefault();
        setValue("anneeConstruction", currentValue);
      } else {
        setValue("anneeConstruction", inputValue);
      }
    },
    [numberValueyear, setValue]
  );

  const handleBackspaceYear = useCallback(
    (e) => {
      if (e.key === "Backspace") {
        e.preventDefault();
        const currentValue = numberValueyear;
        setValue("anneeConstruction", currentValue.slice(0, -1));
      }
    },
    [numberValueyear, setValue]
  );

  const numberOptions = Array.from({ length: 30 }, (_, index) => index + 1);

  useEffect(() => {
    setValue("pieces", formalizedData?.pieces ? formalizedData?.pieces : 1);
    setValue(
      "superficie",
      formalizedData?.superficie ? formalizedData?.superficie : ""
    );
    setValue(
      "anneeConstruction",
      formalizedData?.anneeConstruction ? formalizedData?.anneeConstruction : ""
    );
  }, [
    setValue,
    formalizedData?.pieces,
    formalizedData?.superficie,
    formalizedData?.anneeConstruction,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="bg-white rounded-xl p-4">
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-2">
          {t("Estimate-new.42")}
        </h2>
        <div className="row align-items-center">
          <div className="col">
            <ul
              className="max-width-274 nav flex-column flex-nowrap"
              style={{ rowGap: "1rem" }}
            >
              <li>
                <label
                  htmlFor="number-of-pieces"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.43")}
                </label>
                <Controller
                  name="pieces" // Name for your select field
                  control={control}
                  // defaultValue={""}
                  rules={{
                    required: true,
                  }} // Add rules if needed
                  render={({ field }) => (
                    <select
                      style={{ height: "50px" }}
                      className="form-control  font-poppins text-14"
                      {...field}
                      // value={field.value}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      {numberOptions.map((number) => (
                        <option key={number} value={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  )}
                />

                {/* <input
                  type="number"
                  id="number-of-pieces"
                  className="form-control py-4 font-poppins text-14"
                  placeholder="2 pièces"
                  {...register("pieces", {
                    required: true,
                    validate: validateNonNegative,
                    min: 1,
                    max: 12,
                  })}
                /> */}
                {errors.pieces?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")} </span>
                )}
                {errors.pieces?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
                {errors.pieces?.type === "max" && (
                  <span className="text-danger"> {t("Estimate-new.127")} </span>
                )}
                {errors.pieces?.type === "min" && (
                  <span className="text-danger"> {t("Estimate-new.128")} </span>
                )}
              </li>
              <li>
                <label
                  htmlFor="area-of-the-property"
                  className="font-poppins text-14 mb-2"
                >
                  {formalizedData?.type === "Unifamiliale" ? (
                    <>
                      {t("Estimate-new.122")}{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {t("Estimate-new.123")}
                      </span>
                    </>
                  ) : (
                    t("Estimate-new.44")
                  )}
                </label>
                <input
                  type="number"
                  id="area-of-the-property"
                  className="form-control py-4 font-poppins text-14"
                  placeholder="10000 sq ft"
                  maxLength="5"
                  {...register("superficie", {
                    required: true,
                    validate: validateNonNegative,
                    min: formalizedData?.type === "Unifamiliale" ? 500 : 100,
                    max:
                      formalizedData?.type === "Unifamiliale" ? 100000 : 50000,
                  })}
                  onKeyDown={handleBackspace}
                  onInput={enforceMaxLength}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      setError("superficie", {
                        type: "required",
                        message: "This field is required",
                      });
                    } else if (!validateNonNegative(value)) {
                      setError("superficie", {
                        type: "validate",
                        message: "Value must be non-negative",
                      });
                    } else if (
                      formalizedData?.type === "Unifamiliale"
                        ? value < 500
                        : value < 100
                    ) {
                      setError("superficie", {
                        type: "min",
                        // message: "Value must be more",
                      });
                    } else if (
                      formalizedData?.type === "Unifamiliale"
                        ? value > 100000
                        : value > 50000
                    ) {
                      setError("superficie", {
                        type: "max",
                        // message: "Value must be less",
                      });
                    } else {
                      setError("superficie", null);
                    }
                  }}
                />
                {errors.superficie?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.superficie?.type === "validate" && (
                  <span className="text-danger"> {t("Estimate-new.124")} </span>
                )}
                {errors.superficie?.type === "min" && (
                  <span className="text-danger">
                    {" "}
                    {t("Estimate-new.125")}{" "}
                    {formalizedData?.type === "Unifamiliale" ? "500" : "100"}{" "}
                  </span>
                )}
                {errors.superficie?.type === "max" && (
                  <span className="text-danger">
                    {" "}
                    {t("Estimate-new.126")}{" "}
                    {formalizedData?.type === "Unifamiliale"
                      ? "100000"
                      : "50000"}{" "}
                  </span>
                )}
              </li>
              <li>
                <label
                  htmlFor="year-of-construction"
                  className="font-poppins text-14 mb-2"
                >
                  {t("Estimate-new.45")}
                </label>
                <input
                  type="number"
                  id="year-of-construction"
                  className="form-control py-4 font-poppins text-14"
                  placeholder="1990"
                  {...register("anneeConstruction", {
                    required: true,
                    maxLength: 4,
                    pattern: /^\d{1,4}$/,
                    min: 1850,
                    max: new Date().getFullYear(),
                  })}
                  onKeyDown={handleBackspaceYear}
                  onInput={enforceMaxLengthYear}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      setError("anneeConstruction", {
                        type: "required",
                      });
                    } else if (value < 1850) {
                      setError("anneeConstruction", {
                        type: "min",
                      });
                    } else if (value > new Date().getFullYear()) {
                      setError("anneeConstruction", {
                        type: "max",
                      });
                    } else {
                      setError("anneeConstruction", null);
                    }
                  }}
                />
                {errors.anneeConstruction?.type === "required" && (
                  <span className="text-danger"> {t("Estimate-new.11")}</span>
                )}
                {errors.anneeConstruction?.type === "min" && (
                  <span className="text-danger">{t("Estimate-new.15")}</span>
                )}
                {errors.anneeConstruction?.type === "max" && (
                  <span className="text-danger">{t("Estimate-new.16")}</span>
                )}
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-7 d-none d-md-block">
            <div className="step-image-box mx-auto">
              <img
                className="d-block h-100 w-100"
                src="/assets/images/steps/tiny-house-amico-1.png"
                alt="..."
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={previousStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default PropertyInformationStep;
