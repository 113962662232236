import AuthGuard from "../../guards/AuthGuard";
import Rapport from "./Rapport";

const inboxRoutes = [
  {
    path: "/vmz/:id",
    exact: true,
    element: <AuthGuard component={<Rapport />} />,
  },
];

export default inboxRoutes;
