import { useEffect, useState } from "react";
import Spinner from "components/spinner";
import { listCards } from "../../../redux/slices/subscription/actions/listCards";
import { listRecentTransactions } from "../../../redux/slices/subscription/actions/listRecentTransactions";
import { getAllProducts } from "../../../redux/slices/subscription/actions/getAllProducts";
import { useSelector, useDispatch } from "react-redux";
import { Wizard } from "react-use-wizard";
import { setFooterShow } from "../../../redux/slices/layout/layoutSettingSlice";
import { setSubLoading } from "../../../redux/slices/subscription/subscriptionSlice";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";

const ManageSubscription = ({ loading = false, currentAccount }) => {
  const dispatch = useDispatch();

  const [selectedPack, setSelectedPack] = useState();
  const [currStep, setCurrStep] = useState(1);

  const { transactions, currentPlan, subLoading, products } = useSelector(
    (state) => state.subscription
  );

  useEffect(() => {
    dispatch(setSubLoading(true));
    dispatch(setFooterShow(false));
    dispatch(listRecentTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (!subLoading && products.length > 0 && currentPlan) {
      dispatch(setFooterShow(true));
    }
  }, [subLoading, products, currentPlan, dispatch]);

  useEffect(() => {
    if (currentPlan) {
      dispatch(listRecentTransactions());
    }
  }, [currentPlan, dispatch]);

  useEffect(() => {
    if (currentAccount?.selectedCardId) {
      dispatch(listCards(currentAccount?.selectedCardId));
    }
  }, [currentAccount?.selectedCardId, dispatch]);

  useEffect(() => {
    if (currentPlan) {
      dispatch(getAllProducts());
    }
  }, [currentPlan, dispatch]);

  return (
    <div>
      {loading || subLoading ? (
        <Spinner />
      ) : (
        <Wizard>
          <StepOne
            currentAccount={currentAccount}
            currStep={currStep}
            currentPlan={currentPlan}
            products={products}
            setCurrStep={setCurrStep}
            setSelectedPack={setSelectedPack}
            transactions={transactions}
          />
          <StepTwo
            setCurrStep={setCurrStep}
            currStep={currStep}
            selectedPack={selectedPack}
            currentPlan={currentPlan}
          />
        </Wizard>
      )}
    </div>
  );
};

export default ManageSubscription;
