import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const fetchMoreData = async (
  filters = {},
  setLoading,
  ownerValue,
  limit,
  nextProspectId,
  onOwnerChange,
  setAll,
  All,
  setEvals,
  setReports,
  setNextProspectId,
  allLabel,
  leadsCount,
  setLeadsCount
) => {
  setLoading(true);
  const httpCallable = httpsCallable(functions, "moreNewleads");
  httpCallable({ ...filters, limit, nextProspectId })
    .then((res) => {
      setLoading(false);

      if (res.data?.data) {
        const reports = res.data.data;
        const filtered = reports.map((v) => {
          if (v.ouiContacterParProfessionnel === "oui") v.type = "contact";
          else v.type = "prospect";
          return v;
        });
        filtered.sort(
          (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
        );

        if (ownerValue && ownerValue.value?.toLowerCase() !== "all") {
          onOwnerChange({ value: "all", label: allLabel });
        }
        setAll([...All, ...filtered]);
        setEvals([...All, ...filtered]);
        setReports([...reports, ...filtered]);
        setNextProspectId(res.data.nextProspectId);
        setLeadsCount(leadsCount + 5);
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export default fetchMoreData;
