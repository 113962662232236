import { useEffect, useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { useDispatch, useSelector } from "react-redux";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";

const TypeBaitment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { nextStep, previousStep } = useWizard();

  const [selectedItem, setSelectedItem] = useState(null);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = useCallback(
    (data) => {
      dispatch(updateFormalizedData({ typeBatiment: data?.typeBatiment }));
      nextStep();
    },
    [dispatch, nextStep]
  );

  const handleDivClick = useCallback(
    (value) => {
      setValue("typeBatiment", value);
      setSelectedItem(value);
    },
    [setValue]
  );

  const estimate = useMemo(
    () => [
      {
        imageSrc: "/assets/images/steps/appartment.png",
        label: t("Estimate-new.39"),
        value: "Isolé (détaché)",
      },
      {
        imageSrc: "/assets/images/steps/appartment.png",
        label: t("Estimate-new.40"),
        value: "Jumelé",
      },
      {
        imageSrc: "/assets/images/steps/appartment.png",
        label: t("Estimate-new.41"),
        value: "En rangée",
      },
    ],
    [t]
  );

  useEffect(() => {
    setValue(
      "typeBatiment",
      formalizedData?.typeBatiment
        ? formalizedData?.typeBatiment
        : "Isolé (détaché)"
    );
    setSelectedItem(
      formalizedData?.typeBatiment
        ? formalizedData?.typeBatiment
        : "Isolé (détaché)"
    );
  }, [setValue, setSelectedItem, formalizedData?.typeBatiment]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
          {t("Estimate-new.38")}
        </h2>
        <div className="d-flex flex-wrap" style={{ gap: 16 }}>
          {estimate.map((data) => (
            <div
              key={data.value}
              className={`step-box-1 p-4 text-center flex-fill ${
                selectedItem === data.value ? "active" : ""
              }`}
              onClick={() => handleDivClick(data.value)}
            >
              <img src={data.imageSrc} alt={data.value} />
              <h6 className="mt-4 mb-0 text-14 font-weight-700 font-poppins">
                {data.label}
              </h6>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={previousStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            disabled={selectedItem === null}
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default TypeBaitment;
