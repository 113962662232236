import { deleteDoc, doc } from "@firebase/firestore";
import { db } from "../../../../firebase";
import { setLoading, setEstimations } from "../myEstimationsSlice";

const deleteEstimation = (estimationId) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { myEstimations } = getState();
    await deleteDoc(doc(db, "RapportsEvaluations", estimationId));
    const filteredEstimations = myEstimations.estimations.filter(
      (estimation) => estimation.id !== estimationId
    );
    dispatch(setEstimations(filteredEstimations));
    dispatch(setLoading(false));
  } catch (error) {
    console.log("deleteEstimation_error", error);
    dispatch(setLoading(false));
  }
};

export default deleteEstimation;
