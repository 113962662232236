import { authRoles } from "../../guards/authRoles";
import AuthGuard from "../../guards/AuthGuard";

import Dashboard from "./Dashboard";
const dashboardRoutes = [
  {
    path: "/dashboard",
    element: <AuthGuard component={<Dashboard />} />,
    auth: authRoles.Essentiel,
  },
];

export default dashboardRoutes;
