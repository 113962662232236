import { useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { firebaseLoginEmailPassword } from "../../../redux/slices/authentication/actions/firebaseLoginEmailPassword";
import Spinner from "../../../components/spinner";

const SigninSchema = (t) =>
  yup.object().shape({
    email: yup.string().email("E-mail invalide").required(t("Sign_in.18")),
    password: yup.string().min(8, t("Sign_in.22")).required(t("Sign_in.19")),
  });

const Signin = () => {
  const { t } = useTranslation();
  const { email, password, error, loading } = useSelector(
    (state) => state.authentication
  );
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      dispatch(firebaseLoginEmailPassword(value, t));
    },
    [dispatch, t]
  );

  return (
    <>
      {loading && <Spinner />}
      <>
        <div className="auth-content">
          <div className="card o-hidden">
            <div className="row">
              <div className="col-md-6">
                <div className="p-4">
                  <div className="auth-logo text-center mb-4">
                    <Link to="/packages">
                      <img src="/assets/images/logo.png" alt="" />
                    </Link>
                  </div>
                  <h1 className="mb-3 text-18">{t("Sign_in.1")}</h1>
                  <Formik
                    initialValues={{
                      email: email,
                      password: password,
                    }}
                    validationSchema={SigninSchema(t)}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="email">{t("Sign_in.2")}</label>
                          <input
                            className="form-control form-control-rounded position-relative"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          {touched.email && errors.email && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.email}
                            </div>
                          )}
                          {error && error === "auth/user-not-found" && (
                            <div className="text-danger mt-1 ml-2">
                              {t("Sign_in.27")}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">{t("Sign_in.3")}</label>
                          <input
                            className="form-control form-control-rounded"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          {touched.password && errors.password && (
                            <div className="text-danger mt-1 ml-2">
                              {errors.password}
                            </div>
                          )}
                          {error && error === "auth/wrong-password" && (
                            <div className="text-danger mt-1 ml-2">
                              {t("Sign_in.26")}
                            </div>
                          )}
                        </div>

                        <button
                          className="btn btn-rounded btn-primary btn-block mt-2"
                          type="submit"
                        >
                          {t("Sign_in.4")}
                        </button>
                      </form>
                    )}
                  </Formik>

                  <div className="mt-3 text-center">
                    <Link to="/forgot-password" className="text-muted">
                      <u>{t("Sign_in.5")}</u>
                    </Link>
                  </div>
                  <div className="mt-1 text-center">
                    <Link
                      to="/dataPolicy"
                      className="text-muted"
                      rel="noopener noreferrer"
                    >
                      <u>{t("Sign_in.6")} </u>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 text-center "
                style={{
                  backgroundSize: "cover",
                  backgroundImage: "url(/assets/images/photo-long-3.jpg)",
                }}
              >
                <div className="pr-3 auth-right">
                  <Link
                    to="/signup"
                    className="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text"
                  >
                    <i className="i-Mail-with-At-Sign"></i>
                    {t("Sign_in.15")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Signin;
