import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../firebase";
import { setLoading, setUser, setError } from "../authenticationSlice";
import swal from "sweetalert2";

export const firebaseLoginEmailPassword = (values, t) => {
  return async (dispatch, getState) => {
    try {
      const { email, password } = values;
      dispatch(setLoading(true));
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user.uid) {
            dispatch(setUser(user.uid));
            dispatch(setLoading(false));
          } else {
            dispatch(setError({ code: "no_user_uid" }));
            dispatch(setLoading(false));
          }
        })
        .catch((error) => {
          dispatch(setError(error.code));
          dispatch(setLoading(false));
          console.log("error 1", error.code);
          if (error.code === "auth/user-disabled") {
            swal.fire({
              title: t("Sign_in.28"),
              text: t("Sign_in.29"),
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    } catch (error) {
      console.log("error", error);
      dispatch(setError(error.code));
      dispatch(setLoading(false));
    }
  };
};
