import { db } from "../../../../firebase";
import { setLoadingMore, setSales, setLastFetchedSale } from "../salesSlice";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  startAt,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Timestamp } from "firebase/firestore";
const fetchMoreSales = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoadingMore(true));

      const state = getState();
      const lastFetchedSale = state.sales.lastFetchedSale;
      const count = state.sales.count;

      if (!lastFetchedSale) {
        dispatch(setLoadingMore(false));
        return;
      }

      const q = query(
        collection(db, "sales"),
        where("userRef", "==", userId),
        orderBy("timestamp", "desc"),
        startAt(Timestamp.fromDate(new Date(lastFetchedSale.data.timestamp))),
        limit(count + 1)
      );
      const salesSnap = await getDocs(q);

      if (salesSnap.empty) {
        dispatch(setLoadingMore(false));
        return;
      }

      let salesData = [];
      salesSnap.forEach(async (sale) => {
        const data = sale.data();
        const serialzedUpdatedAt = data.updatedAt
          ? data.updatedAt.toDate().getTime()
          : null;

        const serializedtimestamp = sale.data().timestamp
          ? data.timestamp.toDate().getTime()
          : null;
        salesData.push({
          id: sale.id,
          data: {
            ...data,
            updatedAt: serialzedUpdatedAt,
            timestamp: serializedtimestamp,
          },
        });
      });
      dispatch(setSales([...state.sales.sales, ...salesData.slice(0, count)]));
      dispatch(
        setLastFetchedSale(salesData.length > count ? salesData[count] : null)
      );
      dispatch(setLoadingMore(false));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
};

export default fetchMoreSales;
