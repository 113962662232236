import MesVentes from "./MesVentes";
import AuthGuard from "../../guards/AuthGuard";

const mesVentesRoutes = [
  {
    path: "/mes-ventes",
    element: <AuthGuard component={<MesVentes />} />,
  },
];

export default mesVentesRoutes;
