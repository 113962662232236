import { useState, useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Spinner from "../../../components/spinner";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStripeToken } from "../../../redux/slices/subscription/actions/createStripeToken";
import { updateDefaultCard } from "../../../redux/slices/subscription/actions/updateDefaultCard";
import { detachCard } from "../../../redux/slices/subscription/actions/detachCard";
import swal from "sweetalert2";

const MyCards = () => {
  const [loading, setLoading] = useState(false);
  const [detachLoading, setDetachLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cards, selectedCardId } = useSelector((state) => state.subscription);

  const [selectedCard, setSelectedCard] = useState(
    selectedCardId || cards[0]?.id
  );
  const lastUsedCard = useMemo(
    () => cards?.length && cards.find((card) => card.id === selectedCardId),
    [cards, selectedCardId]
  );
  const [cardNo, setCardNo] = useState("");
  const [mmyy, setMmyy] = useState("");
  const [fullName, setFullname] = useState("");
  const [cvv, setCvv] = useState("");

  const [showModal, setShowModal] = useState(false);

  const updateCard = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    if (!selectedCard && !cardNo) return alert(t("NProfile.129"));
    if (!selectedCard && !mmyy) return alert("expiry date is required");
    if (!selectedCard && !cvv) return alert("cvv is required");

    let source = null;
    if (!selectedCard) {
      source = await createStripeToken({
        number: cardNo,
        exp_month: mmyy.split(" ")[0],
        exp_year: mmyy.split(" ")[1],
        cvc: cvv,
      });
    }

    dispatch(
      updateDefaultCard(
        {
          card: selectedCard,
          source: source ? source.id : null,
        },
        async (data) => {
          setLoading(false);
          setShowModal(false);
          await swal.fire({
            titleText: t("profile.subscription.payment.12"),
            showCancelButton: false,
            confirmButtonText: "Okay",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
          });
        }
      )
    );
  }, [selectedCard, cardNo, mmyy, cvv, dispatch, loading, t]);

  const deleteCard = useCallback(
    async (cardId) => {
      if (detachLoading) return;
      setDetachLoading(true);
      dispatch(
        detachCard(cardId, async (data) => {
          setDetachLoading(false);
          await swal.fire({
            titleText: t("Your card was deleted successfully"),
            showCancelButton: false,
            confirmButtonText: "Okay",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !swal.isLoading(),
          });
        })
      );
    },
    [detachLoading, dispatch, t]
  );

  return (
    <>
      {(loading || detachLoading) && <Spinner />}
      <div className="card-container">
        <p className="component-title">{t("profile.subscription.payment.8")}</p>
        <p className="component-subtxt">
          {t("profile.subscription.payment.9")}
        </p>

        <div className="single-card-container">
          <div className="flex-row">
            <img
              src={
                lastUsedCard?.brand === "Visa"
                  ? "./assets/images/visa.png"
                  : "./assets/images/master-card.png"
              }
              alt="card_icon"
            />
            <div>
              <p className="card-number">
                {t("profile.subscription.payment.1")} {lastUsedCard?.brand}
                {` XXXX ${lastUsedCard?.last4}`}
              </p>
              <p className="card-number card-expires-on">
                {t("profile.subscription.payment.2")} {lastUsedCard?.exp_month}/
                {lastUsedCard?.exp_year}
              </p>
            </div>
          </div>
          <button className="bg-filled-btn" onClick={() => setShowModal(true)}>
            {t("profile.subscription.payment.10")}
          </button>
        </div>
        {cards.length > 0 &&
          cards
            .filter(
              (card) => card.id !== selectedCard && card.id !== lastUsedCard?.id
            )
            .slice(0, 2)
            .map((card) => (
              <div
                className="single-card-container single-card-container-popup"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                }}
                key={card?.id}
              >
                <div className="flex-row">
                  <img
                    src={
                      card.brand === "Visa"
                        ? "./assets/images/visa.png"
                        : "./assets/images/master-card.png"
                    }
                    alt="card_icon"
                  />
                  <div>
                    <p className="card-number">
                      {t("profile.subscription.payment.1")} {card.brand} XXXX{" "}
                      {card.last4}{" "}
                    </p>
                    <p className="card-number card-expires-on">
                      {t("profile.subscription.payment.2")} {card.exp_month}/
                      {card.exp_year}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        <Modal
          className="suspended-modal"
          show={showModal}
          onHide={() => {
            setSelectedCard(selectedCardId);
            setShowModal(false);
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="payment-form-container payment-form-container-popup">
              {cards.length > 0 &&
                cards.map((card) => (
                  <div
                    className="single-card-container single-card-container-popup"
                    key={card?.id}
                    style={{
                      borderWidth: card.id === selectedCard ? 1 : 0,
                      borderColor: "#5F3994",
                    }}
                  >
                    <div className="flex-row">
                      <img
                        src={
                          card.brand === "Visa"
                            ? "./assets/images/visa.png"
                            : "./assets/images/master-card.png"
                        }
                        alt="card_icon"
                      />
                      <div>
                        <p className="card-number">
                          {t("profile.subscription.payment.1")} {card.brand}{" "}
                          XXXX {card.last4}{" "}
                        </p>
                        <p className="card-number card-expires-on">
                          {t("profile.subscription.payment.2")} {card.exp_month}
                          /{card.exp_year}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div>
                        {selectedCard !== card.id && (
                          <button
                            className="bg-filled-btn"
                            onClick={() => setSelectedCard(card.id)}
                          >
                            {t("profile.subscription.payment.3")}
                          </button>
                        )}
                      </div>
                      {selectedCardId !== card.id &&
                      selectedCard !== card.id ? (
                        <div>
                          <img
                            src={`assets/icons/supprimer.svg`}
                            onClick={() => deleteCard(card.id)}
                            alt="icon"
                            style={{
                              maxWidth: "20px",
                              marginLeft: "10px",
                              cursor: "pointer",
                              marginRight: "0",
                              opacity: detachLoading ? 0.5 : 1,
                            }}
                          />
                        </div>
                      ) : (
                        <div style={{ width: "20px", marginLeft: "10px" }} />
                      )}
                    </div>
                  </div>
                ))}
              {!selectedCard || cards?.length === 0 ? (
                <div>
                  <div className="new-card-txt new-card-txt-popup">
                    <p>{t("profile.subscription.payment.4")}</p>
                    <div />
                  </div>
                  <div className="name-popup">
                    <label>{t("profile.subscription.payment.5")}</label>
                    <input
                      className="card-inputs mb-20"
                      id="username"
                      onChange={(e) => setFullname(e.target.value)}
                      value={fullName}
                    />
                  </div>
                  <div>
                    <label>{t("profile.subscription.payment.6")}</label>
                    <div className="name-input-container">
                      <input
                        className="card-inputs card-number"
                        id="card-no"
                        maxLength="19"
                        onChange={(e) =>
                          setCardNo(
                            e.target.value
                              .replace(/\W/gi, "")
                              .replace(/(.{4})/g, "$1 ")
                              .trim()
                          )
                        }
                        placeholder="1234 1234 1234 1234"
                        value={cardNo}
                      />
                      <img
                        src="./assets/images/cards.png"
                        className="cards-img"
                        alt="card"
                      />
                    </div>
                    <div className="expiry-cvv-container">
                      <input
                        className="mm-yy"
                        id="mmyy"
                        maxLength="5"
                        onChange={(e) =>
                          setMmyy(
                            e.target.value
                              .replace(/\W/gi, "")
                              .replace(/(.{2})/g, "$1 ")
                              .trim()
                          )
                        }
                        placeholder="MM/YY"
                        value={mmyy}
                      />
                      <input
                        className="mm-yy"
                        id="cvv"
                        placeholder="CVV"
                        maxLength="3"
                        minLength="3"
                        onChange={(e) => setCvv(e.target.value)}
                        value={cvv}
                      />
                      <img
                        src="./assets/images/cvv.png"
                        className="cvv-img"
                        alt="ccv_icon"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <p
                  className="new-card-btn"
                  onClick={() => setSelectedCard(null)}
                >
                  {t("profile.subscription.payment.7")}
                </p>
              )}
              <button className="payer" onClick={() => updateCard()}>
                {loading
                  ? t("NProfile.120")
                  : t("profile.subscription.payment.11")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default MyCards;
