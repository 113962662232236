import { useMemo, useEffect } from "react";
import Spinner from "../spinner";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-toastify";

const MapEstimate = (props) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places", "geometry", "drawing"],
  });

  useEffect(() => {
    if (loadError) {
      console.log("error loading map", loadError);
      toast.error(loadError.message);
    }
  }, [loadError]);

  const containerStyle = useMemo(() => {
    return { height: `100%`, width: `100%` };
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          zoom={7}
          center={{
            lat: props?.markers[0]?.location?.latlng?.lat,
            lng: props?.markers[0]?.location?.latlng?.lng,
          }}
          mapContainerStyle={containerStyle}
        >
          {props.markers.map((marker) => {
            return (
              <Marker
                key={marker?.location?.value}
                icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                position={{
                  lat: marker?.location?.latlng?.lat,
                  lng: marker?.location?.latlng?.lng,
                }}
              ></Marker>
            );
          })}
        </GoogleMap>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default MapEstimate;
