import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setResult } from "../evaluationSlice";
import swal from "sweetalert2";

const evaluerBien = (navigate) => async (dispatch, getState) => {
  try {
    const { formalizedData } = getState().evaluation;
    const data =
      formalizedData?.type === "condo"
        ? {
            ...formalizedData,
            location: formalizedData?.location,
            typeBatiment: "",
          }
        : { ...formalizedData, location: formalizedData?.location };
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    let infosBien = {
      dateCreation: new Date().toLocaleDateString("fr-ca", options),
      ...data,
      sendEmail: false,
    };

    var EvaluerBien = httpsCallable(functions, "EvaluerBienNew");
    EvaluerBien({ infobien: infosBien })
      .then((res) => {
        dispatch(setResult(res?.data?.ficheEstimation));
      })
      .catch((error) => {
        swal
          .fire({
            title: "Oups erreur!",
            text: "Nous sommes désolés, une erreur s'est produite. Nos équipes ont été informées de l'incident. Voulez-vous refaire l'évaluation",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, lets Re Evaluate!",
            cancelButtonText: "Cancel",
          })
          .then((result) => {
            navigate("/");
          });
      });
  } catch (error) {
    console.log("EvaluerBien_error", error);
  }
};

export default evaluerBien;
