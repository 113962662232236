const sortingByDate = (asending, contacts, setContacts, setAsending) => {
  if (asending === false) {
    const sorted = contacts.sort(
      (a, b) => new Date(a.dateCreation) - new Date(b.dateCreation)
    );

    setContacts(sorted);
    setAsending(true);
  } else {
    const sorted = contacts.sort(
      (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
    );

    setContacts(sorted);
    setAsending(false);
  }
};

export default sortingByDate;
