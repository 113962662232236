import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setUserData } from "../../../redux/slices/authentication/authenticationSlice";

const addBookmark = (
  e,
  bookmark,
  setLoading,
  dispatch,
  profile,
  alertAddLead
) => {
  e.stopPropagation();
  setLoading(true);
  const add = httpsCallable(functions, "addBookmark");
  add({ bookmark })
    .then((res) => {
      let bookmarks = profile?.bookmarks;
      let newBookmarks = [...bookmarks, bookmark];
      dispatch(setUserData({ ...profile, bookmarks: newBookmarks }));
      alertAddLead();
      setLoading(false);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export default addBookmark;
