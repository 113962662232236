import { authRoles } from "../../guards/authRoles";

export const navigations = (t) => {
  return [
    {
      id: "1",
      name: t("SideNav.1"),

      type: "link",
      path: "/dashboard",
      icon: "home",
      role: authRoles.Essentiel,
    },

    {
      id: "2",
      name: t("SideNav.3"),

      type: "link",
      path: "/leads",
      icon: "prospecter",
      role: authRoles.Performance,
    },

    {
      id: "3",
      name: t("SideNav.2"),

      type: "link",
      path: "/evaluation-bien",
      icon: "estimer",
      role: authRoles.Essentiel,
    },

    {
      id: "4",
      name: t("SideNav.5"),

      type: "link",
      path: "/mes-biens",
      icon: "estimations",
      role: authRoles.Essentiel,
    },
    {
      id: "5",
      name: t("SideNav.6"),

      type: "link",
      path: "/mes-ventes",
      icon: "ventes",
      role: authRoles.Essentiel,
    },

    {
      id: "6",
      name: t("SideNav.7"),

      type: "link",
      path: "/abonnement",
      icon: "abonnement",
      role: authRoles.Essentiel,
    },

    {
      id: "7",
      name: t("SideNav.4"),
      disabled: true,
      type: "link",
      path: "/retains",
      icon: "fideliser",
      role: authRoles.Expert,
    },

    {
      id: "8",
      name: t("SideNav.8"),

      type: "link",
      path: "/moncompte",
      icon: "profile",
      role: authRoles.Essentiel,
    },
  ];
};
