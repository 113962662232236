import { db } from "../../../../firebase";
import { setLoading, setSales, setLastFetchedSale } from "../salesSlice";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";

export function fetchSales(userId) {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const count = state.sales.count;

      dispatch(setLoading(true));
      const q = query(
        collection(db, "sales"),
        where("userRef", "==", userId),
        orderBy("timestamp", "desc"),
        limit(count + 1)
      );
      const salesSnap = await getDocs(q);

      let salesData = [];

      if (salesSnap.empty) {
        dispatch(setLoading(false));
        return;
      }

      salesSnap.forEach((doc) => {
        const serialzedUpdatedAt = doc.data().updatedAt
          ? doc.data().updatedAt.toDate().getTime()
          : null;

        const serializedtimestamp = doc.data().timestamp
          ? doc.data().timestamp.toDate().getTime()
          : null;
        salesData.push({
          id: doc.id,
          data: {
            ...doc.data(),
            updatedAt: serialzedUpdatedAt,
            timestamp: serializedtimestamp,
          },
        });
      });
      dispatch(setSales(salesData.slice(0, count)));
      dispatch(
        setLastFetchedSale(salesData.length > count ? salesData[count] : null)
      );
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
}
