import { useState, useRef, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import AdressField from "../../../components/forms/AddressField";
import Suggestions from "../../../components/forms/AddressField/Suggestions";
import getPlaceDetail from "../../../components/forms/AddressField/utils/getPlaceDetail";
import { updateFormalizedData } from "../../../redux/slices/evaluation/actions/updateFormalizedData";
import { useWizard } from "react-use-wizard";
import Spinner from "../../../components/spinner";

const NewAddressStep = () => {
  const dispatch = useDispatch();
  const { nextStep } = useWizard();
  const { handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const [address, setAddress] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [place, setPlace] = useState("");

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    debounce: 1000,
    libraries: ["places"],
  });

  const adressInputRef = useRef(null);

  const [showSuggestions, setShowSuggestions] = useState(false);

  const placeToAddress = useCallback((place) => {
    var address = {};
    if (place.address_components) {
      address.value = place.formatted_address;
      address.latlng = place.geometry && {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      place.address_components.forEach(function (c) {
        switch (c.types[0]) {
          case "street_number":
            address.StreetNumber = c;
            break;
          case "route":
            address.StreetName = c;
            break;
          case "neighborhood":
          case "locality": // North Hollywood or Los Angeles?
            address.city = c.long_name;
            break;
          case "administrative_area_level_1": //  Note some countries don't have states
            address.state = c;
            break;
          case "postal_code":
            address.postcode = c.short_name;
            break;
          case "country":
            address.Country = c;
            break;
          default:
            break;
          /*
           *   . . .
           */
        }
      });
    }
    return address;
  }, []);

  const validateField = useCallback(
    (values) => {
      const provinces = ["QC", "Québec"];
      if (!values.StreetName) {
        return t("Estimate-new.116");
      } else if (!values.postcode) {
        return t("Estimate-new.117");
      } else if (provinces.indexOf(values.state.short_name) === -1) {
        return t("Estimate-new.118");
      }

      // Add other validation rules as needed

      return ""; // Return an empty string if validation passes
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data) => {
      if (!validationError) {
        try {
          dispatch(updateFormalizedData({ location: data?.address }));
          await nextStep();
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    [dispatch, nextStep, validationError]
  );

  return (
    <section className="home-section-one steps-home pb-5 pb-lg-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container mx-auto">
          <Row className="align-items-center">
            <Col>
              <div>
                <h1 className="font-poppins font-weight-bold text-violet-2 mb-3">
                  {t("Estimate-new.112")}{" "}
                  <span className="text-orange"> {t("Estimate-new.113")}</span>
                  <br className="d-none d-xl-block" />
                  {t("Estimate-new.114")}
                </h1>
                <div className="position-relative">
                  {showSuggestions && placePredictions?.length > 0 && (
                    <Suggestions
                      placePredictions={placePredictions}
                      getPlaceDetail={getPlaceDetail}
                      placesService={placesService}
                      setValue={setPlace}
                      placeToAddress={placeToAddress}
                      followUpFunc={(place) => {
                        setAddress(place);
                        const error = validateField(place);
                        setValidationError(error);
                      }}
                      setFieldValue={setValue}
                      setShowPredictions={setShowSuggestions}
                      adressInputRef={adressInputRef}
                    />
                  )}
                  <div className="bg-white p-3 box-shadow-2 rounded-xl">
                    <div
                      className="d-flex flex-column flex-md-row"
                      style={{ gap: 16 }}
                    >
                      {isPlacePredictionsLoading && <Spinner />}
                      <AdressField
                        getPlacePredictions={getPlacePredictions}
                        isPlacePredictionsLoading={isPlacePredictionsLoading}
                        setShowPredictions={setShowSuggestions}
                        value={place}
                        setValue={setPlace}
                        adressInputRef={adressInputRef}
                        setAddress={setAddress}
                      />

                      <button
                        disabled={
                          address === null || !address.postcode ? true : false
                        }
                        type="submit"
                        className="font-poppins rounded-2 p-3 btn btn-primary font-weight-500 text-12"
                      >
                        {t("Estimate-new.115")}
                      </button>
                    </div>
                    {validationError && (
                      <span className="text-danger">{validationError}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="mb-4 mb-lg-0 order-first order-lg-last">
              <div className="position-relative box-image">
                <span className="box-cover-image">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 424 424"
                    fill="none"
                  >
                    <rect
                      x="-1.42456"
                      y="26.393"
                      width={400}
                      height={400}
                      rx={30}
                      transform="rotate(-3.98779 -1.42456 26.393)"
                      fill="#5F3994"
                    />
                  </svg>
                </span>
                <img
                  className="h-100 w-100 p-2 d-block position-relative"
                  src="/assets/images/hero-image-0.png"
                  alt=".."
                />
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </section>
  );
};

export default NewAddressStep;
