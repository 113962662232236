import { setSubLoading, setCurrent, setStep } from "../subscriptionSlice";
import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";

export const createNewAccount = (values, nextStep, setActiveStep) => {
  return async (dispatch, getState) => {
    dispatch(setSubLoading(true));
    const createAccount = httpsCallable(functions, "createNewAccount");
    createAccount({ ...values, creationProcessFinished: false })
      .then((res) => {
        if (res.data && res.data.error) {
          dispatch(setSubLoading(false));
          toast(res.data.message);
        } else {
          dispatch(setCurrent(res.data));
          dispatch(setSubLoading(false));
          dispatch(setStep(1));
          nextStep && nextStep();
          setActiveStep && setActiveStep(2);
        }
        return res;
      })
      .catch((err) => {
        toast.error(err.message);
        console.log("err", err);
      });
  };
};
