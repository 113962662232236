import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MyNotes from "./myNotes";
import NotesModal from "./notesModal";
import getNotes from "../../utils/getNotes";
import EditNoteModal from "./editNoteModal";

const Notes = ({ prospect, setLoading }) => {
  const { t } = useTranslation();

  const [notes, setNotes] = useState([]);
  const [notesId, setNotesId] = useState(null);

  const fetchNotes = useCallback(
    async (id) => {
      const notes = await getNotes(id, setLoading);
      if (notes?.notes?.length > 0) setNotes(notes?.notes);
      else {
        setNotes([]);
      }
      if (notes?.notesId) setNotesId(notes?.notesId);
    },
    [setLoading]
  );

  useEffect(() => {
    fetchNotes(prospect?.id);
  }, [fetchNotes, prospect?.id]);

  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editedNote, setEditedNote] = useState(null);

  return (
    <div className="newLeads_notes">
      <div className="newLeads_notes_head">
        <span>{t("Leads.97")}</span>
        <span onClick={() => setShow(true)}>{t("Leads.69")}</span>
      </div>
      <div className="newLeads_notes_list">
        <MyNotes
          email={prospect.email}
          evalId={prospect.id}
          notesList={notes}
          notesId={notesId}
          setLoading={setLoading}
          setNotesList={setNotes}
          setEditShow={setEditShow}
          setEditedNote={setEditedNote}
        />
        <NotesModal
          show={show}
          setShow={setShow}
          evalId={prospect?.id}
          setLoading={setLoading}
          setNotes={setNotes}
          notesList={notes}
          notesId={notesId}
          setNotesId={setNotesId}
        />
        <EditNoteModal
          show={editShow}
          setShow={setEditShow}
          setLoading={setLoading}
          setNotes={setNotes}
          notesList={notes}
          note={editedNote}
          setEditedNote={setEditedNote}
          notesId={notesId}
        />
      </div>
    </div>
  );
};

export default Notes;
