import { db } from "../../../../firebase";
import { setUpdatingSale, setSales } from "../salesSlice";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const updateSale = (saleData, saleId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setUpdatingSale(true));
      const state = getState();
      const userId = state.authentication.user;
      const docRef = doc(db, "sales", saleId);
      await updateDoc(docRef, {
        ...saleData,
        userRef: userId,
      });

      const serialzedUpdatedAt = saleData?.updatedAt
        ? saleData?.updatedAt.toDate().getTime()
        : null;

      const saleObj = {
        ...saleData,
        updatedAt: serialzedUpdatedAt,
        userRef: userId,
      };

      const statesList = state.sales.sales;
      const updatedSales = statesList.map((item) => {
        if (item.id === saleId) {
          return { data: { ...saleObj }, id: saleId };
        } else {
          return item;
        }
      });
      dispatch(setSales(updatedSales));
      dispatch(setUpdatingSale(false));
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
};

export default updateSale;
