import swal from "sweetalert2";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import {
  setUserPersonalData,
  setLoadingUserData,
} from "../authenticationSlice";

export function updateUserData(
  values,
  successTitle,
  successMessage,
  errorTitle,
  errorMessage
) {
  return (dispatch, getState) => {
    try {
      const userId = getState().authentication.userData.id;
      dispatch(setLoadingUserData(true));
      const docRef = doc(db, "users", userId);
      updateDoc(docRef, values);
      dispatch(setUserPersonalData(values));
      dispatch(setLoadingUserData(false));
      swal.fire(successTitle, successMessage, "success");
    } catch (error) {
      console.log(error);
      swal.fire(errorTitle, errorMessage, "error");
      dispatch(setLoadingUserData(false));
    }
  };
}
