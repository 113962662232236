import { useState } from "react";
import EditMySalesList from "./EditMySalesList";
import { Button } from "react-bootstrap";
import AddSale from "./AddSale";
import Spinner from "../../../components/spinner";
import { useSelector } from "react-redux";

const MySales = ({ t }) => {
  const [selectedSale, setSelectedSale] = useState(null);

  const [isAdding, setIsAdding] = useState(false);
  const { addingSale, updatingSale } = useSelector((state) => state.sales);

  return (
    <div>
      {(addingSale || updatingSale) && <Spinner />}
      <AddSale show={isAdding} onHide={() => setIsAdding(false)} t={t} />
      <div className="profile_sales_header">
        <div className="profile_sales_header_title">{t("profile.20")}</div>
        <Button
          variant="primary"
          className="profile_sales_header_btn"
          onClick={() => {
            setIsAdding(true);
          }}
        >
          {t("profile.sales.60")}
        </Button>
      </div>
      <EditMySalesList
        t={t}
        setSelectedSale={setSelectedSale}
        selectedSale={selectedSale}
      />
    </div>
  );
};

export default MySales;
