import "../layout.scss";
import Footer from "../components/footer";
import ScrollToTop from "../components/ScrollToTop";

const UnAuthLayout = ({ children }) => {
  return (
    <div>
      <div className="main-content">{children}</div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default UnAuthLayout;
