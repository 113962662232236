import { useState, useEffect, useMemo, useCallback } from "react";
import Breadcrumb from "../../components/breadcrumb";
import SimpleCard from "../../components/simpleCard";
import ProspectsMap from "../../components/map";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../components/customTable/CustomTable";
import getMyEstimations from "../../redux/slices/myEstimations/actions/getMyEstimations";
import deleteEstimation from "../../redux/slices/myEstimations/actions/deleteEstimation";
import fetchMoreEstimations from "../../redux/slices/myEstimations/actions/fetchMoreEstimations";
import LoadMore from "../../components/buttons/loadMore";
import Spinner from "../../components/spinner";

const CarteProspection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { estimations, lastFetchedEstimation, loading } = useSelector(
    (state) => state.myEstimations
  );
  const { userData: profile } = useSelector((state) => state.authentication);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (estimations) {
      const data = estimations.map((estimation) => ({
        id: estimation.id,
        dateCreation: estimation.dateCreation,
        genreProprietes: estimation.genreProprietes,
        // anneeConstruction: estimation.anneeConstruction,
        location: estimation.location?.value,
        ziaEstimation: estimation.ziaEstimation,
        city: estimation?.location?.city,
      }));
      setTableData(data);
    }
  }, [estimations]);

  const handleDelete = useCallback(
    (e, value) => {
      e.stopPropagation();
      dispatch(deleteEstimation(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (profile?.userId) {
      dispatch(getMyEstimations(profile?.userId));
    }
  }, [profile, dispatch]);

  const handleClick = useCallback((marker, event) => {
    setSelectedMarker(marker);
  }, []);

  const values = useMemo(
    () => ({
      1: t("estimate-table.3"),
      2: t("estimate-table.4"),
    }),
    [t]
  );

  return (
    <div>
      {loading && <Spinner />}
      <Breadcrumb
        routeSegments={[
          { name: `${t("estimate-table.1")}`, path: "/" },
          { name: `${t("estimate-table.2")}`, path: "mes-biens" },
        ]}
      />

      {estimations && (
        <SimpleCard title="">
          <div className="row mt-0">
            <ProspectsMap
              markers={estimations}
              selectedMarker={selectedMarker}
              updateSelectedMarker={handleClick}
            />
          </div>
          {tableData && tableData?.length > 0 && (
            <CustomTable
              data={tableData}
              handleDelete={handleDelete}
              estimations={estimations}
              setSelectedMarker={setSelectedMarker}
              values={values}
              titles={[
                t("estimate-table.10"),
                t("estimate-table.5"),
                t("estimate-table.6"),
                t("estimate-table.7"),
                t("estimate-table.8"),
                t("estimate-table.9"),
              ]}
            />
          )}
          {lastFetchedEstimation && (
            <div className="d-flex justify-content-center">
              <LoadMore
                loadMore={() => dispatch(fetchMoreEstimations(profile?.userId))}
              />
            </div>
          )}
        </SimpleCard>
      )}
    </div>
  );
};

export default CarteProspection;
