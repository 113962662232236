import { createSlice } from "@reduxjs/toolkit";

export const currentReportSlice = createSlice({
  name: "currentReport",
  initialState: {
    report: null,
    loading: false,
  },
  reducers: {
    setReport: (state, action) => {
      state.report = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setReport, setLoading } = currentReportSlice.actions;

export default currentReportSlice.reducer;
