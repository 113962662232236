import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const getPropertyDetailInfo = (
  formulaizeData,
  setLoading,
  nextStep,
  setData,
  setCharData,
  setInformation
) => {
  setLoading(true);
  const data = {
    postalCode: formulaizeData?.location?.postcode,
    address: `${formulaizeData?.location?.StreetNumber?.long_name} ${formulaizeData?.location?.StreetName?.long_name}`,
  };
  const GetPropertyDetailInfo = httpsCallable(
    functions,
    "GetProperyDetailInfoNew"
  );
  GetPropertyDetailInfo(data)
    .then((res) => {
      if (res?.data?.length === 0) {
        nextStep();
      } else {
        setLoading(false);
        setData(res?.data[0]);
        setCharData([
          res?.data[0]?.type,
          res?.data[0]?.genreProprietes,
          res?.data[0]?.anneeConstruction,
          ` ${parseInt(res?.data[0]?.superficieTerrain)} sq ft`,
        ]);
        setInformation([
          `${res?.data[0]?.Nbrpieces} pieces`,
          `${res?.data[0]?.NbrChambres} chambres`,
          `${res?.data[0]?.salleBains} salles de bain`,
          `${res?.data[0]?.stationnements} stationnements`,
        ]);
      }
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
};

export default getPropertyDetailInfo;
