import { createSlice } from "@reduxjs/toolkit";

export const salesSlice = createSlice({
  name: "sales",
  initialState: {
    sales: [],
    loading: false,
    loadingPropertyInfo: false,
    addingSale: false,
    loadingMore: false,
    updatingSale: false,
    selectedSale: null,
    lastFetchedSale: null,
    count: 8,
    conditions: [
      {
        label: "54",
        value: "renovated",
      },

      {
        label: "55",
        value: "good condition",
      },
      {
        label: "56",
        value: "needs refreshing",
      },
      {
        label: "57",
        value: "work to be planned/anticipated",
      },
    ],
    contractStatus: [
      {
        label: "6",
        value: "sold",
      },
      {
        label: "7",
        value: "expired",
      },
      {
        label: "8",
        value: "in the process of being sold",
      },
    ],
    categories: [
      {
        label: "13",
        value: "single-family",
      },
      {
        label: "14",
        value: "condominium",
      },
    ],
    genresOne: [
      {
        label: "16",
        value: "split-level house",
      },
      {
        label: "17",
        value: "bungalow",
      },
      {
        label: "18",
        value: "mobile home",
      },
      {
        label: "19",
        value: "two-story house",
      },
      {
        label: "20",
        value: "one and a half story house",
      },
    ],
    genresTwo: [
      {
        label: "21",
        value: "apartment",
      },
      {
        label: "22",
        value: "loft",
      },
      {
        label: "23",
        value: "house",
      },
    ],
    types: [
      {
        label: "25",
        value: "detached",
      },
      {
        label: "26",
        value: "semi-detached",
      },
      {
        label: "27",
        value: "end unit townhouse",
      },
      {
        label: "28",
        value: "townhouse",
      },
      {
        label: "29",
        value: "quadrex",
      },
    ],
  },

  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSales: (state, action) => {
      state.sales = action.payload;
    },
    setLastFetchedSale: (state, action) => {
      state.lastFetchedSale = action.payload;
    },
    setLoadingPropertyInfo: (state, action) => {
      state.loadingPropertyInfo = action.payload;
    },
    setAddingSale: (state, action) => {
      state.addingSale = action.payload;
    },
    setLoadingMore: (state, action) => {
      state.loadingMore = action.payload;
    },
    setUpdatingSale: (state, action) => {
      state.updatingSale = action.payload;
    },
  },
});

export const {
  setLoading,
  setSales,
  setLastFetchedSale,
  setLoadingPropertyInfo,
  setAddingSale,
  setLoadingMore,
  setUpdatingSale,
} = salesSlice.actions;

export default salesSlice.reducer;
