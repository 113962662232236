import { setPlans, setPlan, setTaxes } from "../subscriptionSlice";
import { toast } from "react-toastify";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";

export const getAllPlans = (priceId) => {
  return async (dispatch, getState) => {
    const getPlans = httpsCallable(functions, "getAllPlans");
    getPlans({ priceId })
      .then((res) => {
        if (res.data && res.data.error) {
          toast.error(res.data.message);
        } else {
          dispatch(setPlans(res.data.plans));
          dispatch(setPlan(res.data.selectedPlan));
          dispatch(setTaxes(res.data.taxes));
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
};
