import { createSlice } from "@reduxjs/toolkit";

export const evaluationSlice = createSlice({
  name: "evaluation",
  initialState: {
    loading: false,
    formalizedData: {},
    result: {},
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setFormalizedData: (state, action) => {
      state.formalizedData = action.payload;
    },

    setResult: (state, action) => {
      state.result = action.payload;
    },
  },
});

export const { setLoading, setCurrentStep, setFormalizedData, setResult } =
  evaluationSlice.actions;

export default evaluationSlice.reducer;
