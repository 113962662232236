import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { checkAuthStatus } from "../redux/slices/authentication/actions/checkAuthStatus";
import { useDispatch, useSelector } from "react-redux";
import AuthLayout from "../components/layout/AuthLayout";
import Spinner from "../components/spinner";
import { useTranslation } from "react-i18next";

const AuthGuard = ({ component, noFooter }) => {
  const { t } = useTranslation();
  const { authStatus, authEventOn, userData, loadingUserData } = useSelector(
    (state) => state.authentication
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus(t));
  }, [dispatch, t]);

  const isCreationProcessFinished = useMemo(() => {
    // console.log("user", userData);
    if (userData?.creationProcessFinished === false) {
      return false;
    } else if (
      userData?.creationProcessFinished === undefined ||
      userData?.creationProcessFinished === true
    ) {
      return true;
    }
  }, [userData]);

  useEffect(() => {
    if (authEventOn && !authStatus) {
      navigate("/signin");
    } else {
      if (
        !isCreationProcessFinished &&
        location.pathname !== "/profile-create"
      ) {
        navigate("/profile-create");
      } else if (
        isCreationProcessFinished &&
        location.pathname === "/profile-create"
      ) {
        navigate("/dashboard");
      } else if (
        location.pathname === "/leads" &&
        userData?.role === "Essentiel"
      ) {
        navigate("/");
      }
    }
  }, [
    authStatus,
    authEventOn,
    isCreationProcessFinished,
    navigate,
    location,
    userData,
  ]);

  const isAuth = useMemo(() => {
    if (authEventOn && authStatus) {
      return true;
    } else {
      return false;
    }
  }, [authStatus, authEventOn]);

  if (!isAuth && !loadingUserData) return <Spinner />;
  if (!isCreationProcessFinished) return <>{component}</>;
  return <AuthLayout noFooter={noFooter}>{component}</AuthLayout>;
};

export default AuthGuard;
