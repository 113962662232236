import {
  collection,
  getCountFromServer,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../../firebase";

import { toast } from "react-toastify";

const getLeadsCountPerWeek = async (startDate, endDate) => {
  try {
    const allLeadsRef = collection(db, "RapportsEvaluations");

    const leadsWeekRef = query(
      allLeadsRef,
      where("dateCreation", ">=", startDate),
      where("dateCreation", "<=", endDate),
      orderBy("dateCreation", "desc")
    );

    const snapshotContacts = await getCountFromServer(leadsWeekRef);
    const leadsWeekCount = snapshotContacts.data().count;
    return leadsWeekCount;
  } catch (error) {
    console.error("Error getting documents: ", error);
    toast.error("Erreur lors de la récupération des leads");
  }
};

export default getLeadsCountPerWeek;
