import { useEffect, useState, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { navigations } from "../navigations";
import { classList } from "../../../utils/classList";
import Srcollbar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateLeftSideBarOpen } from "../../../redux/slices/layout/actions/UpdateLeftSideBarOpen";
import { logOut } from "../../../redux/slices/authentication/actions/logout";

import { ReactComponent as HomeIcon } from "./menuIcons/home.svg";
import { ReactComponent as Abonnement } from "./menuIcons/abonnement.svg";
import { ReactComponent as Deconnecter } from "./menuIcons/deconnecter.svg";
import { ReactComponent as Estimations } from "./menuIcons/estimations.svg";
import { ReactComponent as Estimer } from "./menuIcons/estimer.svg";
import { ReactComponent as Fideliser } from "./menuIcons/fideliser.svg";
import { ReactComponent as Profile } from "./menuIcons/profile.svg";
import { ReactComponent as Prospecter } from "./menuIcons/prospecter.svg";
import { ReactComponent as Ventes } from "./menuIcons/ventes.svg";

const SideNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { leftSidebar } = useSelector((state) => state.layoutSetting);
  const { userData } = useSelector((state) => state.authentication);

  const { t } = useTranslation();

  const [windowListener, setWindowListener] = useState(null);
  const [role, setRole] = useState("");
  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    if (userData?.role && userData?.customerId) {
      setRole(userData.role);
      setCustomerId(userData.customerId);
    }
  }, [userData]);

  const [currentPage, setCurrentPage] = useState("/dashboard");

  const icons = useMemo(() => {
    return {
      home: HomeIcon,
      abonnement: Abonnement,
      deconnecter: Deconnecter,
      estimations: Estimations,
      estimer: Estimer,
      fideliser: Fideliser,
      profile: Profile,
      prospecter: Prospecter,
      ventes: Ventes,
    };
  }, []);

  const verifyIfDisabled = (item, customerId) => {
    if (item.path === "/abonnement" && customerId === "admin") {
      return true;
    }
    return false;
  };

  const closeSidenav = () => {
    dispatch(updateLeftSideBarOpen(false));
  };

  const openSidenav = () => {
    dispatch(updateLeftSideBarOpen(true));
  };

  useEffect(() => {
    const pathname = location.pathname;
    setCurrentPage(pathname);

    if (window)
      if (window.innerWidth < 1200) {
        closeSidenav();
      } else {
        openSidenav();
      }

    setWindowListener(
      window.addEventListener("resize", ({ target }) => {
        if (window.innerWidth < 1200) {
          closeSidenav();
        } else {
          openSidenav();
        }
      })
    );

    return () => {
      if (windowListener) {
        window.removeEventListener("resize", windowListener);
      }
    };
  }, []);

  const navigationRole = navigations(t).map((item) => {
    item.disabled = !(item.role && item.role.includes(role)) || item.disabled;
    return item;
  });

  return (
    <div className="side-content-wrap sidebar-dark-purple">
      <Srcollbar
        className={classList({
          "sidebar-left o-hidden rtl-ps-none layout_sidebar_container": true,
          open: leftSidebar.open,
        })}
      >
        <ul className="navigation-left" style={{ position: "relative" }}>
          {navigationRole.slice(0, 4).map((item, i) => {
            const Icon = icons[item.icon];
            return (
              <li
                className="nav-item"
                style={{
                  borderBottom: "none",
                }}
                key={item.id}
              >
                {item.path && item.type !== "extLink" && (
                  <NavLink
                    className={`nav-item-hold layout_section_navItem ${
                      item.disabled ? "disabled" : ""
                    }`}
                    to={item.disabled ? "#" : item.path}
                    style={{
                      padding: "1rem 0.25rem",
                    }}
                    onClick={() => {
                      setCurrentPage(item.path);
                    }}
                    title={t(`SideNav.${item.id}`)}
                  >
                    <Icon
                      className="nav-icon"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        stroke:
                          currentPage === item.path ? "#5F3994" : "#2B2B2B",
                        opacity: item.disabled ? "0.3" : "1",
                        strokeWidth: currentPage === item.path ? "2" : "1.4",
                      }}
                    />
                    <h2
                      className="layout_sidenav_title"
                      style={{
                        color:
                          currentPage === item.path ? "#5F3994" : "#2B2B2B",
                        opacity: item.disabled ? "0.3" : "1",
                      }}
                    >
                      {item?.name}
                    </h2>
                  </NavLink>
                )}
              </li>
            );
          })}
          <div
            style={{
              borderBottom: "1px solid #8792A2",
              opacity: "0.3",
              margin: "0.25rem 1rem",
            }}
          />
          {navigationRole.slice(4, 7).map((item, i) => {
            const Icon = icons[item.icon];
            return (
              <li
                className="nav-item"
                style={{
                  borderBottom: "none",
                }}
                title={t(`SideNav.${item.id}`)}
                key={item.id}
              >
                {item.path && item.type !== "extLink" && (
                  <NavLink
                    className={`nav-item-hold layout_section_navItem ${
                      item.disabled || verifyIfDisabled(item, customerId)
                        ? "disabled"
                        : ""
                    }`}
                    to={
                      item.disabled || verifyIfDisabled(item, customerId)
                        ? "#"
                        : item.path
                    }
                    style={{
                      padding: "1rem 0.25rem",
                    }}
                    onClick={() => {
                      setCurrentPage(item.path);
                    }}
                  >
                    <Icon
                      className="nav-icon"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        stroke:
                          currentPage === item.path ? "#5F3994" : "#2B2B2B",
                        opacity:
                          item.disabled || verifyIfDisabled(item, customerId)
                            ? "0.3"
                            : "1",
                        strokeWidth: currentPage === item.path ? "2" : "1.4",
                      }}
                    />
                    <h2
                      style={{
                        color:
                          currentPage === item.path ? "#5F3994" : "#2B2B2B",
                        opacity:
                          item.disabled || verifyIfDisabled(item, customerId)
                            ? "0.3"
                            : "1",
                      }}
                      className="layout_sidenav_title"
                    >
                      {item?.name}
                    </h2>
                  </NavLink>
                )}
              </li>
            );
          })}

          <div className="layout_section_end">
            {navigationRole.slice(7, 9).map((item, i) => {
              const Icon = icons[item.icon];
              return (
                <li
                  className="nav-item"
                  style={{
                    borderBottom: "none",
                    width: "100%",
                  }}
                  title={t(`SideNav.${item.id}`)}
                  key={item.id}
                >
                  {item.path && item.type !== "extLink" && (
                    <NavLink
                      className={`nav-item-hold layout_section_navItem ${
                        item.disabled ? "disabled" : ""
                      }`}
                      to={item.disabled ? "#" : item.path}
                      style={{
                        padding: "1rem 0.25rem",
                      }}
                      onClick={() => {
                        setCurrentPage(item.path);
                      }}
                    >
                      <Icon
                        className="nav-icon"
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          stroke:
                            currentPage === item.path ? "#5F3994" : "#2B2B2B",
                          opacity: item.disabled ? "0.3" : "1",
                          strokeWidth: currentPage === item.path ? "2" : "1.4",
                        }}
                      />
                      <h2
                        style={{
                          color:
                            currentPage === item.path ? "#5F3994" : "#2B2B2B",
                          opacity: item.disabled ? "0.3" : "1",
                        }}
                        className="layout_sidenav_title"
                      >
                        {item?.name}
                      </h2>
                    </NavLink>
                  )}
                </li>
              );
            })}
            <li
              className="nav-item"
              style={{
                borderBottom: "none",
              }}
            >
              <div
                className="nav-item-hold"
                title={t("Header.4")}
                style={{
                  padding: "1rem 0.25rem",
                }}
              >
                <Deconnecter
                  className="nav-icon"
                  onClick={() => dispatch(logOut())}
                  style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    stroke: "#2B2B2B",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
            </li>
          </div>
        </ul>
      </Srcollbar>
    </div>
  );
};

export default SideNav;
