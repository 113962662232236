import { setLicenceIdFound } from "../subscriptionSlice";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";

export function findLicenceIdInDatabase(licenceId) {
  return (dispatch) => {
    const findLicanceIdInDb = httpsCallable(
      functions,
      "findLicanceIdInDataBase"
    );
    findLicanceIdInDb({ licenceId })
      .then((res) => {
        dispatch(setLicenceIdFound(res.data.success ? true : false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
