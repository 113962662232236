import { setMyEvaluations } from "../leadsSlice";

export const changeEvaluationsAction = (profile) => {
  return async (dispatch, getState) => {
    if (profile && profile.bookmarks) {
      let myOpportunities = profile.bookmarks.map((bookmark) => {
        let newBookmark = { ...bookmark };
        if (bookmark.ouiContacterParProfessionnel === "oui")
          newBookmark.type = "contact";
        else newBookmark.type = "prospect";
        return newBookmark;
      });
      myOpportunities.sort(
        (a, b) => new Date(b.dateCreation) - new Date(a.dateCreation)
      );
      dispatch(setMyEvaluations(myOpportunities));
    }
  };
};
