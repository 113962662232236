import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";
import { useDispatch, useSelector } from "react-redux";
import { updateFormalizedData } from "../../../../redux/slices/evaluation/actions/updateFormalizedData";

const EstimateStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formalizedData } = useSelector((state) => state.evaluation);
  const { nextStep, previousStep } = useWizard();
  const [selectedItem, setSelectedItem] = useState(null);
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    dispatch(updateFormalizedData({ genreProprietes: data?.genreProprietes }));
    nextStep();
  };

  const handleDivClick = useCallback(
    (value) => {
      setValue("genreProprietes", value);
      setSelectedItem(value);
    },
    [setValue]
  );

  const estimate = useCallback(() => {
    if (formalizedData?.type === "condo") {
      return [
        {
          imageSrc: "/assets/images/steps/house.png",
          label: t("Estimate-new.32"),
          value: "Maison",
        },
        {
          imageSrc: "/assets/images/steps/appartment.png",
          label: t("Estimate-new.33"),
          value: "Appartement",
        },
        {
          imageSrc: "/assets/images/steps/appartment.png",
          label: t("Estimate-new.34"),
          value: "Loft/Studio",
        },
      ];
    } else {
      return [
        {
          imageSrc: "/assets/images/steps/house.png",
          value: "Maison de plain-pied",
          label: t("Estimate-new.35"),
        },
        {
          imageSrc: "/assets/images/steps/appartment.png",
          value: "Maison à étages",
          label: t("Estimate-new.36"),
        },
        {
          imageSrc: "/assets/images/steps/house.png",
          value: "Maison à un étage et demi",
          label: t("Estimate-new.37"),
        },
      ];
    }
  }, [formalizedData?.type, t]);

  useEffect(() => {
    if (formalizedData?.type === "condo") {
      setValue(
        "genreProprietes",
        formalizedData?.genreProprietes
          ? formalizedData?.genreProprietes
          : "Appartement"
      );
      setSelectedItem(
        formalizedData?.genreProprietes
          ? formalizedData?.genreProprietes
          : "Appartement"
      );
    } else if (formalizedData?.type === "Unifamiliale") {
      setValue(
        "genreProprietes",
        formalizedData?.genreProprietes
          ? formalizedData?.genreProprietes
          : "Maison de plain-pied"
      );
      setSelectedItem(
        formalizedData?.genreProprietes
          ? formalizedData?.genreProprietes
          : "Maison de plain-pied"
      );
    }
  }, [
    formalizedData?.type,
    setValue,
    setSelectedItem,
    formalizedData?.genreProprietes,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <h2 className="font-poppins font-weight-bold text-violet-2 mb-4">
          {t("Estimate-new.31")}
        </h2>
        <div className="d-flex flex-wrap" style={{ gap: 16 }}>
          {estimate().map((data) => (
            <div
              key={data.value}
              className={`step-box-1 p-4 text-center flex-fill ${
                selectedItem === data.value ? "active" : ""
              }`}
              onClick={() => handleDivClick(data.value)}
            >
              <img src={data.imageSrc} alt={data.value} />
              <h6 className="mt-4 mb-0 text-14 font-weight-700 font-poppins">
                {data.label}
              </h6>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button
            type="button"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            onClick={previousStep}
          >
            {t("Estimate-new.25")}
          </button>
          <button
            type="submit"
            className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
            // onClick={nextStep}
            disabled={selectedItem === null}
          >
            {t("Estimate-new.26")}
          </button>
        </div>
      </fieldset>
    </form>
  );
};

export default EstimateStep;
